import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { push as pushRouter } from 'connected-react-router';

import { withTheme } from 'styled-components';

import ReduxBlockUi from 'react-block-ui/redux';
import { Loader } from 'react-loaders';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Types as LoadingTypes } from '~/redux/ducks/loading';

import NavbarComponent from '~/navigation/components/Navbar';
import InitialLoader from '~/Components/InitialLoader';

import Contratos from '~/Pages/Contratos/pages/List';
import ContratoCrud from '~/Pages/Contratos/pages/Crud';
import Campanhas from '~/Pages/Campanhas/pages/List';
import CampaignScheduling from '~/Pages/CampaignScheduling';
import ApprovalList from '~/Pages/Campanhas/pages/ApprovalList';
import ApprovalView from '~/Pages/Campanhas/pages/ApprovalView';
import CampanhaCrud from '~/Pages/Campanhas/pages/Crud';
import CampanhaCrudCreative from '~/Pages/Campanhas/pages/Crud/Form/Creative';
import CampanhaResultado from '~/Pages/Campanhas/pages/Resultado';
import AuctionsList from '~/Pages/Auctions/Pages/List';
import GridPeriodList from '~/Pages/GridPeriod/Pages/List';
import NewGridPeriodList from '~/Pages/NewGridPeriod/Pages/List';
import ReportList from '~/Pages/Reports/List';
import ReportWeeklyGridPeriodExtract from '~/Pages/Reports/Weekly';
import ReportMonthlyGridPeriodExtract from '~/Pages/Reports/Monthly';
import PeriodList from '~/Pages/Period/pages/List';
// audiência
import AudiencesList from '~/Pages/Audiences/Pages/List';
import AudiencesMap from '~/Pages/Audiences/Pages/Map';
import AudiencesForm from '~/Pages/Audiences/Pages/Form';
import AudiencesExternal from '~/Pages/Audiences/Pages/ExternalAudience';
// financeiro
import FinancialPartner from '~/Pages/Financial/Pages/Partner';
import FinancialProvider from '~/Pages/Financial/Pages/Provider';
import FinancialFiles from '~/Pages/Financial/Pages/Files';
// gestão de PI
import PiManagement from '~/Pages/PiManagement/Pages/List';
import PiManagementForm from '~/Pages/PiManagement/Pages/Form';

import BehaviorsList from '~/Pages/Behaviors/List';
import BehaviorsCrud from '~/Pages/Behaviors/Crud';

import AudienceTest from '~/Pages/AudienceTest/pages/List';

import Provider from '~/Pages/Provider/pages/List';
import ProviderEdit from '~/Pages/Provider/pages/Crud';

import AdvertisingPartner from '~/Pages/AdvertisingPartner/pages/List';

import AdManagement from '~/Pages/Ad/pages/AdManagement';
import PlacementSetting from '~/Pages/Ad/pages/PlacementSetting';

import Dashboard from '~/Pages/Dashboard';

import CockpitDashboard from '~/Pages/CockpitDashboard';

import PointsManagement from '~/Pages/PointsManagement';

import { Creators as AuthActions } from '~/redux/ducks/auth';

import CockpitAcompanhamento from './Pages/CockpitAcompanhamento';

import ProductList from './Pages/Product/List';

import Auth from './Auth/Auth';
import { PlatformSettingsDashboard } from './Pages/PlatformSettings/Pages/Dashboard';
import CarrotSettings from './Pages/PlatformSettings/Pages/CarrotSettings';

import ManualSegmentation from './Pages/ManualSegmentation/Pages/List';

import ViewSegmentation from './Pages/ManualSegmentation/Pages/ViewSegmentation';

import AuthError from './Pages/AuthError';

let auth = null;

class Routes extends Component {
  state = {
    userUnauthorized: null,
  }


  async componentWillMount() {
    const {
      location: { pathname },
      push, credentials,
    } = this.props;
    auth = new Auth(push, credentials);
    let result = false;
    try {
      result = await auth.handleAuthentication();
    } catch (e) {
      if (e.error && e.error === 'unauthorized') {
        this.userUnauthorizedAuth(e);
        return;
      }
      result = false;
    }
    if (result) {
      this.updateCurrentUser();
    } else {
      sessionStorage.setItem('@ArtemisAdmin:path_return', pathname);
      auth.login();
    }
  }

  userUnauthorizedAuth = (error) => {
    this.setState({
      userUnauthorized: {
        error,
      },
    });
  }

  updateCurrentUser = async () => {
    const { setCurrentUser } = this.props;
    const uuidUser = await auth.getUuidUser();
    const uuidTenant = await auth.getUuidTenant();
    localStorage.setItem('uuidTenant', uuidTenant);
    setCurrentUser(uuidUser);
  };

  render() {
    const { currentUser, theme } = this.props;
    const { userUnauthorized } = this.state;

    if (userUnauthorized) {
      return <AuthError error={userUnauthorized.error} auth={auth} />;
    }

    if (!currentUser) {
      return <InitialLoader />;
    }
    return (
      <>
        <ReduxBlockUi
          tag="div"
          style={{ zIndex: 999999999 }}
          block={LoadingTypes.START_REQUEST}
          unblock={[LoadingTypes.REQUEST_SUCCESS, LoadingTypes.REQUEST_ERROR]}
          loader={<Loader active type="ball-grid-beat" color={theme.colors.primary} />}
        />
        <NavbarComponent currentUser={currentUser} />
        <Switch>
          <Route exact path="/" render={() => <InitialLoader />} />
          <Route exact path="/callback" render={() => <InitialLoader />} />
          <Route
            exact
            path="/logout"
            render={() => {
              auth.logout();
              return <InitialLoader />;
            }}
          />
          <Route
            exact
            path="/contracts"
            render={props => <Contratos {...props} />}
          />
          <Route
            exact
            path="/customMap"
            render={props => <AudiencesMap {...props} />}
          />
          <Route
            path="/contracts/edit/:contractId"
            render={props => <ContratoCrud {...props} />}
          />
          <Route
            path="/campaigns"
            exact
            render={props => <Campanhas {...props} />}
          />
          <Route
            path="/scheduling"
            exact
            render={props => <CampaignScheduling {...props} />}
          />
          <Route
            path="/campaigns/approval"
            exact
            render={props => <ApprovalList {...props} />}
          />
          <Route
            path="/campaigns/approval/view/:campaignId"
            render={props => <ApprovalView {...props} />}
          />
          <Route
            path="/auctions"
            render={props => <AuctionsList {...props} />}
          />
          <Route
            path="/gridperiod"
            render={props => <GridPeriodList {...props} />}
            exact
          />
          <Route
            path="/new-grid-period"
            render={props => <NewGridPeriodList {...props} />}
            exact
          />
          <Route
            path="/reports"
            render={props => <ReportList {...props} />}
            exact
          />
          <Route
            path="/reports/weekly-gridperiod-extract"
            render={props => <ReportWeeklyGridPeriodExtract {...props} />}
            exact
          />
          <Route
            path="/reports/monthly-gridperiod-extract"
            render={props => <ReportMonthlyGridPeriodExtract {...props} />}
            exact
          />
          <Route
            path="/campaigns/edit/:campaignId"
            exact
            render={props => <CampanhaCrud {...props} />}
          />
          <Route
            path="/campaigns/:campaignMode/:campaignId/creative"
            exact
            render={props => <CampanhaCrudCreative {...props} />}
          />
          <Route
            path="/campaigns/:campaignMode/:campaignId/report"
            exact
            render={props => <CampanhaResultado {...props} />}
          />
          <Route
            path="/providers"
            exact
            render={props => <Provider {...props} />}
          />
          <Route
            path="/providers/new"
            render={props => <ProviderEdit {...props} />}
          />
          <Route
            path="/providers/edit/:providerId"
            render={props => <ProviderEdit {...props} />}
          />
          <Route
            path="/advertising-partner"
            exact
            render={props => <AdvertisingPartner {...props} />}
          />
          <Route
            path="/ad/management"
            exact
            render={props => <AdManagement {...props} />}
          />
          <Route
            path="/ad/placement-setting"
            exact
            render={props => <PlacementSetting {...props} />}
          />
          <Route
            path="/periods"
            exact
            render={props => <PeriodList {...props} />}
          />
          <Route
            path="/audience/test"
            exact
            render={props => <AudienceTest {...props} />}
          />
          <Route
            path="/audience"
            exact
            render={props => <AudiencesList {...props} />}
          />
          <Route
            path="/audience/new"
            exact
            render={props => <AudiencesForm {...props} />}
          />
          <Route
            path="/audience/new/:campaignId"
            exact
            render={props => <AudiencesForm {...props} />}
          />
          <Route
            path="/audience/:audienceId/edit"
            exact
            render={props => <AudiencesForm {...props} />}
          />
          <Route
            path="/audience/:audienceId/details"
            exact
            render={props => <AudiencesExternal {...props} />}
          />
          <Route
            path="/pi-management"
            exact
            render={props => <PiManagement {...props} />}
          />
          <Route
            path="/pi-management/new"
            exact
            render={props => <PiManagementForm {...props} />}
          />
          <Route
            path="/pi-management/:piId/edit"
            exact
            render={props => <PiManagementForm {...props} />}
          />
          <Route
            path="/financial/partner"
            exact
            render={props => <FinancialPartner {...props} />}
          />
          <Route
            path="/financial/provider"
            exact
            render={props => <FinancialProvider {...props} />}
          />
          <Route
            path="/financial/:type/files/:year/:month/:partnerUuid"
            exact
            render={props => <FinancialFiles {...props} />}
          />
          <Route
            path="/dashboard"
            exact
            render={props => <Dashboard {...props} />}
          />
          <Route
            path="/cockpit"
            exact
            render={props => <CockpitDashboard {...props} />}
          />
          <Route
            path="/cockpit-analyze"
            exact
            render={props => <CockpitAcompanhamento {...props} />}
          />
          <Route
            path="/pointsManagement"
            exact
            render={props => <PointsManagement {...props} />}
          />
          <Route
            path="/behaviors"
            exact
            render={props => <BehaviorsList {...props} />}
          />
          <Route
            path="/behaviors/crud/:behaviorUuid"
            exact
            render={props => <BehaviorsCrud {...props} />}
          />
          <Route
            path="/products"
            exact
            render={props => <ProductList {...props} />}
          />
          <Route
            path="/platform-settings"
            exact
            render={props => <PlatformSettingsDashboard {...props} />}
          />
          <Route
            path="/platform-settings/carrot"
            exact
            render={props => <CarrotSettings {...props} />}
          />
          <Route
            path="/manual-segmentation"
            exact
            render={props => <ManualSegmentation {...props} />}
          />
          <Route
            path="/manual-segmentation/:segmentationUuid"
            exact
            render={props => <ViewSegmentation {...props} />}
          />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location,
  currentUser: state.auth.currentUser,
  credentials: state.credentials.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setCurrentUser: AuthActions.setCurrentUser,
    push: pushRouter,
  },
  dispatch,
);

Routes.defaultProps = {
  currentUser: null,
};

Routes.propTypes = {
  push: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  currentUser: PropTypes.shape(),
  location: PropTypes.shape().isRequired,
  credentials: PropTypes.shape().isRequired,
  theme: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(Routes));
