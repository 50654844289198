import styled from 'styled-components';
import { hex2rgba } from '~/Utils';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => hex2rgba(props.theme.colors.dark, 0.5)};
  .value-range {
    align-self: flex-end;
    float: right;
    font-size: 18px;
    font-weight: 200;
    color: ${props => props.theme.colors.regular};
    margin-right: 15px;
  }
`;

export const ModalBox = styled.div`
  position: relative;
  z-index: 2;
  padding: 50px 50px 50px 50px;
  max-width: 70%;
  min-height: 80%;
  max-height: 90%;
  background-color: white;
  border-radius: 30px;
  overflow-y: scroll;

  @media (max-width: 1200px) {
    max-width: 80%;
  }

  @media (max-width: 960px) {
    max-width: 90%;
    padding: 30px 30px 30px 30px;
  }

  header{
    text-align: center;
    padding-bottom:25px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
      h2{
      font-size: x-large;
      color: ${props => props.theme.colors.primary};
      margin: 0;
    };
  };
  .close-button{
    height: 50px;
    width: 50px;
    border: 0px;
    background-color: transparent;
    img{
      width: 100%;
    }
  }

  .modal-body{
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .identification{
    border: 1px solid ${props => props.theme.colors.light};
    border-radius: 30px;

    .identification-row{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 25px;
      padding: 10px 0px 25px 0px;
      margin: 0px 25px 0px 25px;

      h3{
        margin: 10px 0px 10px 0px;
        grid-column-start: 1;
        grid-column-end: 4;
      }

      .block{
        label{
          padding: 0;
          margin-left: 10px;
          margin-bottom: 0px;
          color: ${props => props.theme.colors.primary};
        }
      }
    }

  }

  .config{
    border: 1px solid ${props => props.theme.colors.light};
    border-radius: 30px;

    .config-row{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 25px;
      padding: 10px 0px 25px 0px;
      margin: 0px 25px 0px 25px;

      h3{
        margin: 10px 0px 10px 0px;
        grid-column-start: 1;
        grid-column-end: 4;
      }

      .block{
        background-color: ${props => props.theme.colors.lighterWhite};
        padding: 15px 15px 15px 15px;
        border-radius: 30px;

        .toggle-row{
          display: flex;
          align-items: center;
          margin: 15px 0 15px 0;
          width: 100%;

          label.inactive{
            color: ${props => props.theme.colors.regular};
          }
        }

        .block-title{
          display: block;
          width: 100%;
          font-size: 16px;
          font-weight: 600;
          color: ${props => props.theme.colors.primary};
          margin: 10px 15px ;
        }

        .block-text{
          margin: 10px;

          .text-warning-bordered{
            img{
              height: 25px;
              margin: 15px 0px 10px 10px;
            }
            border-bottom: 1px solid ${props => props.theme.colors.warning2} ;
          }

          .text-warning{
            color: ${props => props.theme.colors.warning2};
          }
        }

        .config-template-download{
          display: flex;
          width: 100%;
          min-height: 150px;
          margin-top: 25px;

          border: 1px dashed ${props => props.theme.colors.light};
          border-radius: 30px;

          align-items: center;
          justify-content: center;
          background-color: ${props => props.theme.colors.white};

          img{
            height: 60px;
            margin-right: 15px;
          }

          a{
            font-size: 18px;
            color: ${props => props.theme.colors.primary};
          }

        }

        label{
          padding: 0;
          margin-left: 10px;
          margin-bottom: 0px;
          color: ${props => props.theme.colors.primary};
        }
      }
    }
  }

  .segmentation-origin{
    /* border: 1px dashed ${props => props.theme.colors.light};
    border-radius: 30px; */

    // customized border
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='30' ry='30' stroke='%2397979759' stroke-width='3' stroke-dasharray='25%2c 30' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 30px;

    .row{
      height: 200px;
      margin: 0px 15px 0px 15px;

      section{
        height: 100%;


        div{
          height: 100%;

          h3{
            padding: 10px 0px 25px 0px;
            margin: 10px 25px 10px 10px;
          }
          .dropzone{
            padding: 20px;
            margin-top: -80px;
            outline: none;
            flex: 1;
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            font-size: 18px;

            p {
              margin: 0;
              outline: none;
            }
          }
        }
      }



    }

  }

  .modal-new-manual-segmentation-footer{
    display: flex;
    justify-content: flex-end;
    margin: 15px 15px 15px 0px;
    gap: 25px;
  }

  box-shadow: 0 5px 20px ${props => hex2rgba(props.theme.colors.dark, 0.5)};
`;
