import { call, put } from 'redux-saga/effects';
// import FileSaver from 'file-saver';
// import TermsService from '~/services/TermsService';

import { replace } from 'connected-react-router';


import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ManualSegmentationCreators } from '~/redux/ducks/manualSegmentation';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import ManualSegmentationService from '~/services/ManualSegmentationService';

export function* getTemplates() {
  yield put(startRequest());
  try {
    const result = yield call(ManualSegmentationService.getConfigTemplates);
    yield put(ManualSegmentationCreators.loadConfigTemplateData(result));

    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Erro ao buscar os dados do banco.',
    ));
  }
}

export function* getSubcategories() {
  yield put(startRequest());
  try {
    const result = yield call(ManualSegmentationService.getSubcategories);
    yield put(ManualSegmentationCreators.loadSubcategories(result));

    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Erro ao buscar os dados do banco.',
    ));
  }
}

export function* getSegmentations({ name, userKey, page }) {
  yield put(startRequest());
  try {
    const result = yield call(ManualSegmentationService.getSegmentations, { name, userKey, page });
    yield put(ManualSegmentationCreators.loadSegmentations(result));
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Erro ao buscar os dados do banco.',
    ));
  }
}

export function* getSegmentationData({ segmentationUuid }) {
  yield put(startRequest());
  try {
    const result = yield call(ManualSegmentationService.getSegmentationData, segmentationUuid);

    // if (!result) {
    //   yield put(replace('/manual-segmentation'));
    //   yield put(ToastrMessageActions.errorMessage(
    //     'Atenção!',
    //     'Sem informações sobre esta segmentação manual.',
    //   ));
    // }

    yield put(ManualSegmentationCreators.loadSegmentationData(result));
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Erro ao buscar os dados do banco.',
    ));
    yield put(replace('/manual-segmentation'));
  }
}

export function* createSegmentation({ newSegmentation }) {
  yield put(startRequest());
  try {
    const result = yield call(ManualSegmentationService.createSegmentation, newSegmentation);

    console.log(result);

    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao tentar cadastrar a segmentação. Tente novamente!',
    ));
  }
}
