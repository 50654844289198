import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Creators as ManualSegmentationCreators } from '~/redux/ducks/manualSegmentation';

import { general } from '~/Styles';
import { Button, Table, ModalNewManualSegmentation } from './Components';
import {
  Wrap,
  Container,
  SearchInput,
  SearchButton,
  SearchGroup,
  Header,
  Select,
  HeaderFormGroup,
} from './styles';

import { Pagination } from '../Components';

function ManualSegmentation({
  openModalNewManualSegmentation,
  getSegmentations,
  paginate,
  changePageSegmentations,
  configTemplates,
}) {
  const [searchIdentificator, setSearchIdentificator] = useState('');
  const [searchName, setSearchName] = useState('');


  function handleSearch(page) {
    getSegmentations(searchName, searchIdentificator, page || 0);
  }

  // useEffect(() => {
  //   handleSearch();
  // }, []);

  return (
    <Wrap>
      <Header>
        <h1>SEGMENTAÇÃO MANUAL</h1>
        <HeaderFormGroup>
          <div>
            <Select
              id="user-identificator"
              name="user-identificator"
              className="user-identificator"
              value={searchIdentificator}
              onChange={e => setSearchIdentificator(e.target.value)}
            >
              <option value="">Identificador...</option>
              {configTemplates
                  && configTemplates.userKeys
                  && configTemplates.userKeys.map(template => (
                    <option key={template} value={template}>{template}</option>
                  ))}
            </Select>
          </div>
          <SearchGroup>
            <SearchInput
              name="search"
              type="text"
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              placeholder="Procure por nome"
            />
            <SearchButton onClick={handleSearch}>
              <img src={general.image['search-icon-green.png']} alt="search-icon" className="search-icon" />
            </SearchButton>
          </SearchGroup>
          <div>
            <Button id="new-segmentation-button" name="new-segmentation-button" title="Nova Segmentação" onClick={openModalNewManualSegmentation}>
              <img src={general.image['new-segmentation-icon.png']} alt="new-segmentation-icon" className="new-segmentation-icon" />
            </Button>
          </div>
        </HeaderFormGroup>
      </Header>
      <Container>
        <Table />
        <Pagination
          page={paginate.page}
          pageCount={paginate.count}
          pageChange={e => handleSearch(parseInt(e.selected))}
        />
      </Container>
      <ModalNewManualSegmentation />
    </Wrap>
  );
}

const mapStateToProps = ({
  manualSegmentation: {
    show,
    paginate,
    configTemplates,
  },
}) => ({
  show,
  paginate,
  configTemplates,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    openModalNewManualSegmentation: ManualSegmentationCreators.openModalNewManualSegmentation,
    changePageSegmentations: ManualSegmentationCreators.changePageSegmentations,
    getSegmentations: ManualSegmentationCreators.getSegmentations,
  },
  dispatch,
);

ManualSegmentation.propTypes = {
  openModalNewManualSegmentation: PropTypes.func.isRequired,
  changePageSegmentations: PropTypes.func.isRequired,
  getSegmentations: PropTypes.func.isRequired,
  paginate: PropTypes.shape().isRequired,
  configTemplates: PropTypes.objectOf(PropTypes.array),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(ManualSegmentation));
