import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Select = ({
  id,
  name,
  placeholder,
  value,
  onChange,
  ...rest
}) => (
  <Container
    id={id}
    name={name}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    {...rest}
  />
);

Select.defaultProps = {
  name: null,
  style: null,
  placeholder: '',
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  style: PropTypes.shape(),
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Select;
