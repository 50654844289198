import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '~/Components/Button';
import ModalTest from './Components/ModalTest';

import { Creators as AudienceTestActions } from '~/redux/ducks/audienceTest';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';


import {
  Container,
  Table,
  Row,
  Input,
  ButtonGroup,
  LinkButton,
} from './styles';

const INIT_NEW_AUDIENCE = {
  name: '',
  audienceTypeId: null,
};

class AudienceTest extends Component {
  state = {
    newAudience: INIT_NEW_AUDIENCE,
    validateForm: false,
    showModal: false,
  };

  componentDidMount() {
    const { loadAudienceTest } = this.props;
    loadAudienceTest();
  }

  //  Excluir
  handleDeleteAudience = (audience) => {
    const { confirmModal, deleteAudience } = this.props;
    confirmModal(
      'Deseja excluir usuário de audiência?',
      `${audience.name}`,
      () => {
        deleteAudience(audience.uuid);
      },
    );
  };

  // Render Audience - Tabela audiencia
  renderAudiences = () => {
    const { audienceUsers } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th style={{ width: 360 }}>IDENTIFICAÇÃO</th>
            <th style={{ width: 220 }}>NÚMERO</th>
            <th className="title-status" style={{ width: 160 }}>SMS</th>
            <th className="title-status" style={{ width: 160 }}>PUSH</th>
            <th className="title-status" style={{ width: 160 }}>NO DATA</th>
            <th className="title-status" style={{ width: 250 }}>NOTIFICAÇÃO PATROCINADA</th>
            <th style={{ width: 200 }} />
          </tr>
        </thead>
        <tbody>
          {audienceUsers.length < 1 && (
            <tr>
              <td colSpan="8">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de audiências de teste</span>
                </div>
              </td>
            </tr>
          )}
          {audienceUsers.length > 0 && audienceUsers.map((audience, index) => this.renderRowAudience(audience, index))}
        </tbody>
      </Table>
    );
  };

  // Tabela BODY audiencia
  renderRowAudience = (audience, index) => {
    const active = <FontAwesomeIcon color="#4DF901" icon="check" />;
    const disabled = <FontAwesomeIcon color="#B6B6B6" icon="check" />;
    const { loadAudienceUserData } = this.props;

    return (
      <tr key={audience.uuid}>
        <td>
          <span style={{ marginRight: 20 }}>{(index + 1)}</span>
          <a className="identification" onClick={() => loadAudienceUserData(audience.uuid)}>{audience.name}</a>
        </td>
        <td>
          <span>{audience.identifier}</span>
        </td>
        <td>
          <span id="SMS" className="center-status">{audience.channels.includes('899cb964-0224-4067-8978-de855232ff6a') ? active : disabled }</span>
        </td>
        <td>
          <span id="PUSH" className="center-status">
            {audience.channels.includes('3b148899-6c48-4f49-92af-5d22b9381d39') ? active : disabled }
            {' '}
          </span>
        </td>
        <td>
          <span id="NO DATA" className="center-status">{audience.channels.includes('fd1bd5dc-4754-11ea-89ec-0677896605d2') ? active : disabled }</span>
        </td>
        <td>
          <span id="NOTIFICAÇÃO PATROCINADA" className="center-status">{audience.channels.includes('39766f8e-621a-40b0-aa5e-ff265b881324') ? active : disabled}</span>
        </td>
        <td>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              bordered
              title="EXCLUIR"
              onClick={() => this.handleDeleteAudience(audience)}
            />
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const {
      audienceFilter,
      setFilterAudience,
      showModalAudience,
      onFilter,
      onResetFilter,
    } = this.props;
    return (
      <>
        <Container>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>Audiência de Teste</h1>
            <Button
              bordered
              onClick={() => showModalAudience()}
              title="NOVO USUÁRIO"
            />
          </Row>
          <Row>
            <div className="box-search">
              <Input
                placeholder="Procurar por identificação ou número"
                value={audienceFilter}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onFilter(audienceFilter);
                  }
                }}
                onChange={(e) => {
                  setFilterAudience({ name: e.target.value });
                }}
              />
              <ButtonGroup>
                <LinkButton
                  onClick={() => {
                    onFilter(audienceFilter);
                  }}
                >
                  Buscar
                </LinkButton>
                <Button
                  icon="FaTimes"
                  noLabel
                  onClick={() => {
                    onResetFilter();
                  }}
                />
              </ButtonGroup>
            </div>
          </Row>
          {this.renderAudiences()}
          <Row />
          <ModalTest />
        </Container>
      </>

    );
  }
}


const mapStateToProps = (state) => {
  const result = {
    audienceUsers: state.audienceTest.audienceUsers,
    audienceFilter: state.audienceTest.filter,
  };
  return result;
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadAudienceTest: AudienceTestActions.loadAudienceTest,
    showModalAudience: AudienceTestActions.showModalAudience,
    loadAudienceUserData: AudienceTestActions.loadAudienceUserData,
    deleteAudience: AudienceTestActions.deleteAudience,
    onFilter: AudienceTestActions.filterAudience,
    setFilterAudience: AudienceTestActions.setFilterAudience,
    confirmModal: ConfirmModalActions.showConfirmModal,
    onResetFilter: AudienceTestActions.resetfilterAudience,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceTest);
