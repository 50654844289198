/* eslint-disable max-len */
/* eslint-disable no-console */
import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
// import L from 'leaflet';
// import turf from 'turf';
// import lodash from 'lodash';

import { Creators as AudienceActions } from '~/redux/ducks/audience';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import AudienceService from '~/services/AudienceService';
import CampaignEditService from '~/services/CampaignEditService';

import AudienceUtil from '~/Utils/AudienceUtil';
import { replaceAll } from '~/Utils';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import CreativeName from '~/Utils/CreativeName';
import { forEach } from 'lodash';

const reduceTotalFilters = (data) => {
  let result = 0;

  if (data) {
    data.forEach((d) => {
      if (d.data && d.data.length > 0) {
        result += reduceTotalFilters(d.data);
      } else {
        result += 1;
      }
    });
  }

  return result;
};

// const dataToGeolocations = (geofenceData) => {
//   const geolocations = geofenceData.map((geo) => {
//     const geolocation = {
//       uuid: geo.uuid,
//       name: geo.name,
//       color: geo.color,
//       type: geo.locations.geometry.type,
//       references: geo.references,
//     };
//
//     if (geolocation.type === 'Point') {
//       geolocation.type = 'circle';
//       geolocation.distances = geo.locations.properties.radius;
//       geolocation.locations = {
//         lat: geo.locations.geometry.coordinates[1],
//         lon: geo.locations.geometry.coordinates[0],
//       };
//     }
//
//     if (geolocation.type === 'Polygon') {
//       geolocation.type = 'polygon';
//       geolocation.locations = geo.locations.geometry.coordinates[0].map(coord => ({
//         lat: coord[1],
//         lon: coord[0],
//       }));
//     }
//     return geolocation;
//   });
//   return geolocations;
// };

// const geolocationsToData = (geolocations) => {
//   const geofenceData = geolocations.map((item) => {
//     const newGeo = {
//       uuid: item.uuid,
//       color: item.color,
//       name: item.name,
//       references: item.references,
//     };
//     if (item.type === 'circle') {
//       newGeo.center = new L.LatLng(item.locations.lat, item.locations.lon);
//       newGeo.locations = {
//         geometry: {
//           type: 'Point',
//           coordinates: [
//             item.locations.lon,
//             item.locations.lat,
//           ],
//         },
//         properties: { radius: item.distances },
//         type: 'Feature',
//       };
//     }
//     if (item.type === 'polygon') {
//       const polygon = turf.polygon(
//         [[...(item.locations.map(coord => ([coord.lon, coord.lat])))]],
//       );
//       const newCenter = turf.center(polygon);
//       newGeo.locations = polygon;
//       newGeo.center = new L.LatLng(
//         newCenter.geometry.coordinates[1],
//         newCenter.geometry.coordinates[0],
//       );
//     }
//     return newGeo;
//   });
//   return geofenceData;
// };

export function* audienceRequest({ filter }) {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(AudienceService.audienceRequest, filter);
    response.forEach((audience) => {
      audience.totalFilterActive = 0;
      if (audience.criteria) {
        if (audience.criteria instanceof String) {
          audience.criteria = JSON.parse(audience.criteria);
        }
        if (audience.criteria instanceof Array && audience.criteria.length > 0) {
          audience.totalFilterActive = reduceTotalFilters(audience.criteria);
        }
      }
    });
    yield put(
      AudienceActions.audienceRequestSuccess(response),
    );
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema carregar as audiências.',
    ));
  }
}

export function* audienceSetupRequest() {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(AudienceService.contractsRequest);
    yield put(
      AudienceActions.audienceSetupRequestSuccess({
        contracts: response,
      }),
    );
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao realizar o setup da audiência.',
    ));
  }
}

export function* problemUploadFileAudienceExternal() {
  const {
    audienceExternalUploadData: {
      audience: {
        uuid,
      },
    },
  } = yield select(state => state.audience);

  try {
    yield put(startRequest());
    yield call(AudienceService.audienceDelete, uuid);
    yield put(requestSuccess());
    yield put(ToastrMessageActions.errorMessage('Atenção!', 'Houve um problema enviar o arquivo.'));
    yield put(AudienceActions.cleanResetAudienceExternalData());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao salvar a audiência.',
    ));
  }
}

export function* downloadOriginalFileAudienceExternal() {
  const {
    audienceUpdate: {
      uuid,
    },
  } = yield select(state => state.audience);
  try {
    yield put(startRequest());
    const result = yield call(AudienceService.getUrlDownloadOriginalFileAudienceExternal, uuid);
    if (result && result.url) {
      window.open(result.url, '_blank');
    }
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao gerar o link para download.',
    ));
  }
}

export function* downloadInvalidationFileAudienceExternal() {
  const {
    audienceUpdate: {
      uuid,
    },
  } = yield select(state => state.audience);
  try {
    yield put(startRequest());
    const result = yield call(AudienceService.getUrlDownloadInvalidationFileAudienceExternal, uuid);
    if (result && result.url) {
      window.open(result.url, '_blank');
    }
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao gerar o link para download.',
    ));
  }
}

export function* saveExternalAudience() {
  const {
    audienceExternalData: {
      name,
      contract,
      file,
      shared,
    },
  } = yield select(state => state.audience);

  const { hasPermissionGlobalAudience, contract: contractUser } = yield select(state => state.auth.currentUser);

  yield put(startRequest());

  let sharedSave = shared;
  let contractUuidSave;

  if (!hasPermissionGlobalAudience) {
    sharedSave = false;
    contractUuidSave = contractUser.uuid;
  } else {
    contractUuidSave = contract.uuid;
  }

  try {
    const response = yield call(
      AudienceService.audienceSave, {
        name,
        description: '',
        criteria: [],
        preferences: {},
        type: 'external',
        contract: { uuid: contractUuidSave },
        shared: sharedSave,
      },
    );
    const responseUrlUpload = yield call(AudienceService.generateUrlUploadAudienceExternal, response.uuid);
    yield put(AudienceActions.uploadFileExternalAudience({
      audience: response,
      file,
      urlUpload: responseUrlUpload.url,
    }));
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    if (e && e.type === 'ConflictException') {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        `Já existe uma audiência com o nome "${name}".`,
      ));
    } else if (e && e.type === 'AuthorizedUserException') {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Usuário sem permissão.',
      ));
    } else {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um problema ao salvar a audiência.',
      ));
    }
  }
}

export function* audienceSaveData() {
  const { campaignId, ...data } = yield select(state => state.audience.audienceNew);
  const criativoEdit = yield select(state => state.campaignEdit.criativoEdit);
  const impactSettings = yield select(state => state.campaignEdit.impactSettings);
  const { geofenceData } = yield select(state => state.audience);
  const { hasPermissionGlobalAudience, contract } = yield select(state => state.auth.currentUser);

  // Start loading
  yield put(startRequest());
  try {
    let sharedSave = data.shared;
    let contractUuidSave;
    if (!hasPermissionGlobalAudience) {
      sharedSave = false;
      contractUuidSave = contract.uuid;
    } else {
      contractUuidSave = data.contract.uuid;
    }

    const response = yield call(
      AudienceService.audienceSave,
      {
        ...data,
        contract: { uuid: contractUuidSave },
        shared: sharedSave,
        geolocations: geofenceData,
      },
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(AudienceActions.audienceSetNew(null));
    yield put(AudienceActions.audienceSetUpdate(null));
    yield put(AudienceActions.audienceCategorySetActiveFilter({
      include: [],
      exclude: [],
    }));
    if (campaignId) {
      // Start loading
      yield put(startRequest());
      const auth = yield select(state => state.auth);
      const audiences = yield call(
        CampaignEditService.campaignEditAudienceRequest, {
          contractUuid: auth.contract.uuid,
          shared: true,
          processingStatus: 'PROCESSED,N/A,INVALID_FILE_PROCESSED',
        },
      );
      const newAudience = audiences.find(item => item.uuid === response.uuid);
      const { countMap: { totalOptinSms, totalOptinPush } } = newAudience;
      let total = 0;
      if (criativoEdit.creativeDefinition.name === CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE
        || criativoEdit.creativeDefinition.name === CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE) {
        total = totalOptinPush;
      } else {
        total = totalOptinSms;
      }
      yield put(CampaignEditActions.campaignEditSetImpactSettings({
        ...impactSettings,
        audienceUuid: response.uuid,
        estimatedAudience: total || 0,
        volume: total || 0,
        // cpm: 0.15,
      }));
      // Stop loading
      yield put(requestSuccess());
      yield put(replace(`/campaigns/edit/${campaignId}`));
    } else {
      yield put(replace('/audience'));
    }
  } catch (e) {
    yield put(requestError());
    if (e && e.type === 'ConflictException') {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        `Já existe uma audiência com o nome "${data.name}".`,
      ));
    } else {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um problema ao salvar a audiência.',
      ));
    }
  }
}

export function* audienceUpdateData() {
  const audienceUpdate = yield select(state => state.audience.audienceUpdate);
  const { geofenceData } = yield select(state => state.audience);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      AudienceService.audienceUpdate,
      audienceUpdate.uuid,
      {
        ...audienceUpdate,
        geolocations: geofenceData,
      },
    );

    // Stop loading
    yield put(requestSuccess());

    yield put(ToastrMessageActions.successMessage('Sucesso!', 'Audiência salva com sucesso.'));

    yield put(AudienceActions.audienceSetNew(null));
    yield put(AudienceActions.audienceSetUpdate(null));
    yield put(AudienceActions.audienceCategorySetActiveFilter({
      include: [],
      exclude: [],
    }));
    yield put(replace('/audience'));
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar audiência.',
    ));
  }
}

export function* audienceGetData({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    yield put(AudienceActions.audienceSetUpdate(null));
    const response = yield call(AudienceService.audienceGet, uuid);

    const geofenceData = response.geolocations || [];
    yield put(AudienceActions.updateGeofenceData(geofenceData));

    let responseContent = response;
    if (typeof response.criteria === 'string' || response.criteria instanceof String) {
      responseContent = {
        ...response,
        criteria: JSON.parse(response.criteria),
      };
    }
    if (
      typeof responseContent.preferences === 'string'
      || responseContent.preferences instanceof String
    ) {
      responseContent = {
        ...responseContent,
        preferences: JSON.parse(responseContent.preferences),
      };
    }
    if (
      !responseContent.preferences
      || JSON.stringify(responseContent.preferences) === '{}'
    ) {
      responseContent = {
        ...responseContent,
        preferences: {
          grafico01: { category: { key: 'faixa_etaria', label: 'Faixa Etária' }, type: 'column' },
          grafico02: { category: { key: 'sexo', label: 'Gênero' }, type: 'pie' },
          grafico03: { category: { key: 'plano', label: 'Plano' }, type: 'column' },
          grafico04: { category: { key: 'fabricante_aparelho', label: 'Fabricante do Device' }, type: 'bar' },
        },
      };
    }

    // Stop loading
    yield put(requestSuccess());

    if (responseContent.criteria && responseContent.criteria.indexOf('{}') < 0) {
      yield put(AudienceActions.audienceCategoryDataCriteriaCount(
        responseContent.criteria,
      ));
      yield put(AudienceActions.audienceCategorySetActiveFilter(
        AudienceUtil.criteriaToDataFilter(responseContent.criteria),
      ));
    }
    yield put(AudienceActions.audienceSetUpdate(responseContent));
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar a audiência.',
    ));
  }
}

export function* audienceDeleteData({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    yield call(AudienceService.audienceDelete, uuid);
    // Stop loading
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage('Sucesso!', 'Audiência excluída com sucesso.'));

    yield put(AudienceActions.audienceRequest());
  } catch (e) {
    yield put(requestError());
    if (e.type === 'warning') {
      yield put(ToastrMessageActions.warningMessage(
        'Atenção!',
        e.message,
      ));
    } else {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        e.message,
      ));
    }
  }
}

export function* audienceCategoriesRequest() {
  // Start loading
  yield put(startRequest());
  try {
    // const getPathItems = (item, path = []) => {
    //   const makePath = [...path, item.key];
    //   if (item.data.length > 0) {
    //     return getPathItems(item.data[0], makePath);
    //   }
    //   return makePath;
    // };

    const getPathItems = (items, path = []) => {
      let result = [];
      items.forEach(item => {
        const makePath = [...path, item.key];
        if (item.data && item.data.length > 0) {
          result = result.concat(getPathItems(item.data, makePath));
        } else {
          result.push(makePath);
        }
      });
      return result.flat(1);
    };

    const response = yield call(AudienceService.audienceCategoriesRequest);

    const subCategories = response.map(item => ({
      uuid: item.category.uuid,
      key: item.category.key,
      label: item.category.label,
      isUnique: item.subcategoriesChain.length === 0,
      pathItems:
        item.subcategoriesChain.length > 0
          ? getPathItems(item.subcategoriesChain)
          : [],
      source: item.category.source || 'DATASOURCE',
      subcategoriesChain: item.subcategoriesChain,
    }));

    yield put(AudienceActions.audienceCategoriesRequestSuccess(subCategories));

    // Stop loading
    yield put(requestSuccess());
    if (subCategories.length > 0) {
      yield put(
        AudienceActions.audienceCategoryDataRequest(subCategories[0]),
      );
    }
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as categorias.',
    ));
  }
}

export function* audienceCategoryDataRequest({ subCategory }) {
  // Start loading
  yield put(startRequest());
  try {
    let subcategoryData = null;

    // endpoint para buscar as subcategorias dada uma categoria mae
    if(subCategory.isUnique) {
      const data = yield call(
        AudienceService.getDataOfSubcategory,
        subCategory.key,
        {
          criterias: [],
          subcategoryKey:subCategory.key,
        },
      );

      subcategoryData = data.map(item => ({
        key: subCategory.key,
        label: item.label ? item.label : item.key,
        pathItemName: null,
        subCategory,
        data: [],
        collapsed: false,
        value: item.key,
        breadcrumbs: [subCategory.label],
        path: [subCategory.key, subCategory.key],
        bodyToGetSubcategory: null,
      }));
    } else {
      for(const pathItem of subCategory.pathItems) {

        const subcategoryChain = subCategory.subcategoriesChain.find(i => i.key === pathItem);

        if(subcategoryChain) {
          const data = yield call(
            AudienceService.getDataOfSubcategory,
            subCategory.key,
            {
              criterias: [],
              subcategoryKey: subcategoryChain.key,
            },
          );

          const subcat = data.map(item => {
            return {
              key: item.key,
              label: item.label ? item.label : item.key,
              pathItemName: subcategoryChain.label,
              subCategory,
              data: [],
              collapsed: false,
              value: item.key,
              breadcrumbs: [subCategory.label],
              path: [subCategory.key, subCategory.pathItems[1]],
              bodyToGetSubcategory:{
                  criterias: [
                    {
                      key: subCategory.pathItems[0],
                      value: item.key,
                    },
                  ],
                  subcategoryKey: subCategory.pathItems[1],
                }
              }
            });

          subcategoryData = subcategoryData && subcategoryData.length ? [...subcategoryData, ...subcat] : subcat;
        }
      }
    }

    const subcategory = {
      category: subCategory.key,
      data: subcategoryData,
    }

    yield put(AudienceActions.audienceCategoryDataRequestSuccess(
      subcategory
    ));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados da categoria.',
    ));
  }
}

export function* audienceCategoryDataCriteriaCount({ criteria }) {
  const { geofenceData } = yield select(state => state.audience);

  try {
    if (criteria.length > 0 || geofenceData.length > 0) {
      const response = yield call(
        AudienceService.audienceCategoryDataCriteriaCount,
        criteria,
        geofenceData,
      );
      yield put(AudienceActions.audienceCategoryDataCriteriaCountSuccess(
        `${replaceAll(response.count.toLocaleString(), ',', '.')}`,
      ));
    } else {
      yield put(AudienceActions.audienceCategoryDataCriteriaCountSuccess(0));
    }
  } catch (e) {
    console.error(e);
    yield put(AudienceActions.audienceCategoryDataCriteriaCountSuccess('0'));
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar os dados.',
    ));
  }
}

export function* audienceCategoryDataCriteriaCountByZone({ zoneId, criteria }) {
  const { geofenceData } = yield select(state => state.audience);

  try {
    if (criteria.length > 0 || geofenceData.length > 0) {
      const response = yield call(
        AudienceService.audienceCategoryDataCriteriaCountByZone,
        zoneId,
        criteria,
        geofenceData,
      );
      yield put(AudienceActions.audienceCategoryDataCriteriaCountByZoneSuccess(
        `${replaceAll(response.count.toLocaleString(), ',', '.')}`,
      ));
    } else {
      yield put(AudienceActions.audienceCategoryDataCriteriaCountByZoneSuccess(0));
    }
  } catch (e) {
    console.error(e);
    yield put(AudienceActions.audienceCategoryDataCriteriaCountByZoneSuccess('0'));
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar os dados.',
    ));
  }
}

export function* audienceCategoryDataCriteriaAnalyze({ preferences, criteria }) {
  try {
    if (criteria.length > 0) {
      yield put(AudienceActions.audienceCategoryDataCriteriaAnalyzeLoading(true));
      const response = yield call(
        AudienceService.audienceCategoryDataCriteriaAnalyze,
        preferences,
        criteria,
      );
      yield put(
        AudienceActions.audienceCategoryDataCriteriaAnalyzeSuccess(
          response.aggregations || null,
        ),
      );
      yield put(AudienceActions.audienceCategoryDataCriteriaAnalyzeLoading(false));
    } else {
      yield put(AudienceActions.audienceCategoryDataCriteriaAnalyzeSuccess(null));
    }
  } catch (e) {
    yield put(AudienceActions.audienceCategoryDataCriteriaCountSuccess('0'));
    yield put(AudienceActions.audienceCategoryDataCriteriaCountByZoneSuccess('0'));
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados.',
    ));
  }
}

export function* audienceCategoryDataCriteriaAnalyzeByZone({ zoneId, preferences, criteria }) {
  try {
    if (criteria.length > 0) {
      yield put(AudienceActions.audienceCategoryDataCriteriaAnalyzeByZoneLoading(true));
      const response = yield call(
        AudienceService.audienceCategoryDataCriteriaAnalyzeByZone,
        zoneId,
        preferences,
        criteria,
      );
      yield put(
        AudienceActions.audienceCategoryDataCriteriaAnalyzeByZoneSuccess(
          response.aggregations || null,
        ),
      );
      yield put(AudienceActions.audienceCategoryDataCriteriaAnalyzeByZoneLoading(false));
    } else {
      yield put(AudienceActions.audienceCategoryDataCriteriaAnalyzeByZoneSuccess(null));
    }
  } catch (e) {
    yield put(AudienceActions.audienceCategoryDataCriteriaCountSuccess('0'));
    yield put(AudienceActions.audienceCategoryDataCriteriaCountByZoneSuccess('0'));
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados.',
    ));
  }
}

export function* audienceGetMapMarkers({ criteria, event }) {
  try {
    if (criteria.length > 0) {
      const response = yield call(
        AudienceService.audienceGetMapMarkers,
        criteria,
        event,
      );
      yield put(AudienceActions.audienceGetMapMarkersSuccess(response));
    }
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados.',
    ));
  }
}

export function* audienceGetMapMarkersByZone({ zoneId, criteria, event }) {
  try {
    if (criteria.length > 0) {
      const response = yield call(
        AudienceService.audienceGetMapMarkersByZone,
        zoneId,
        criteria,
        event,
      );
      yield put(AudienceActions.audienceGetMapMarkersByZoneSuccess(response));
    }
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados.',
    ));
  }
}

export function* audienceZonesOnCreateRequest() {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(AudienceService.audienceZonesOnCreateRequest);
    yield put(AudienceActions.audienceZonesRequestSuccess(response));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as zonas.',
    ));
  }
}

export function* audienceZonesOnUpdateRequest({ audienceUuid }) {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(
      AudienceService.audienceZonesOnUpdateRequest,
      audienceUuid,
    );
    yield put(AudienceActions.audienceZonesRequestSuccess(response));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as zonas.',
    ));
  }
}

export function* audienceCloneRequest() {
  const { audienceToClone } = yield select(state => state.audience);
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(
      AudienceService.audienceCloneRequest,
      audienceToClone.uuid,
      audienceToClone.name,
    );
    // Stop loading
    yield put(requestSuccess());

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'A cópia da audiência foi realizada com sucesso.',
    ));

    yield put(AudienceActions.audienceSetClone(null));
    yield put(replace(`/audience/${response.uuid}/edit`));
    yield put(AudienceActions.audienceSetNew(null));
    yield put(AudienceActions.audienceSetUpdate(null));
    yield put(AudienceActions.audienceSetupRequest());
    yield put(AudienceActions.audienceCategoriesRequest());
    yield put(AudienceActions.audienceGetData(response.uuid));
    yield put(AudienceActions.audienceZonesOnUpdateRequest(response.uuid));
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao clonar a audiência.',
    ));
  }
}
