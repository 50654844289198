import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';

import auth from './auth';
import campaign from './campaign';
import approval from './approval';
import contract from './contract';
import provider from './provider';
import audienceTest from './audienceTest';
import { reducer as advertisingPartner } from './advertisingPartner';
import { reducer as period } from './period';
import { reducer as ad } from './ad';
import { reducer as auction } from './auction';
import { reducer as gridPeriod } from './gridPeriod';
import { reducer as channel } from './channel';
import { reducer as event } from './event';
import { reducer as confirmModal } from './confirmModal';
import { reducer as alertModal } from './alertModal';
import demo from './demo';
import { reducer as credentials } from './credentials';
import historyCampaign from './historyCampaign';
import { reducer as campaignEdit } from './campaignEdit';
import { reducer as redirect } from './redirect';
import { reducer as campaignIcon } from './campaignIcon';
import { reducer as report } from './report';
import { reducer as reportV2 } from './reportV2';
import { reducer as benefit } from './benefit';
import { reducer as terms } from './terms';
import { reducer as audience } from './audience';
import { reducer as financial } from './financial';
import { reducer as dashboard } from './dashboard';
import { reducer as campaignScheduling } from './campaignScheduling';
import { reducer as warning } from './warning';
import { reducer as cockpit } from './cockpit';
import { reducer as cockpitAnalyze } from './cockpitAnalyze';
import { reducer as pointsManagement } from './pointsManagement';
import { reducer as behavior } from './behavior';
import { reducer as product } from './product';
import { reducer as audienceExporter } from './audienceExporter';
import { reducer as newGridPeriod } from './newGridPeriod';
import { reducer as platformSettings } from './platformSettings';
import { reducer as manualSegmentation } from './manualSegmentation';

export default history => combineReducers({
  router: connectRouter(history),
  toastr: toastrReducer,
  auth,
  campaign,
  approval,
  contract,
  provider,
  audienceTest,
  advertisingPartner,
  period,
  ad,
  auction,
  gridPeriod,
  channel,
  credentials,
  event,
  confirmModal,
  alertModal,
  demo,
  historyCampaign,
  campaignEdit,
  redirect,
  campaignIcon,
  report,
  reportV2,
  benefit,
  terms,
  audience,
  financial,
  dashboard,
  campaignScheduling,
  warning,
  cockpit,
  cockpitAnalyze,
  pointsManagement,
  behavior,
  product,
  audienceExporter,
  newGridPeriod,
  platformSettings,
  manualSegmentation,
});
