import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { transparentize } from 'polished';
import { hex2rgba } from '~/Utils';


export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => hex2rgba(props.theme.colors.dark, 0.5)};
  .value-range {
    align-self: flex-end;
    float: right;
    font-size: 18px;
    font-weight: 200;
    color: ${props => props.theme.colors.regular};
    margin-right: 15px;
  }
`;


export const ModalBox = styled.div`
  position: relative;
  z-index: 2;
  padding: 50px 50px 0px 50px;
  width: 90%;
  min-width: 950px;
  height: 80%;
  min-height: 650px;
  max-height: calc(100vh - 60px);
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 5px 20px ${props => hex2rgba(props.theme.colors.dark, 0.5)};

  header{
    text-align: center;
    padding-bottom:5px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      svg {
        width: 40px;
        height: 40px;
        path {
          fill: ${props => props.theme.colors.dark};
        }
      }
      h1{
        font-size: x-large;
        color: ${props => props.theme.colors.primary};
        margin: 0;
      };
      .header-title-with-subtitle {
        text-align: left;
        span {
          font-size: large;
        }
      }
    }

    .header-disparos {
      span {
        font-size: 15px;
      }
    }
  };
  .close-button{
    border: 0px;
    background-color: transparent;
  }
`;


export const StyledDiv = styled.div`
  width: 100%;
  min-width: 400px;
  padding: 25px;
  margin: 25px 0 25px 0;
  background-color: ${props => (props.bordered ? 'transparent' : '#FCF4EE')};
  border: ${props => (props.bordered ? '1px solid #E0E0E0' : '')};
  border-radius: 36px;

  h3 {
    margin-left: 15px;
  };
`;


export const ModalContent = styled.div`
  width: 100%;
  height: 90%;

  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;


export const ContentBlock = styled.div`
  width: 100%;
  .block-summary{
    display: flex;
    justify-content: space-between;
    gap: 70px;

    .content-block-summary {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
  }
  .block-content {
    width: 100%;
    padding: 5px 15px 15px;
    margin-top: 5px;

    background-color: ${props => props.theme.colors.lighterWhite};
    border-radius: 30px;
    overflow-x: auto;
  }
`;


export const ContentBlockSummaryCard = styled.div`
  min-width: 250px;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 15px 15px;

  border-radius: 20px;
  background-color: ${props => (props.gray ? props.theme.colors.lighterWhite : '')};
  span {
    margin-left: 10px;
  }
  .card-text-info {
    font-size: xx-large;
    color: #4F7FC8;
  }
  .card-text-success {
    font-size: xx-large;
    color: #67C84F;
  }
  .div-transform-to-columns {
    display: flex;
    .text-bold {
      font-size: larger;
      font-weight: bold;
    }
  }
`;

export const TableInput = styled.input`
  height: 35px;
  background-color: transparent;
  border-color: ${props => props.theme.colors.light};
  outline-color: ${props => transparentize(0.4, props.theme.colors.primary)};
  border-radius: 20px;
  text-align: center;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}

`;


export const Select = styled(ReactSelect)`
  font-size: 20px;
  font-weight: 500;
  width: ${props => props.width || 300}px;
  .react-select__control {
    border-radius: 30px;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
  }
  .react-select__menu {
    border-radius: 30px;
  }
  .react-select__indicator{
    padding-top: 0px;
  }
  .react-select__indicator-separator{
    display: none;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const ButtonUpdate = styled.button`

  background-color: transparent;
  border: 2px solid #fd6c00;
  border-radius: 30px;

  box-shadow: 0 5px 10px ${props => hex2rgba(props.theme.colors.dark, 0.3)};

  svg {
    height: 24px;

    path {
      fill: #fd6c00;
    }
  }

  span {
    color: #fd6c00;
    font-size: 18px;
  }

  &:hover {
    background-color: ${transparentize(0.9, '#fd6c00')};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};

    svg path {
      fill: ${props => props.theme.colors.regular};
    }

    span {
      color: ${props => props.theme.colors.regular};
    }
  }
`;

export const ButtonShow = styled.button`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: ${props => `3px solid ${props.theme.colors.primary}`};
  border-radius: 50%;

  svg {
    width: 24px;
    height: 24px;
    rotate: ${props => (props.inverseIcon ? '180deg' : '')};
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }

  &:hover {
    border-color:
    ${props => (props.bordered ? transparentize(0.9, props.theme.colors.primary)
    : transparentize(0.1, props.theme.colors.primary))};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
  }
`;


export const ButtonInfo = styled.button`
  height: 40px;
  width: 40px;
  min-width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 2px solid #4F7FC8;
  border-radius: 10px;

  svg {
    height: 24px;
    path {
      fill: #4F7FC8;
    }
  }

  &:hover {
    border-color: ${transparentize(0.4, '#4F7FC8')};
    svg path {
      fill: ${transparentize(0.4, '#4F7FC8')};
    }
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
  }
`;


export const ButtonDelete = styled.button`
  height: 40px;
  width: 40px;
  min-width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 2px solid #E54B4B;
  border-radius: 10px;

  svg {
    height: 24px;
    path {
      fill: #E54B4B;
    }
  }

  &:hover {
    border-color: ${transparentize(0.4, '#E54B4B')};
    svg path {
      fill: ${transparentize(0.4, '#E54B4B')};
    }
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
  }
`;


export const ButtonAdd = styled.button`
  height: 40px;
  min-width: max-content;
  padding-left: 20px;
  padding-right: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

  background-color: transparent;
  border: 2px solid #67C84F;
  border-radius: 30px;

  box-shadow: 0 5px 10px ${props => hex2rgba(props.theme.colors.dark, 0.3)};

  svg {
    height: 24px;
    path {
      fill: #67C84F;
    }
  }

  span {
    color: #67C84F;
    font-size: 18px;
  }

  &:hover {
    background-color: ${transparentize(0.9, '#67C84F')};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
  }
`;


export const Table = styled.table`
  width: 100%;
  min-width: 1400px;

  thead {
    font-size: large;
    color: ${props => props.theme.colors.primary};
    th {
      text-align: left;
      padding: 20px 0 15px 10px;
    }
    th:first-child {
      padding-left: 15px;
      text-align: left;
    }
    th:last-child {
      text-align: center;
    }
  }

  tbody {
    tr{
      td {
        height: 60px;
        padding-left: 10px;
      }
      .grid-content-ranking-input {
        width: 50px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        margin-right: 10px;
      }
      .input-div {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .brl-symbol {
          margin-right: 10px;
        }
      }
      .info-button-div,
      .delete-button-div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      td:first-child {
        padding-left: 15px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    tr:last-child {
      border-bottom: none;
    }
    // remoção de setas dos inputs number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // remoção de setas dos inputs number - firefox
    input[type=number]{
      -moz-appearance: textfield;
    }
  }
  .grid-separator {
    td {
      height: 1px;
      padding: 0;
      border-bottom: 1px solid ${props => props.theme.colors.light};
    }
  }
`;

export const BoxDailyVolumes = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
`;

export const BoxDailyVolumesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  ${props => props.separator && css`
    border-right: 1px solid ${props.theme.colors.primary};
    margin: 10px 0;
  `}
  label {
    display: flex;
    align-items: center;
  }
`;

export const InfoWeek = styled.span`
  cursor: pointer;
  margin-left: 5px;
  svg {
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
  }
`;
