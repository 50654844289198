import React from 'react';
import PropTypes from 'prop-types';

import { CreatableSelectComponent } from './styles';

const CreatableSelect = ({
  id,
  name,
  placeholder,
  onChange,
  options,
  value,
  isClearable,
  className,
  ...rest
}) => (
  <CreatableSelectComponent
    id={id}
    name={name}
    placeholder={placeholder}
    onChange={onChange}
    options={options}
    value={value}
    isClearable={isClearable}
    components={
      { DropdownIndicator: null }
    }
    className={className}
    classNamePrefix="react-select"
    formatCreateLabel={inputValue => (
      <p>
        {' '}
        <b>Criar:</b>
        {' '}
        {inputValue}
      </p>
    )}
    {...rest}
  />
);

CreatableSelect.defaultProps = {
  name: null,
  placeholder: '',
  isClearable: false,
};

CreatableSelect.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
};

export default CreatableSelect;
