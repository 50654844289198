import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: hidden;
  min-width: 400px;

  @media(max-width: 1100px){
    overflow-x: scroll;
  }
`;

export const ContainerTable = styled.table`

  /* display:block; */
  min-width: 1100px;

  width: 100%;

  tbody{

    tr{
      font-size: 18px;
      border-radius: 15px;
      border-bottom: 1px solid ${props => props.theme.colors.light};
      height: 75px;

      td{
        &:first-child{
          padding-left: 15px;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
        }

        &:last-child{
          height: inherit;
          border-top-right-radius: 15px;
          border-bottom-right-radius: 15px;
        }
      }

      &:hover{
        color: ${props => props.theme.colors.primary};
        background-color: rgba(196, 242, 203, 0.15);
        transition: color 0.2s, background-color 0.2s;
        .link{
          color: ${props => props.theme.colors.primary};
          &:hover{
            text-decoration: none;
          }
        }
      }

      &:last-child{
        border-bottom: 0;
      }

    }

  }

`;
