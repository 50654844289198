import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Toggle = ({
  id,
  name,
  onClick,
  checked,
  className,
  disabled,
}) => (
  <>
    <Container checked={checked} disabled={disabled}>
      <input type="checkbox" checked={checked} id={id} name={name} onClick={onClick} className={className} disabled={disabled} />
      <span />
    </Container>
  </>
);

Toggle.defaultProps = {
  disabled: false,
  className: null,
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
};

export default Toggle;
