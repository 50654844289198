import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  openModalNewManualSegmentation: [],
  closeModalNewManualSegmentation: [],
  loadConfigTemplateData: ['configTemplates'],
  loadSubcategories: ['subcategories'],
  getSegmentationData: ['segmentationUuid'],
  loadSegmentationData: ['segmentation'],
  createSegmentation: ['newSegmentation', 'csvFile'],
  getSegmentations: ['name', 'userKey', 'page'],
  loadSegmentations: ['segmentations'],
});

export const ManualSegmentationTypes = Types;
export default Creators;


/* Initial State */
const INITIAL_STATE = Immutable({
  show: false,
  configTemplates: [],
  subcategories: [],
  segmentation: {},
  segmentations: [],
  segmentationsPaginate: [],
  paginate: { page: 0, count: 0 },
});

/* Reducers */
const openModalNewManualSegmentation = state => ({
  ...state,
  show: true,
});

const closeModalNewManualSegmentation = state => ({
  ...state,
  show: false,
});

const loadConfigTemplateData = (state, { configTemplates }) => ({
  ...state,
  configTemplates,
});

const loadSubcategories = (state, { subcategories }) => ({
  ...state,
  subcategories,
});

const loadSegmentationData = (state, { segmentation }) => ({
  ...state,
  segmentation,
});

const loadSegmentations = (state, { segmentations: { data, page, totalElements } }) => ({
  ...state,
  segmentations: data,
  paginate: {
    page,
    count: totalElements / 20,
  },
});

export const reducer = createReducer(INITIAL_STATE, {
  [ManualSegmentationTypes.OPEN_MODAL_NEW_MANUAL_SEGMENTATION]: openModalNewManualSegmentation,
  [ManualSegmentationTypes.CLOSE_MODAL_NEW_MANUAL_SEGMENTATION]: closeModalNewManualSegmentation,
  [ManualSegmentationTypes.LOAD_CONFIG_TEMPLATE_DATA]: loadConfigTemplateData,
  [ManualSegmentationTypes.LOAD_SUBCATEGORIES]: loadSubcategories,
  [ManualSegmentationTypes.LOAD_SEGMENTATION_DATA]: loadSegmentationData,
  [ManualSegmentationTypes.LOAD_SEGMENTATIONS]: loadSegmentations,
});
