import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import uuidv4 from 'uuid/v4';

import moment from 'moment';
import { jsonToCSV } from 'react-papaparse';
import FileSaver from 'file-saver';
import MediaQuestionario from './Pages/MediaQuestionario';
import MediaImagem from './Pages/MediaImagem';
import MediaVideo from './Pages/MediaVideo';
import IncludeHasPermission from '~/Components/IncludeHasPermission';

import { Creators as ReportCreators } from '~/redux/ducks/reportV2';
import { Creators as AudienceExporterCreators } from '~/redux/ducks/audienceExporter';
import { Creators as RedirectActions } from '~/redux/ducks/redirect';

import Breadcrumb from '~/Components/Breadcrumb';
import {
  Button, Table, RadialChart, LineChart, BarChart, ModalExport,
} from './Components';
import { general } from '~/Styles';
import {
  Wrap,
  Container,
  GuideLine,
  Row,
  Column,
  ColumnBenefit,
  ItemBenefit,
  SingleHorizontalChart,
  SingleHorizontalChartTotal,
  CheckButton,
  Divider,
  ButtonMedia,
  BaseInfoMedias,
} from './styles';
import { getFormattedValueByType, getPercent } from '~/Utils';
import DataEmpty from './Components/DataEmpty';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';

const MODE_EVENTO = 'EVENTO';
const MODE_ACUMULADO = 'ACUMULADO';

const downloadCsv = (data) => {
  const dataConvert = [
    ...data.map(item => [
      item.date,
      getFormattedValueByType('decimal', item.delivered),
      getFormattedValueByType('decimal', item.impressions),
      getFormattedValueByType('decimal', item.impact),
      getFormattedValueByType('decimal', item.clicks),
      `${getFormattedValueByType('decimal', item.ctrPercent)}%`,
      getFormattedValueByType('decimal', item.benefits.pre),
      getFormattedValueByType('decimal', item.benefits.pos),
    ]),
  ];
  const fields = ['Data', 'Entregue', 'Impressão', 'Impacto', 'Cliques', 'CTR', 'Benefícios Concedidos - Antecipado', 'Benefícios Concedidos - Final'];
  const csvContent = new Blob(['\ufeff', jsonToCSV({ fields, data: dataConvert })], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(csvContent, 'dados.csv');
};


function Resultado({
  theme,
  match: { params: { campaignId } },
  loadDataReportDashboard,
  cleanDataReportDashboard,
  campaign,
  campaignResult,
  benefit,
  periodResults,
  campaignMedias,
  navigateToRoute,
  openModalAudienceExporter,
}) {
  const channelType = campaign && campaign.channel ? campaign.channel.type : null;

  const bread = [
    { name: 'Home', route: '/' },
    { name: 'Campanhas', route: '/campaigns' },
    { name: 'Editar', route: `/campaigns/edit/${campaignId}` },
    { name: 'Relatório', route: '#' },
  ];

  const [chartDisparosMode, setChartDisparosMode] = useState(MODE_EVENTO);
  const [dataChartDisparos, setDataChartDisparos] = useState(null);

  const [tableDados, setTableDados] = useState([]);

  const [medias, setMedias] = useState([]);
  const [indexCurrentMedia, setIndexCurrentMedia] = useState(0);

  function loadData() {
    loadDataReportDashboard(campaignId);
  }

  function makeMedias(data) {
    const mediasUpdate = data.map(d => ({
      ...d,
      uuid: d.uuid || uuidv4(),
    }));
    setMedias(mediasUpdate);
    setIndexCurrentMedia(0);
  }

  function makeDataTableDados(data) {
    const tableDadosUpdate = data.map(r => ({
      ...r,
      date: moment(r.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
    }));
    setTableDados(tableDadosUpdate);
  }

  function makeDataChartDisparosDados(data) {
    const chartDisparos = {
      labels: [],
      values: {
        events: {
          delivered: [],
          impact: [],
          impressions: [],
        },
        accumuled: {
          delivered: [],
          impact: [],
          impressions: [],
        },
      },
    };
    data.forEach(({
      date, delivered, impact, impressions,
    }) => {
      chartDisparos.labels.push(moment(date, 'YYYY-MM-DD').format('DD/MM'));
      chartDisparos.values.events.delivered.push(delivered);
      chartDisparos.values.events.impact.push(impact);
      chartDisparos.values.events.impressions.push(impressions);

      const lastAccumuledDelivered = chartDisparos.values.accumuled.delivered[chartDisparos.values.accumuled.delivered.length - 1] || 0;
      chartDisparos.values.accumuled.delivered.push(lastAccumuledDelivered + delivered);
      const lastAccumuledImpact = chartDisparos.values.accumuled.impact[chartDisparos.values.accumuled.impact.length - 1] || 0;
      chartDisparos.values.accumuled.impact.push(lastAccumuledImpact + impact);
      const lastAccumuledImpressions = chartDisparos.values.accumuled.impressions[chartDisparos.values.accumuled.impressions.length - 1] || 0;
      chartDisparos.values.accumuled.impressions.push(lastAccumuledImpressions + impressions);
    });

    const m = data.reduce((total, d) => {
      total.delivered += d.delivered;
      total.impact += d.impact;
      total.impressions += d.impressions;
      return total;
    }, { delivered: 0, impact: 0, impressions: 0 });
    m.delivered /= chartDisparos.labels.length;
    m.impact /= chartDisparos.labels.length;
    m.impressions /= chartDisparos.labels.length;
    chartDisparos.medias = m;

    setDataChartDisparos(chartDisparos);
  }

  useEffect(() => {
    if (periodResults && periodResults.results && periodResults.results.length > 0) {
      const dailyResults = periodResults.results.reduce((result, i) => ([...result, ...i.dailyResults]), []);
      makeDataTableDados(dailyResults);
      makeDataChartDisparosDados(dailyResults);
    }
  }, [periodResults]);

  useEffect(() => {
    if (campaignMedias) {
      makeMedias(campaignMedias);
    }
  }, [campaignMedias]);

  useEffect(() => {
    loadData();
    return () => {
      cleanDataReportDashboard();
    };
  }, []);

  function handleChangeModeDisparos(mode) {
    setChartDisparosMode(mode);
  }

  function renderChartDisparos() {
    if (!chartDisparosMode) {
      return null;
    }

    let values;
    if (dataChartDisparos) {
      values = chartDisparosMode === MODE_EVENTO ? dataChartDisparos.values.events : dataChartDisparos.values.accumuled;
    }

    const isDataValues = dataChartDisparos && dataChartDisparos.labels && dataChartDisparos.labels.length > 1;

    return (
      <div className="box eventos-chart">
        <Row className="space">
          <img src={general.imageCampaign['icon-money.png']} alt="" />

          <CheckButton
            checked={chartDisparosMode === MODE_EVENTO}
            onClick={() => handleChangeModeDisparos(MODE_EVENTO)}
          >
            <span>Eventos</span>
          </CheckButton>
          <CheckButton
            checked={chartDisparosMode === MODE_ACUMULADO}
            onClick={() => handleChangeModeDisparos(MODE_ACUMULADO)}
          >
            <span>Acumulado</span>
          </CheckButton>

        </Row>
        <Row className="chart-compare">
          <Column className="chart-line">
            {isDataValues ? (
              <LineChart
                values={[
                  {
                    name: 'Entregue',
                    data: values.delivered,
                  },
                  {
                    name: 'Impressão',
                    data: values.impressions,
                  },
                  {
                    name: 'Impacto',
                    data: values.impact,
                  },
                ]}

                labels={dataChartDisparos.labels}
              />
            ) : <DataEmpty />}
          </Column>
          <Column>
            {isDataValues && (
              <BaseInfoMedias>
                <span>Médias</span>
                <div className="line" />
                <span className="primary bigger">{getFormattedValueByType('decimal', dataChartDisparos.medias.delivered || 0)}</span>
                <div className="circle" />
                <span className="secundary bigger">{getFormattedValueByType('decimal', dataChartDisparos.medias.impressions || 0)}</span>
                <div className="circle" />
                <span className="third bigger">{getFormattedValueByType('decimal', dataChartDisparos.medias.impact || 0)}</span>
              </BaseInfoMedias>
            )}

          </Column>
        </Row>
        {isDataValues && (
          <Row className="subtitle-line-chart">
            <div className="line primary " />
            <label>Entregue</label>
            <div className="line secundary " />
            <label>Impressão</label>
            <div className="line third " />
            <label>Impacto</label>

          </Row>
        )}
      </div>
    );
  }

  function renderCronogramaEntregas() {
    const { accomplishedStatus, remainingStatus } = campaignResult;

    const isCampanhaEmAndamento = remainingStatus.time.minutes > 0;

    const dailyResults = periodResults && periodResults.results && periodResults.results.length > 0 ? periodResults.results[0].dailyResults : [];
    const cronogramaDiario = dailyResults.map(({ date, impact }) => ({
      date: moment(date, 'YYYY-MM-DD').format('DD/MM'),
      impact,
      type: 'IMPACT',
    }));
    return (
      <div className="box cronograma-entrega">
        <Row className="space">
          <img src={general.imageCampaign['icon-calendar.png']} alt="" />
          <h2>Cronograma x Entregas</h2>
        </Row>

        <div className="grid-cronograma-entrega">

          <div className="grid-dados">

            <Row className="dados-cronograma space">
              <Column className="space" style={{ minWidth: 75 }}>
                <label>{campaign.startDate ? moment(campaign.startDate).format('DD/MM/YYYY') : 'Sem data'}</label>
                <span className="bigger primary">{campaign.startDate ? moment(campaign.startDate).format('HH:mm') : 'Inicial'}</span>
              </Column>
              <img src={general.imageCampaign['icon-arrow-big.png']} alt="" />
              <Column className="space" style={{ minWidth: 75 }}>
                <label>{campaign.endDate ? moment(campaign.endDate).format('DD/MM/YYYY') : 'Sem data'}</label>
                <span className="bigger primary">{campaign.endDate ? moment(campaign.endDate).format('HH:mm') : 'Final'}</span>
              </Column>
              <Column className="chart">
                <div className="grid-chart">
                  <div className="time-left">
                    <span className="bigger">{`${accomplishedStatus.time.percent}%`}</span>
                  </div>
                  <RadialChart
                    size={100}
                    values={[accomplishedStatus.time.percent]}
                    padding={{
                      top: -20,
                      bottom: -20,
                    }}
                  />
                </div>
              </Column>
            </Row>
            <Row className="dados-entrega space">
              <Column className="space">
                <label>Impactos Realizados</label>
                <span className="bigger chart-color">
                  {`${getFormattedValueByType('decimal', accomplishedStatus.impact.value)} `}
                  <span>{`/ ${getFormattedValueByType('decimal', campaign.impact)}`}</span>
                </span>
              </Column>
              <Column className="chart">
                <div className="grid-chart">
                  <div className="time-left">
                    <span className="bigger">{`${accomplishedStatus.impact.percent}%`}</span>
                  </div>
                  <RadialChart
                    size={100}
                    values={[accomplishedStatus.impact.percent]}
                    color={[theme.colors.chart[1] || '']}
                    padding={{
                      top: -20,
                      bottom: -20,
                    }}
                  />
                </div>
              </Column>
            </Row>

            <Row className="dados-meta">
              <Column className="space">
                <label>Média de impactos/dias para Cumprir a Meta</label>
                {isCampanhaEmAndamento ? (
                  <span className="bigger">
                    {`${getFormattedValueByType('decimal', remainingStatus.impact.value)} `}
                    <span>{`/ ${remainingStatus.time.days} dias`}</span>
                    &nbsp;=&nbsp;
                    <span className="bigger chart-color">{`${getFormattedValueByType('decimal', (remainingStatus.impact.daily))} / dia`}</span>
                  </span>
                ) : (
                  <span className="bigger">
                    Campanha encerrada
                  </span>
                )}
              </Column>
              <div className="bar-subtitle" />
            </Row>
          </div>
          <div className="grid-chart">
            <div className="time-left">
              {isCampanhaEmAndamento ? (
                <span className="bigger">
                  {`${remainingStatus.time.days} `}
                  <span className="smaller">dias</span>
                </span>
              ) : (
                <span className="bigger">
                  Encerrada
                </span>
              )}
              {isCampanhaEmAndamento && <label>Tempo Restante</label>}
            </div>
            <RadialChart
              size={350}
              values={[accomplishedStatus.time.percent, accomplishedStatus.impact.percent]}
              color={theme.colors.chart}
              padding={{
                top: -20,
                bottom: -20,
              }}
            />
          </div>

        </div>
        <BarChart data={cronogramaDiario} remainingStatus={remainingStatus} />
      </div>
    );
  }

  function renderCustoEstimadoRealizado() {
    const { accomplishedStatus, remainingStatus } = campaignResult;
    const realizado = {
      custoEnvio: accomplishedStatus.cost.value / accomplishedStatus.impact.value || 0,
      impacto: accomplishedStatus.impact.value,
      custoTotal: accomplishedStatus.cost.value,
    };

    const estimado = {
      custoEnvio: 0,
      impacto: accomplishedStatus.impact.value + remainingStatus.impact.value,
      custoTotal: accomplishedStatus.cost.value + remainingStatus.cost.value,
    };
    estimado.custoEnvio = estimado.custoTotal / estimado.impacto || 0;

    const isExistsCusto = !!estimado.custoEnvio;

    return (
      <div className="box estimado-realizado">
        <Row className="space">
          <img src={general.imageCampaign['icon-money.png']} alt="Custo Estimado x Realizado" />
          <h2>Custo Estimado x Realizado</h2>
        </Row>
        {!isExistsCusto && (
          <DataEmpty label="Custo por entrega não informado." />
        )}
        {isExistsCusto && (
          <>
            <Row className="space">

              <Column className="space estimado">
                <h2>Estimado</h2>
                <Column className="space">
                  <label>Custo por envio:</label>
                  <span className="smaller">
                    {'R$ '}
                    <span className="bigger">{getFormattedValueByType('float', estimado.custoEnvio)}</span>

                  </span>
                </Column>

                <Column className="space">
                  <label>Impacto:</label>
                  <span className="bigger">{getFormattedValueByType('decimal', estimado.impacto)}</span>
                </Column>

                <Column className="space">
                  <label>Custo Total:</label>
                  <span className="smaller">
                    {'R$ '}
                    <span className="bigger">{getFormattedValueByType('float', estimado.custoTotal)}</span>
                  </span>
                </Column>
              </Column>
              <Column className="maths">
                <div className="line" />
                <img src={general.imageCampaign['icon-multiply.png']} alt="Múltiplo" />
                <div className="line" />
                <img src={general.imageCampaign['icon-equal.png']} alt="Igual" />
                <div className="line" />
              </Column>

              <Column className="space realizado">
                <h2>Realizado</h2>

                <Column className="space">
                  <label>Custo por envio:</label>
                  <span className="smaller">
                    {'R$ '}
                    <span className="bigger">{getFormattedValueByType('float', realizado.custoEnvio)}</span>
                  </span>
                </Column>

                <Column className="space">
                  <label>Impacto:</label>
                  <span className="bigger">{getFormattedValueByType('decimal', realizado.impacto)}</span>
                </Column>

                <Column className="space">
                  <label>Custo Total:</label>
                  <span className="smaller">
                    {'R$ '}
                    <span className="bigger">{getFormattedValueByType('float', realizado.custoTotal)}</span>
                  </span>
                </Column>

              </Column>
            </Row>

            <span className="bigger primary ">
              {`${realizado.custoTotal === estimado.custoTotal ? 100 : getPercent(realizado.custoTotal, estimado.custoTotal)}% `}
              <span>Custo Total</span>
            </span>
            <SingleHorizontalChart>
              <SingleHorizontalChartTotal value={realizado.custoTotal === estimado.custoTotal ? 100 : getPercent(realizado.custoTotal, estimado.custoTotal)} />
            </SingleHorizontalChart>
            <Row className="subtitles">
              <div className="square" />
              <label>Estimado</label>
              <div className="square primary" />
              <label>Realizado</label>
            </Row>
          </>
        )}


      </div>
    );
  }

  function renderBeneficios() {
    const benefitsPos = benefit.filter(b => b.offerType === 'POS');
    const benefitsPre = benefit.filter(b => b.offerType === 'PRE');
    return (
      <div className="box beneficios">
        <Row className="header">

          <Row>
            <img src={general.imageCampaign['icon-benefits.png']} alt="" />
            <h2>Benefícios</h2>
            {/* <Button onClick={() => { }} className="btn-anchor">
              <img src={general.imageCampaign['icon-anchor-link.png']} alt="" />
            </Button> */}
          </Row>

          {/* <Button title="Download Benefícios" onClick={() => { }}>
            <img src={general.imageCampaign['icon-download-white.png']} alt="" />
          </Button> */}
        </Row>

        <ColumnBenefit className="items">
          <h2>Concessão Antecipada</h2>
          {benefitsPre && benefitsPre.length ? benefitsPre.map(b => (
            <ItemBenefit key={b.uuid}>
              <span>{b.name}</span>
              <span>{`${getFormattedValueByType('decimal', b.quantity)}${b.unit}`}</span>
              <span className="total">
                {b.accomplishedImpact}
                <span className="smaller">{` / ${b.estimatedImpact}`}</span>
              </span>
            </ItemBenefit>
          )) : <DataEmpty label="Nenhum benefício configurado." />}
        </ColumnBenefit>

        <ColumnBenefit className="items">
          <h2>Concessão Final</h2>
          {benefitsPos && benefitsPos.length
            ? benefitsPos.map(b => (
              <ItemBenefit key={b.uuid}>
                <span>{b.name}</span>
                <span>{`${getFormattedValueByType('decimal', b.quantity)}${b.unit}`}</span>
                <span className="total">
                  {b.accomplishedImpact}
                  <span className="smaller">{` / ${b.estimatedImpact}`}</span>
                </span>
              </ItemBenefit>
            )) : <DataEmpty label="Nenhum benefício configurado." />}
        </ColumnBenefit>
      </div>
    );
  }

  function renderImpactoContratado() {
    return (
      <div className="box impacto-contratado">
        <Row className="space">
          <img src={general.imageCampaign['icon-impact.png']} alt="Impacto Contratado" />
          <h2>Impacto Contratado</h2>
        </Row>
        <span>
          {channelType === 'PUSH' && 'Envio'}
          {channelType === 'PULL' && `${getFormattedValueByType('decimal', campaign.impact)} - ${campaign.impactType}`}
        </span>
      </div>
    );
  }

  function renderAudiencia() {
    return (
      <div className="box audiencia">
        <Row className="space">
          <img src={general.imageCampaign['icon-target.png']} alt="Audiência" />
          <h2>Audiência</h2>
        </Row>
        <Column className="base-info-audiencia">
          {campaign.audience ? (
            <>
              <Column className="space">
                <label>Nome</label>
                <span>{campaign.audience.name}</span>
              </Column>
              <Row className="info-secondary-audiencia">
                <Column className="space">
                  <label>Total</label>
                  <span>{getFormattedValueByType('decimal', campaign.audience.totalWithOptin)}</span>
                </Column>
              </Row>
            </>
          ) : (
            <Column className="space">
              <DataEmpty label="Não existe audiência configurada." />
            </Column>
          )}
        </Column>
      </div>
    );
  }

  function renderCTR() {
    const {
      clicks, impressions, delivered, ctrPercent,
    } = campaignResult.accomplishedStatus.event;

    const valueDivisor = channelType === 'PUSH' ? delivered : impressions;
    return (
      <div className="box ctr">
        <Row>
          <img src={general.imageCampaign['icon-ctr.png']} alt="CTR" />
          <h2>CTR</h2>
        </Row>
        <Row className="base-values-chart">
          <Column className="chart">
            <RadialChart
              size={250}
              values={[ctrPercent || 0]}
              showDataLabels
              padding={{
                top: -20,
                bottom: -20,
              }}
            />
          </Column>
          <Column className="values">
            <label>{`Cliques por ${channelType === 'PUSH' ? 'Envio' : 'Impressão'}:`}</label>
            <span>
              <span className="bigger">{getFormattedValueByType('decimal', clicks || 0)}</span>
              {` / ${getFormattedValueByType('decimal', valueDivisor || 0)}`}
            </span>
          </Column>
        </Row>

      </div>
    );
  }

  function renderErrors() {
    const { errors } = campaignResult;

    if (!errors) {
      return null;
    }

    const { delivery, benefit: benefitErrors } = errors;

    return (
      <Container>
        <GuideLine color={[theme.colors.dark]} />

        <Row className="header">
          <Row>
            <h1>Erros</h1>
            {/* <Button onClick={() => { }} className="btn-anchor big">
              <img src={general.imageCampaign['icon-anchor-link.png']} alt="" />
            </Button> */}
          </Row>
        </Row>
        <div className="box erros">
          <Divider />
          <Row>
            <Column>
              <Row className="erros-title">
                <img src={general.imageCampaign['icon-errors.png']} alt="Entregas" />
                <h2>Entregas</h2>
              </Row>
              <Row>
                <span>
                  <span className="bigger">{getFormattedValueByType('decimal', delivery.value)}</span>
                  {` / ${getFormattedValueByType('decimal', delivery.base)}`}
                </span>
              </Row>
            </Column>
            <Column className="chart">
              <RadialChart
                size={140}
                values={[delivery.percent]}
                showDataLabels
                textSize="25pt"
                padding={{
                  top: -20,
                  bottom: -20,
                }}
                color={[theme.colors.regular]}
              />
            </Column>

          </Row>
          <Divider />
          <Row>
            <Column>
              <Row className="erros-title">
                <img src={general.imageCampaign['icon-errors.png']} alt="Benefícios" />
                <h2>Benefícios</h2>
              </Row>
              <Row>
                <span>
                  <span className="bigger">{getFormattedValueByType('decimal', benefitErrors.total.value)}</span>
                  {` / ${getFormattedValueByType('decimal', benefitErrors.total.base)}`}
                </span>
              </Row>
            </Column>
            <Column className="chart">
              <RadialChart
                size={140}
                values={[benefitErrors.total.percent]}
                showDataLabels
                textSize="25pt"
                padding={{
                  top: -20,
                  bottom: -20,
                }}
                color={[theme.colors.regular]}
              />
            </Column>

          </Row>

        </div>


      </Container>
    );
  }

  function renderMidias() {
    function renderTabMidia(media, index) {
      let icon = '';
      let alt = '';
      let label = '';
      switch (media.type) {
        case CreativeCustomMidiaType.VAST:
          icon = 'icon-video';
          alt = 'Botão Mídia de Vast';
          label = 'Vast';
          break;
        case CreativeCustomMidiaType.VPAID:
          icon = 'icon-video';
          alt = 'Botão Mídia de Vpaid';
          label = 'Vpaid';
          break;
        case CreativeCustomMidiaType.VIDEO:
          icon = 'icon-video';
          alt = 'Botão Mídia de Vídeo';
          label = 'Vídeo';
          break;
        case CreativeCustomMidiaType.DATA_SEARCH:
          icon = 'icon-file';
          alt = 'Botão Questionário';
          label = 'Questionário';
          break;
        case CreativeCustomMidiaType.IMAGE:
          icon = 'icon-image';
          alt = 'Botão Imagem';
          label = 'Imagem';
          break;
        default:
      }

      return (
        <ButtonMedia
          key={media.uuid}
          onClick={() => setIndexCurrentMedia(index)}
          color={indexCurrentMedia === index ? theme.colors.primary : null}
        >
          <img src={general.imageCampaign[`${icon}${indexCurrentMedia === index ? '-white' : ''}.png`]} alt={alt} width={50} />
          <span>{label}</span>
        </ButtonMedia>
      );
    }

    function renderContentCurrentMedia() {
      const currentMedia = medias[indexCurrentMedia];
      if (currentMedia) {
        switch (currentMedia.type) {
          case CreativeCustomMidiaType.VAST:
          case CreativeCustomMidiaType.VPAID:
          case CreativeCustomMidiaType.VIDEO:
            return <MediaVideo media={currentMedia} />;
          case CreativeCustomMidiaType.DATA_SEARCH:
            return <MediaQuestionario media={currentMedia} />;
          case CreativeCustomMidiaType.IMAGE:
            return <MediaImagem media={currentMedia} />;
          default:
            return null;
        }
      }
      return null;
    }

    if (!medias || medias.length === 0) {
      return null;
    }

    return (
      <Container>
        <GuideLine color={[theme.colors.primary]} />

        <Row className="header">
          <Row>
            <h1>Mídias Inclusas</h1>
            {/* <Button onClick={() => { }} className="btn-anchor big">
              <img src={general.imageCampaign['icon-anchor-link.png']} alt="Mídias Inclusas" />
            </Button> */}
          </Row>
        </Row>

        <Row className="tabs">
          {medias.map((media, index) => renderTabMidia(media, index))}
        </Row>

        {renderContentCurrentMedia()}

      </Container>
    );
  }

  if (!campaign) {
    return null;
  }

  return (
    <Wrap>
      <Breadcrumb items={bread} />

      <Container>
        <GuideLine />

        {/* TITULO DA CAMPANHA */}
        <Row className="title-campaign">
          <Column>
            <h1>{campaign.name}</h1>
            <label>Nome da Campanha</label>
          </Column>

          <Row className="action">
            <Button
              bordered
              title="Voltar"
              onClick={() => {
                navigateToRoute(
                  `/campaigns/edit/${campaignId}`,
                );
              }}
              type="button"
            />
            <Button title="Atualizar Dados" onClick={() => loadData()}>
              <img src={general.imageCampaign['icon-update.png']} alt="Atualizar" className="update" />
            </Button>
            <IncludeHasPermission roles={['AUDIENCE_RETARGET']}>
              <Button
                title="Exportar Audiência"
                onClick={() => {
                  openModalAudienceExporter(true);
                }}
              >
                <img src={general.imageCampaign['icon-export.png']} alt="Exportar" className="export" />
              </Button>
            </IncludeHasPermission>
            {/* <Button onClick={() => { }}>
              <img src={general.imageCampaign['icon-settings.png']} alt="Configurações" />
            </Button> */}
          </Row>
        </Row>


        <div className="grid">
          {/* BOX TIPO DA CAMPANHA */}
          <div className="box tipo">
            <Row className="space">
              <div className="type-box">
                <img src={general.image['push-notification-dark.png']} alt="Tipo" />
              </div>
              <h2>Tipo</h2>
            </Row>
            <span>{campaign.type ? campaign.type.name : ''}</span>
          </div>
          {/* BOX CTR */}
          {renderCTR()}
          {/* BOX IMPACTO CONTRATADO */}
          {renderImpactoContratado()}
          {/* BOX AUDIENCIA */}
          {renderAudiencia()}
          {/* BOX ESTIMADO REALIZADO */}
          {renderCustoEstimadoRealizado()}
          {/* BOX BENEFÍCIOS */}
          {renderBeneficios()}


          {/* BOX TABELA DADOS */}
          <div className="box dados">
            <Row className="header">
              <Row>
                <h2>Dados</h2>
                {/* <Button onClick={() => { }} className="btn-anchor">
                  <img src={general.imageCampaign['icon-anchor-link.png']} alt="" />
                </Button> */}
              </Row>

              <Button
                id="btn-download-data"
                disabled={!(tableDados && tableDados.length > 0)}
                tooltip={!(tableDados && tableDados.length > 0) ? 'Não existe arquivo para download no momento' : null}
                title="Download Dados"
                onClick={() => downloadCsv(tableDados)}
              >
                <img src={general.imageCampaign['icon-download-white.png']} alt="" />
              </Button>
            </Row>
            {tableDados && tableDados.length > 0 ? <Table channelType={channelType} data={tableDados} /> : <DataEmpty label="Gráfico disponível a partir de dois dias de dados." />}
          </div>


          {/* BOX CRONOGRAMA X ENTREGA */}
          {renderCronogramaEntregas()}


          {/* BOX CRONOGRAMA X ENTREGA */}
          {renderChartDisparos()}

        </div>
      </Container>


      {renderMidias()}


      {/* ERROS */}
      {renderErrors()}


      <ModalExport campaignUuid={campaignId} />
    </Wrap>

  );
}

const mapStateToProps = ({
  reportV2: {
    campaign,
    campaignResult,
    benefit,
    periodResults,
    campaignMedias,
  },
}) => ({
  campaign,
  campaignResult,
  benefit,
  periodResults,
  campaignMedias,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadDataReportDashboard: ReportCreators.loadDataReportDashboard,
    cleanDataReportDashboard: ReportCreators.cleanDataReportDashboard,
    navigateToRoute: RedirectActions.navigateToRoute,
    openModalAudienceExporter: AudienceExporterCreators.changeShowModal,
  },
  dispatch,
);

Resultado.defaultProps = {
  campaign: null,
  campaignResult: null,
  benefit: [],
  periodResults: [],
  campaignMedias: [],
};

Resultado.propTypes = {
  campaign: PropTypes.shape(),
  campaignResult: PropTypes.shape(),
  benefit: PropTypes.arrayOf(PropTypes.shape()),
  periodResults: PropTypes.arrayOf(PropTypes.shape()),
  campaignMedias: PropTypes.arrayOf(PropTypes.shape()),
  match: PropTypes.shape().isRequired,
  loadDataReportDashboard: PropTypes.func.isRequired,
  cleanDataReportDashboard: PropTypes.func.isRequired,
  navigateToRoute: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  openModalAudienceExporter: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(Resultado));
