export default {
  dataFilterToCriteria: (dataFilter) => {
    let criteriaList = [];
    const createMain = (toCreate) => {
      const hasInCriteria = criteriaList.find(
        criteria => criteria.category === toCreate.path[0],
      );
      if (hasInCriteria) {
        criteriaList = criteriaList.map((criteria) => {
          if (criteria.category === toCreate.path[0]) {
            return {
              ...criteria,
              type: toCreate.breadcrumbs.length === 1 ? toCreate.type : criteria.type || '',
              source: toCreate.source,
              label: toCreate.label,
            };
          }
          return criteria;
        });
      } else {
        criteriaList.push({
          category: toCreate.path[0],
          value: toCreate.breadcrumbs[0],
          type: toCreate.breadcrumbs.length === 1 ? toCreate.type : '',
          data: [],
          source: toCreate.source,
          label: toCreate.label,
        });
      }
    };
    const createChildrens = (filter, data) => {
      if (filter.path.length === 1) {
        const hasInData = data.find(
          item => item.key === filter.path[0] && item.value === filter.value,
        );
        if (hasInData) {
          return data.map((item) => {
            if (item.key === filter.path[0] && item.value === filter.breadcrumbs[0]) {
              return {
                ...item,
                type: filter.type || item.type,
                label: filter.label,
              };
            }
            return item;
          });
        }
        return [
          ...data,
          {
            key: filter.path[0],
            value: filter.value,
            label: filter.label,
          },
        ];
      }
      if (filter.path.length > 1) {
        const hasInData = data.find(
          item => item.key === filter.path[0] && item.value === filter.breadcrumbs[0],
        );
        if (hasInData) {
          return data.map((item) => {
            if (item.key === filter.path[0] && item.value === filter.breadcrumbs[0]) {
              return {
                ...item,
                type: filter.breadcrumbs.length === 1 ? filter.type : item.type,
                data: createChildrens(
                  {
                    ...filter,
                    path: filter.path.filter((_, index) => index > 0),
                    breadcrumbs: filter.breadcrumbs.filter((_, index) => index > 0),
                  },
                  item.data || [],
                ),
                label: filter.label,
              };
            }
            return item;
          });
        }
        return [
          ...data,
          {
            key: filter.path[0],
            value: filter.breadcrumbs[0],
            type: filter.breadcrumbs.length === 1 ? filter.type : '',
            data: createChildrens(
              {
                ...filter,
                path: filter.path.filter((_, index) => index > 0),
                breadcrumbs: filter.breadcrumbs.filter((_, index) => index > 0),
              },
              [],
            ),
            source: filter.source,
            label: filter.label,
          },
        ];
      }
      return data;
    };
    if (dataFilter && (dataFilter.include.length > 0 || dataFilter.exclude.length > 0)) {
      const mergeDataFilter = [
        ...dataFilter.include.map(i => ({
          type: 'include',
          ...i,
        })),
        ...dataFilter.exclude.map(e => ({
          type: 'exclude',
          ...e,
        })),
      ];
      mergeDataFilter.forEach((filter) => {
        createMain(filter);
      });
      mergeDataFilter.forEach((filter) => {
        criteriaList = criteriaList.map((item) => {
          if (filter.path[0] === item.category) {
            return {
              ...item,
              data: createChildrens(
                {
                  ...filter,
                  path: filter.path.filter((_, index) => index > 0),
                  breadcrumbs: filter.breadcrumbs.filter((_, index) => index > 0),
                },
                item.data,
              ),
              label: filter.label,
            };
          }
          return item;
        });
      });
    }
    const normalizeData = (data) => {
      data.forEach((d) => {
        if (d.data && d.data.length > 0) {
          normalizeData(d.data);
        }
        if (d.type === '') {
          delete d.type;
        }
      });
    };
    normalizeData(criteriaList);
    return criteriaList;
  },
  criteriaToDataFilter: (crits) => {
    const criteriaInclude = [];
    const criteriaExclude = [];

    const mapMain = (
      type = '',
      data = [],
      breadcrumbs = [],
      path = [],
      source = '',
    ) => {
      data.forEach((item) => {
        let newBreadcrumbs = [];
        const newPath = [...path, item.key];
        if (breadcrumbs.length === 0) {
          newBreadcrumbs = [item.key.toUpperCase()];
        } else {
          newBreadcrumbs = [...breadcrumbs];
        }
        // if (item.data && item.data.length > 0) {
        //   newBreadcrumbs = [...newBreadcrumbs, item.value];
        // }
        switch (type) {
          case 'include':
            criteriaInclude.push({
              key: `${item.key}-${item.value}`,
              value: item.value,
              breadcrumbs: newBreadcrumbs,
              path: newPath,
              source,
              label: item.label,
            });
            break;
          case 'exclude':
            criteriaExclude.push({
              key: `${item.key}-${item.value}`,
              value: item.value,
              breadcrumbs: newBreadcrumbs,
              path: newPath,
              source,
              label: item.label,
            });
            break;
          default:
            break;
        }
        if (item.data && item.data.length > 0) {
          mapMain(
            item.type,
            item.data,
            [...newBreadcrumbs, item.value],
            newPath,
            source,
          );
        }
      });
    };

    crits.forEach((item) => {
      mapMain(item.type, item.data, [item.value], [item.category], item.source);
    });
    // adas
    return {
      include: criteriaInclude,
      exclude: criteriaExclude,
    };
  },
};
