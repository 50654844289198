import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Creators as ManualSegmentationCreators } from '~/redux/ducks/manualSegmentation';

import { Container, ContainerTable } from './styles';
// import { DeleteButton } from './Components';
// import { general } from '~/Styles';

function Table({ segmentations }) {
  console.log(segmentations);
  return (
    <Container>
      <ContainerTable>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Agrupamento</th>
            <th>Total Clientes</th>
            <th>Identificador do Usuário</th>
            <th>Cadastro Automático</th>
            <th>Criação</th>
            <th>Autor</th>
            <th>Última Atualização</th>
            {/* <th>Ações</th> */}
          </tr>
        </thead>
        <tbody>
          {segmentations && segmentations.map(segmentation => (
            <tr key={segmentation.uuid}>
              <td><Link className="link" to={`/manual-segmentation/${segmentation.uuid}`}>{segmentation.name}</Link></td>
              <td>{segmentation.subcategory.label || '--'}</td>
              <td>--</td>
              <td>{segmentation.userKey}</td>
              <td>{segmentation.strategy === 'AUTO_REGISTRATION' ? 'Sim' : 'Não'}</td>
              <td>{moment(segmentation.createdAt).format('DD/MM/YYYY HH:mm')}</td>
              <td>{segmentation.user.name || '--'}</td>
              <td>{moment(segmentation.updatedAt).format('DD/MM/YYYY HH:mm')}</td>
              {/* <td>
                <DeleteButton id="delete-button" name="delete-button" className="button-danger" bordered>
                  <img src={general.image['trash-icon-red.png']} alt="trash-icon" className="trash-icon" />
                </DeleteButton>
              </td> */}
            </tr>
          ))}
        </tbody>
      </ContainerTable>
    </Container>
  );
}

const mapStateToProps = ({
  manualSegmentation: {
    segmentations,
  },
}) => ({
  segmentations,
});


Table.propTypes = {
  segmentations: PropTypes.arrayOf(PropTypes.shape()),
};

Table.defaultProps = {
  segmentations: [],
};

export default connect(
  mapStateToProps,
)(Table);
