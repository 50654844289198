/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.label`

  min-width: 80px;
  min-height: 45px;

  border: 2px solid ${props => (props.disabled ? props.theme.colors.light
    : props.checked ? props.theme.colors.primary
      : props.theme.colors.regular)};
  border-radius: 40px;
  background-color: ${props => (props.checked ? '#EBFFEE' : props.theme.colors.white)};
  background-color: ${props => (props.disabled ? props.theme.colors.white
    : props.checked ? '#EBFFEE'
      : props.theme.colors.white)};
  position: relative;

  input{
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  span{
    position: absolute;
    min-width: 38px;
    min-height: 38px;
    top: 2px;
    left: ${props => (props.checked ? '36px' : '2px')};

    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

    border-radius: 38px;

    background-color: ${props => (props.disabled ? props.theme.colors.light
    : props.checked ? props.theme.colors.primary
      : props.theme.colors.regular)};
    transition: .3s;
  }
`;
