import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding:8px ${props => (props.iconAlone ? '11px' : '20px')};
    border-radius: 15px;
    min-height: 46px;
    outline: none;

    filter: drop-shadow( 0 5px 8px  ${props => props.theme.colors.black}30);
    transition: .3s all;

    background-color: ${props => (props.bordered ? 'transparent' : props.theme.colors[props.color ? props.color : 'primary'])};
    border: 2px solid #E8A0A0;
    color: ${props => (props.bordered ? props.theme.colors[props.color ? props.color : 'primary'] : props.theme.colors.white)};


    &&:hover{
      background-color: #FFD8D8;
      border: 2px solid #FFD8D8;
      transition: border .1s, background-color .2s;

      img{
        animation: treme 0.1s;
        animation-iteration-count: 3;

        @keyframes treme {
          0% {rotate: 0deg;}
          25% {rotate: 3deg;}
          50% {rotate: 0deg;}
          75% {rotate: -3deg;}
          100% {rotate: 0deg;}
        }
      }
    }

    img{
        width:22px;
        margin-right: ${props => (props.iconAlone ? '0px' : '10px')};
        margin-top:${props => (props.iconAlone ? '0px' : '-3px')};
    }

    &&:disabled{
      background: #ccc;
    }

`;
