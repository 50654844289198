import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import ReactTooltip from 'react-tooltip';
import { Creators as AudienceExporterCreators } from '~/redux/ducks/audienceExporter';

import ButtonRefreshIcon from './components/ButtonRefreshIcon';

import {
  Container, ModalBox, ContainerTable, BtnGroup,
} from './styles';
import { general } from '~/Styles';

let timer = null;
function ModalExport({
  show,
  changeShowModalExporter,
  generateRetarget,
  downloadRetarget,
  loadRetargets,
  retargets,
  campaignUuid,
  lastUpdate,
}) {
  function refreshRetargets() {
    loadRetargets(campaignUuid);
  }

  useEffect(() => {
    if (show) {
      refreshRetargets();
      timer = setInterval(refreshRetargets, 30000);
    } else if (timer) {
      clearInterval(timer);
      timer = null;
    }
  }, [show]);

  if (!show) {
    return null;
  }

  function handleCloseModalExporter() {
    changeShowModalExporter(false);
  }
  function handleGenerateRetarget({ retargetConfigUuid }) {
    generateRetarget(campaignUuid, retargetConfigUuid);
  }

  function handleDownload({ retargetUuid }) {
    downloadRetarget(campaignUuid, retargetUuid);
  }

  function getStatusIcon(status) {
    switch (status) {
      case 'NOT_RETARGET':
      case 'FAILED':
        return <img data-tip="Não Exportado" src={general.imageCampaign['icon-no-archive.png']} alt="Exportar" />;
      case 'PROCESSING':
        return <img data-tip="Exportando" src={general.imageCampaign['icon-processing-archive.png']} alt="Processando" className="processing" />;
      case 'PROCESSED':
        return <img data-tip="Exportado" src={general.imageCampaign['icon-archive-processed.png']} alt="Arquivo Processado" className="processed-archive" />;
      default: return null;
    }
  }

  function getStatusButton(retarget) {
    switch (retarget.status) {
      case 'NOT_RETARGET':
      case 'FAILED':
        return (
          <button type="button" onClick={() => handleGenerateRetarget(retarget)}>
            <img src={general.imageCampaign['icon-export.png']} alt="ExportarArquivo" />
            Exportar Arquivo
          </button>
        );
      case 'PROCESSING':
        return (
          <span data-tip="Exportando" type="button" className="button-span-em-andamento">
            Em Andamento
          </span>
        );
      case 'PROCESSED':
        return (
          <BtnGroup>
            <button type="button" onClick={() => handleDownload(retarget)}>
              <img src={general.imageCampaign['icon-download-white.png']} alt="Download" className="icon-download" />
              Download
            </button>
            <button data-tip="Exportar Novamente" type="button" onClick={() => handleGenerateRetarget(retarget)}>
              <img src={general.imageCampaign['icon-refresh-archive.png']} alt="Atualizar" className="update" />
            </button>
          </BtnGroup>
        );
      default: return null;
    }
  }

  return (

    <Container>
      <ModalBox>
        <header>
          <h1>
            <img src={general.imageCampaign['icon-title-export-modal.png']} alt="Exportar" className="export-modal-icon" />
            Exportar Audiências
            <button type="button" data-tip="Atualizar status das exportações" onClick={refreshRetargets} className="refresh-modal-button">
              <ButtonRefreshIcon />
            </button>
            <span>{format(new Date(lastUpdate), "'Última atualização:' kk:mm:ss")}</span>
          </h1>
          <button type="button" onClick={handleCloseModalExporter} className="close-button">
            <img src={general.imageCampaign['icon-close-modal.png']} alt="Close Modal" />
          </button>
        </header>
        {retargets.length ? (
          <div className="scroll-table">
            <ContainerTable>
              <thead>
                <tr>
                  <th>Audiência</th>
                  <th>Status</th>
                  <th className="column-exportado-em">Exportado em</th>
                  <th className="column-acoes">Ações</th>
                </tr>
              </thead>

              <tbody>
                {retargets.map(retarget => (
                  <tr key={retarget.retargetConfigUuid}>
                    <td>{retarget.name}</td>
                    <td>
                      {getStatusIcon(retarget.status)}
                    </td>
                    <td className="column-exportado-em">{retarget.timestamp && moment(retarget.timestamp).format('DD/MM/YYYY HH:mm')}</td>
                    <td className="column-acoes">{getStatusButton(retarget)}</td>
                  </tr>
                ))}
              </tbody>
            </ContainerTable>
          </div>
        ) : (
          <div className="box-sem-dados">
            <p>Sem configurações cadastradas.</p>
          </div>
        )}
      </ModalBox>
      <ReactTooltip place="top" effect="solid" />
    </Container>

  );
}

const mapStateToProps = ({
  audienceExporter: {
    show,
    retargets,
    lastUpdate,
  },
}) => ({
  show,
  retargets,
  lastUpdate,
});


const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changeShowModalExporter: AudienceExporterCreators.changeShowModal,
    generateRetarget: AudienceExporterCreators.generateRetarget,
    downloadRetarget: AudienceExporterCreators.downloadRetarget,
    loadRetargets: AudienceExporterCreators.loadRetargets,

  },
  dispatch,
);

ModalExport.propTypes = {
  show: PropTypes.bool.isRequired,
  lastUpdate: PropTypes.shape().isRequired,
  changeShowModalExporter: PropTypes.func.isRequired,
  loadRetargets: PropTypes.func.isRequired,
  generateRetarget: PropTypes.func.isRequired,
  downloadRetarget: PropTypes.func.isRequired,
  retargets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  campaignUuid: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalExport);
