import React from 'react';

import { general } from '~/Styles';
import {
  Button,
} from '..';

import {
  Container,
  Content,
  MiniCardInfoExpanded,
  MiniCardInfo,
  MiniCardAlert,
} from './styles';

function ImportSummary() {
  return (
    <Container>
      <Content>
        <div className="import-summary-title">
          <h3>RESUMO DA IMPORTAÇÃO</h3>
        </div>
        <div className="import-summary-row">
          <div className="import-summary-column-1">
            <div className="content">
              <div>
                <img src={general.image['csv-icon.png']} alt="csv-icon" className="csv-icon" />
                <span className="csv-file-name">xxxxxxxxxx.csv</span>
              </div>
              <div>
                <label>Identificador</label>
                <span>Terminal</span>
              </div>
              <div>
                <label>Estratégia</label>
                <span>Cadastrar automaticamente na base</span>
              </div>
              <div>
                <label>Tamanho</label>
                <span>2.76MB</span>
              </div>
              <div className="button-row">
                <Button
                  id="download-csv"
                  name="download-csv"
                  title="Download"
                  bordered
                >
                  <img src={general.image['download-icon.png']} alt="download-icon" className="download-icon" />
                </Button>
              </div>
            </div>
          </div>
          <div className="vertical-line-1" />
          <div className="import-summary-column-2">
            <div className="content">
              <span className="title">Linhas</span>
              <MiniCardInfoExpanded>
                <div className="summary-mini-card-info-expanded-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfoExpanded>
              <MiniCardInfo>
                <div className="summary-mini-card-info-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfo>
              <MiniCardAlert>
                <div className="summary-mini-card-alert-content">
                  <label>Inválidas</label>
                  <span>50.000</span>
                </div>
              </MiniCardAlert>
            </div>
          </div>
          <div />
          <div className="import-summary-column-3">
            <div className="content">
              <span className="title">Clientes</span>
              <MiniCardInfoExpanded>
                <div className="summary-mini-card-info-expanded-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfoExpanded>
              <MiniCardInfo>
                <div className="summary-mini-card-info-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfo>
              <MiniCardAlert>
                <div className="summary-mini-card-alert-content">
                  <label>Inválidas</label>
                  <span>50.000</span>
                </div>
              </MiniCardAlert>
            </div>
          </div>
          <div />
          <div className="import-summary-column-4">
            <div className="content">
              <MiniCardInfoExpanded>
                <div className="summary-mini-card-info-expanded-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfoExpanded>
              <MiniCardInfo>
                <div className="summary-mini-card-info-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfo>
              <MiniCardAlert>
                <div className="summary-mini-card-alert-content">
                  <label>Inválidas</label>
                  <span>50.000</span>
                </div>
              </MiniCardAlert>
            </div>
          </div>
          <div />
          <div className="import-summary-column-5">
            <div className="content">
              <span className="title">Total</span>
              <MiniCardInfoExpanded>
                <div className="summary-mini-card-info-expanded-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfoExpanded>
              <MiniCardInfo>
                <div className="summary-mini-card-info-content">
                  <label>Válidas</label>
                  <span>650.000</span>
                </div>
              </MiniCardInfo>
              <MiniCardAlert>
                <div className="summary-mini-card-alert-content">
                  <label>Inválidas</label>
                  <span>50.000</span>
                </div>
              </MiniCardAlert>
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
}

export default ImportSummary;
