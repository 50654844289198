import styled from 'styled-components';

export const Container = styled.input`

  padding: 5px 20px;
  width: 100%;

  font-size: 14pt;
  color: ${props => (props.disabled ? props.theme.colors.light : props.theme.colors.dark)};

  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.light};

  background-color: ${props => (props.disabled ? props.theme.colors.lighterWhite : props.theme.colors.white)};

  &::placeholder{
    color: ${props => (props.disabled ? props.theme.colors.light : props.theme.colors.dark)};
  }

  &:focus{
    outline-color: ${props => props.theme.colors.primary};
  }

`;
