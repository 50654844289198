import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import { withTheme } from 'styled-components';
import moment from 'moment';

import { general } from '~/Styles';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import Modal from '~/Components/Modal';
import Icone from '~/Components/Icone';

import {
  // modal
  ContainerWrap,
  ModalHeader,
  ModalBody,
  ModalBodyHistory,
  ModalFooter,
  ButtonClose,
  ButtonFooter,
  // form
  Row,
  FormGroup,
  CalculationTypeSelect,
  // box
  Box,
  BoxHeader,
  BoxContent,
  ButtonGenerateProposal,
  ProposalBox,
  ProposalItem,
  ProposalSelect,
  ProposalInput,
  ProposalInputCurrent,
  LinkHistory,
  LogContainer,
} from './styles';

class ModalEditOrcamento extends Component {
  state = {
    modalHistoryOpen: false,
  };

  componentDidMount() {}

  renderRoiLayout = () => {
    const {
      campaignEditSetOrcamentoToEdit,
      campaignOrcamentoToEdit,
      campaignProposalRequest,
      campaign,
    } = this.props;
    const formInvalid = () => {
      if (!campaignOrcamentoToEdit.form.roi) return true;
      if (
        !campaignOrcamentoToEdit.form.taxRate
        || campaignOrcamentoToEdit.form.taxRate <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.revenueShare
        || campaignOrcamentoToEdit.form.revenueShare <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.profit
        || campaignOrcamentoToEdit.form.profit <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.conversion
        || campaignOrcamentoToEdit.form.conversion <= 0
      ) return true;
      return false;
    };
    return (
      <>
        <Row>
          <Box>
            <BoxHeader>
              <span>Índices de cálculo da proposta</span>
              {campaignOrcamentoToEdit.proposal ? (
                <ButtonGenerateProposal
                  title="ALTERAR ÍNDICES DA PROPOSTA"
                  icon="FaBackspace"
                  bordered
                  disabled={
                    campaignOrcamentoToEdit.proposal
                    && campaignOrcamentoToEdit.proposal.status
                  }
                  onClick={() => {
                    campaignEditSetOrcamentoToEdit({
                      ...campaignOrcamentoToEdit,
                      proposal: null,
                    });
                  }}
                />
              ) : (
                <ButtonGenerateProposal
                  title="GERAR PROPOSTA"
                  icon="FaSyncAlt"
                  bordered
                  disabled={formInvalid()}
                  onClick={() => {
                    campaignProposalRequest(campaign.uuid);
                  }}
                />
              )}
            </BoxHeader>
            <BoxContent>
              <ProposalBox>
                <ProposalItem width={160}>
                  <label>Tempo de retorno:</label>
                  <ProposalSelect
                    placeholder="Tempo de retorno"
                    value={campaignOrcamentoToEdit.roiList.find(
                      item => item.value === campaignOrcamentoToEdit.form.roi,
                    )}
                    onChange={(roi) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          roi: roi.value,
                        },
                      });
                    }}
                    isClarable={false}
                    isDisabled={!!campaignOrcamentoToEdit.proposal}
                    options={campaignOrcamentoToEdit.roiList}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Share(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Share(%)"
                    value={campaignOrcamentoToEdit.form.taxRate}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(taxRate) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          taxRate: parseFloat(taxRate.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Imposto(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Imposto(%)"
                    value={campaignOrcamentoToEdit.form.revenueShare}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(revenueShare) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          revenueShare: parseFloat(revenueShare.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Lucro(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Lucro(%)"
                    value={campaignOrcamentoToEdit.form.profit}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(profit) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          profit: parseFloat(profit.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem width={154}>
                  <label>Conversão média(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Conversão média(%)"
                    value={campaignOrcamentoToEdit.form.conversion}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(conversion) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          conversion: parseFloat(conversion.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
              </ProposalBox>
            </BoxContent>
          </Box>
        </Row>
        {campaignOrcamentoToEdit.proposal ? (
          <Row style={{ margin: 0 }}>
            <Box>
              <BoxHeader>
                <span>Resultado da proposta</span>
              </BoxHeader>
              <BoxContent>
                <ProposalBox>
                  <ProposalItem>
                    <label>Volume proposto:</label>
                    <span>
                      {parseFloat(campaignOrcamentoToEdit.proposal.volume).toLocaleString()}
                    </span>
                  </ProposalItem>
                  <ProposalItem>
                    <label>CPA mínimo:</label>
                    <span>{`R$${parseFloat(campaignOrcamentoToEdit.proposal.cpa).toLocaleString()}`}</span>
                  </ProposalItem>
                  <ProposalItem>
                    <label>Investimento:</label>
                    <span>{`R$${parseFloat(campaignOrcamentoToEdit.proposal.investment).toLocaleString()}`}</span>
                  </ProposalItem>
                </ProposalBox>
              </BoxContent>
            </Box>
          </Row>
        ) : (
          <Row style={{ margin: 0 }}>
            <Box>
              <BoxHeader>
                <span>Resultado da proposta</span>
              </BoxHeader>
              <BoxContent>
                <ProposalBox>
                  <ProposalItem>
                    <label>Sem proposta aprovada</label>
                  </ProposalItem>
                </ProposalBox>
              </BoxContent>
            </Box>
          </Row>
        )}
      </>
    );
  };

  renderCpaLayout = () => {
    const {
      campaignEditSetOrcamentoToEdit,
      campaignOrcamentoToEdit,
      campaignProposalRequest,
      campaign,
    } = this.props;
    const formInvalid = () => {
      if (!campaignOrcamentoToEdit.form.cpa) return true;
      if (
        !campaignOrcamentoToEdit.form.taxRate
        || campaignOrcamentoToEdit.form.taxRate <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.revenueShare
        || campaignOrcamentoToEdit.form.revenueShare <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.profit
        || campaignOrcamentoToEdit.form.profit <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.conversion
        || campaignOrcamentoToEdit.form.conversion <= 0
      ) return true;
      return false;
    };
    const getRoi = (roi) => {
      const findRoi = campaignOrcamentoToEdit.roiList.find(item => item.value === roi);
      return findRoi ? findRoi.label : '';
    };
    return (
      <>
        <Row>
          <Box>
            <BoxHeader>
              <span>Índices de cálculo da proposta</span>
              {campaignOrcamentoToEdit.proposal ? (
                <ButtonGenerateProposal
                  title="ALTERAR ÍNDICES DA PROPOSTA"
                  icon="FaBackspace"
                  bordered
                  onClick={() => {
                    campaignEditSetOrcamentoToEdit({
                      ...campaignOrcamentoToEdit,
                      proposal: null,
                    });
                  }}
                />
              ) : (
                <ButtonGenerateProposal
                  title="GERAR PROPOSTA"
                  icon="FaSyncAlt"
                  bordered
                  disabled={formInvalid()}
                  onClick={() => {
                    campaignProposalRequest(campaign.uuid);
                  }}
                />
              )}
            </BoxHeader>
            <BoxContent>
              <ProposalBox>
                <ProposalItem>
                  <label>CPA desejado:</label>
                  <ProposalInputCurrent
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    placeholder="CPA desejado"
                    precision={2}
                    prefix="R$ "
                    thousandSeparator="."
                    decimalSeparator=","
                    value={parseFloat(campaignOrcamentoToEdit.form.cpa || 0)}
                    onChange={(e, cpa) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          cpa: parseFloat(cpa || 0),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Share(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Share(%)"
                    value={campaignOrcamentoToEdit.form.taxRate}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(taxRate) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          taxRate: parseFloat(taxRate.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Imposto(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Imposto(%)"
                    value={campaignOrcamentoToEdit.form.revenueShare}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(revenueShare) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          revenueShare: parseFloat(revenueShare.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Lucro(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Lucro(%)"
                    value={campaignOrcamentoToEdit.form.profit}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(profit) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          profit: parseFloat(profit.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem width={154}>
                  <label>Conversão média(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Conversão média(%)"
                    value={campaignOrcamentoToEdit.form.conversion}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(conversion) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          conversion: parseFloat(conversion.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
              </ProposalBox>
            </BoxContent>
          </Box>
        </Row>
        {campaignOrcamentoToEdit.proposal ? (
          <Row style={{ margin: 0 }}>
            <Box>
              <BoxHeader>
                <span>Resultado da proposta</span>
              </BoxHeader>
              <BoxContent>
                <ProposalBox>
                  <ProposalItem>
                    <label>Volume proposto:</label>
                    <span>
                      {parseFloat(campaignOrcamentoToEdit.proposal.volume).toLocaleString()}
                    </span>
                  </ProposalItem>
                  <ProposalItem>
                    <label>Investimento:</label>
                    <span>{`R$${parseFloat(campaignOrcamentoToEdit.proposal.investment).toLocaleString()}`}</span>
                  </ProposalItem>
                  <ProposalItem>
                    <label>ROI:</label>
                    <span>{getRoi(campaignOrcamentoToEdit.proposal.roi)}</span>
                  </ProposalItem>
                </ProposalBox>
              </BoxContent>
            </Box>
          </Row>
        ) : (
          <Row style={{ margin: 0 }}>
            <Box>
              <BoxHeader>
                <span>Resultado da proposta</span>
              </BoxHeader>
              <BoxContent>
                <ProposalBox>
                  <ProposalItem>
                    <label>Sem proposta aprovada</label>
                  </ProposalItem>
                </ProposalBox>
              </BoxContent>
            </Box>
          </Row>
        )}
      </>
    );
  };

  renderBudgetLayout = () => {
    const {
      campaignEditSetOrcamentoToEdit,
      campaignOrcamentoToEdit,
      campaignProposalRequest,
      campaign,
    } = this.props;
    const formInvalid = () => {
      if (!campaignOrcamentoToEdit.form.budget) return true;
      if (!campaignOrcamentoToEdit.form.roi) return true;
      if (
        !campaignOrcamentoToEdit.form.taxRate
        || campaignOrcamentoToEdit.form.taxRate <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.revenueShare
        || campaignOrcamentoToEdit.form.revenueShare <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.profit
        || campaignOrcamentoToEdit.form.profit <= 0
      ) return true;
      if (
        !campaignOrcamentoToEdit.form.conversion
        || campaignOrcamentoToEdit.form.conversion <= 0
      ) return true;
      return false;
    };
    return (
      <>
        <Row>
          <Box>
            <BoxHeader>
              <span>Índices de cálculo da proposta</span>
              {campaignOrcamentoToEdit.proposal ? (
                <ButtonGenerateProposal
                  title="ALTERAR ÍNDICES DA PROPOSTA"
                  icon="FaBackspace"
                  bordered
                  onClick={() => {
                    campaignEditSetOrcamentoToEdit({
                      ...campaignOrcamentoToEdit,
                      proposal: null,
                    });
                  }}
                />
              ) : (
                <ButtonGenerateProposal
                  title="GERAR PROPOSTA"
                  icon="FaSyncAlt"
                  bordered
                  disabled={formInvalid()}
                  onClick={() => {
                    campaignProposalRequest(campaign.uuid);
                  }}
                />
              )}
            </BoxHeader>
            <BoxContent>
              <ProposalBox>
                <ProposalItem width={130}>
                  <label>Budget desejado:</label>
                  <ProposalInputCurrent
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    placeholder="Budget desejado"
                    precision={2}
                    prefix="R$ "
                    thousandSeparator="."
                    decimalSeparator=","
                    value={parseFloat(campaignOrcamentoToEdit.form.budget || 0)}
                    onChange={(e, budget) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          budget: parseFloat(budget || 0),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem width={160}>
                  <label>Tempo de retorno:</label>
                  <ProposalSelect
                    placeholder="Tempo de retorno"
                    value={campaignOrcamentoToEdit.roiList.find(
                      item => item.value === campaignOrcamentoToEdit.form.roi,
                    )}
                    onChange={(roi) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          roi: roi.value,
                        },
                      });
                    }}
                    isClarable={false}
                    isDisabled={!!campaignOrcamentoToEdit.proposal}
                    options={campaignOrcamentoToEdit.roiList}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Share(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Share(%)"
                    value={campaignOrcamentoToEdit.form.taxRate}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(taxRate) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          taxRate: parseFloat(taxRate.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Imposto(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Imposto(%)"
                    value={campaignOrcamentoToEdit.form.revenueShare}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(revenueShare) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          revenueShare: parseFloat(revenueShare.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem>
                  <label>Lucro(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Lucro(%)"
                    value={campaignOrcamentoToEdit.form.profit}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(profit) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          profit: parseFloat(profit.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
                <ProposalItem width={154}>
                  <label>Conversão média(%):</label>
                  <ProposalInput
                    type="number"
                    placeholder="Conversão média(%)"
                    value={campaignOrcamentoToEdit.form.conversion}
                    disabled={!!campaignOrcamentoToEdit.proposal}
                    onChange={(conversion) => {
                      campaignEditSetOrcamentoToEdit({
                        ...campaignOrcamentoToEdit,
                        form: {
                          ...campaignOrcamentoToEdit.form,
                          conversion: parseFloat(conversion.target.value),
                        },
                      });
                    }}
                  />
                </ProposalItem>
              </ProposalBox>
            </BoxContent>
          </Box>
        </Row>
        {campaignOrcamentoToEdit.proposal ? (
          <Row style={{ margin: 0 }}>
            <Box>
              <BoxHeader>
                <span>Resultado da proposta</span>
              </BoxHeader>
              <BoxContent>
                <ProposalBox>
                  <ProposalItem>
                    <label>Volume proposto:</label>
                    <span>
                      {parseFloat(campaignOrcamentoToEdit.proposal.volume).toLocaleString()}
                    </span>
                  </ProposalItem>
                  <ProposalItem>
                    <label>CPA mínimo:</label>
                    <span>
                      {`R$${parseFloat(campaignOrcamentoToEdit.proposal.cpa).toLocaleString()}`}
                    </span>
                  </ProposalItem>
                  <ProposalItem>
                    <label>Investimento:</label>
                    <span>
                      {`R$${parseFloat(campaignOrcamentoToEdit.proposal.investment).toLocaleString()}`}
                    </span>
                  </ProposalItem>
                </ProposalBox>
              </BoxContent>
            </Box>
          </Row>
        ) : (
          <Row style={{ margin: 0 }}>
            <Box>
              <BoxHeader>
                <span>Resultado da proposta</span>
              </BoxHeader>
              <BoxContent>
                <ProposalBox>
                  <ProposalItem>
                    <label>Sem proposta aprovada</label>
                  </ProposalItem>
                </ProposalBox>
              </BoxContent>
            </Box>
          </Row>
        )}
      </>
    );
  };

  renderModalHistory = () => {
    const {
      campaignOrcamentoToEdit, theme,
    } = this.props;

    const getRoi = (roi) => {
      const findRoi = campaignOrcamentoToEdit.roiList.find(item => item.value === parseInt(roi));
      return findRoi ? findRoi.label : '';
    };

    const getStatus = (status) => {
      switch (status) {
        case 'ACCEPTED': return 'Aceito';
        default: return status;
      }
    };

    const getRoiResult = item => (
      <Row className="content-log-info">
        <div className="col" style={{ flex: 1 }}>
          <span className="label">Volume proposto</span>
          <span className="desc-bold">
            {parseFloat(item.volume).toLocaleString()}
          </span>
        </div>
        <div className="col" style={{ flex: 1 }}>
          <span className="label">CPA mínimo</span>
          <span className="desc-bold">
            {`R$${parseFloat(item.cpa).toLocaleString()}`}
          </span>
        </div>
        <div className="col" style={{ flex: 1 }}>
          <span className="label">Investimento</span>
          <span className="desc-bold">
            {`R$${parseFloat(item.investment).toLocaleString()}`}
          </span>
        </div>
      </Row>
    );

    const getCpaResult = item => (
      <Row className="content-log-info">
        <div className="col" style={{ flex: 1 }}>
          <span className="label">Volume proposto</span>
          <span className="desc-bold">
            {parseFloat(item.volume).toLocaleString()}
          </span>
        </div>
        <div className="col" style={{ flex: 1 }}>
          <span className="label">Investimento</span>
          <span className="desc-bold">
            {`R$${parseFloat(item.investment).toLocaleString()}`}
          </span>
        </div>
        <div className="col" style={{ flex: 1 }}>
          <span className="label">ROI</span>
          <span className="desc-bold">
            {getRoi(item.roi)}
          </span>
        </div>
      </Row>
    );

    const getBudgetResult = item => (
      <Row className="content-log-info">
        <div className="col" style={{ flex: 1 }}>
          <span className="label">Volume proposto</span>
          <span className="desc-bold">
            {parseFloat(item.volume).toLocaleString()}
          </span>
        </div>
        <div className="col" style={{ flex: 1 }}>
          <span className="label">CPA mínimo</span>
          <span className="desc-bold">
            {`R$${parseFloat(item.cpa).toLocaleString()}`}
          </span>
        </div>
        <div className="col" style={{ flex: 1 }}>
          <span className="label">Investimento</span>
          <span className="desc-bold">
            {`R$${parseFloat(item.investment).toLocaleString()}`}
          </span>
        </div>
      </Row>
    );

    const getLayoutByType = (item) => {
      switch (item.type) {
        case 'ROI': return getRoiResult(item);
        case 'CPA': return getCpaResult(item);
        case 'BUDGET': return getBudgetResult(item);
        default: return null;
      }
    };

    return (
      <Modal width={600} padding={0} scroll={false}>
        <ContainerWrap>
          <ModalHeader>
            <h1>Histórico de propostas</h1>
            <ButtonClose
              type="button"
              onClick={() => {
                this.setState({
                  modalHistoryOpen: false,
                });
              }}
            >
              <FaTimes size={30} />
            </ButtonClose>
          </ModalHeader>
          <ModalBodyHistory>
            {campaignOrcamentoToEdit.history
              && campaignOrcamentoToEdit.history.content
              && campaignOrcamentoToEdit.history.content.map(item => (
                <LogContainer key={item.uuid}>
                  <div className="left">
                    <div className="icon-circle">
                      <Icone
                        icon="check-circle"
                        color={theme.colors.white}
                        size="25"
                      />
                    </div>
                    <div className="line" />
                  </div>
                  <div className="right">
                    <div className="status">
                      <h2>{getStatus(item.status)}</h2>
                    </div>
                    <div className="content-log">
                      <Row className="content-log-info" style={{ marginBottom: 5 }}>
                        <div className="col" style={{ flex: 1 }}>
                          <span className="label">Data</span>
                          <span className="desc-bold">
                            {moment(item.createdAt).format('DD/MM/YYYY')}
                          </span>
                        </div>
                        <div className="col" style={{ flex: 1 }}>
                          <span className="label">Hora</span>
                          <span className="desc-bold">
                            {moment(item.createdAt).format('HH:mm:ss')}
                          </span>
                        </div>
                        <div className="col" style={{ flex: 1 }}>
                          <span className="label">Tipo de cálculo</span>
                          <span className="desc-bold">{item.type}</span>
                        </div>
                      </Row>
                      {getLayoutByType(item)}
                    </div>
                  </div>
                </LogContainer>
              ))
            }
          </ModalBodyHistory>
        </ContainerWrap>
      </Modal>
    );
  };

  render() {
    const {
      campaignEditChangeModalEditStatus,
      campaignEditSetOrcamentoToEdit,
      confirmModal,
      campaignOrcamentoToEdit,
      campaignProposalAccept,
      campaign,
    } = this.props;
    const { modalHistoryOpen } = this.state;

    return campaignOrcamentoToEdit
      && campaignOrcamentoToEdit.form
      && campaignOrcamentoToEdit.form.type && (
      <Modal width={900} padding={0} scroll={false}>
        <ContainerWrap>
          <ModalHeader>
            <h1>Alterar Orçamento</h1>
            <ButtonClose
              type="button"
              onClick={() => {
                campaignEditChangeModalEditStatus({
                  editOrcamentoStatus: false,
                });
              }}
            >
              <FaTimes size={30} />
            </ButtonClose>
          </ModalHeader>
          <ModalBody>
            <Row>
              <FormGroup>
                <label>Tipo de cálculo</label>
                <CalculationTypeSelect
                  placeholder="Tipo de cálculo"
                  value={campaignOrcamentoToEdit.typeList.find(
                    item => item.value === campaignOrcamentoToEdit.form.type,
                  )}
                  onChange={(type) => {
                    campaignEditSetOrcamentoToEdit({
                      ...campaignOrcamentoToEdit,
                      form: {
                        type: type.value,
                        ...campaignOrcamentoToEdit.information,
                      },
                      proposal: null,
                    });
                  }}
                  options={campaignOrcamentoToEdit.typeList}
                  isClearable={false}
                  isDisabled={
                    campaignOrcamentoToEdit.proposal
                    && campaignOrcamentoToEdit.proposal.status
                  }
                />
              </FormGroup>
              <LinkHistory
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    modalHistoryOpen: true,
                  });
                }}
              >
                <img
                  style={{ width: 20, marginRight: 5 }}
                  src={general.image['icon-log.png']}
                  alt="log imagem"
                />
                Visualizar histórico de propostas
              </LinkHistory>
            </Row>
            {campaignOrcamentoToEdit.form.type === 'ROI' && this.renderRoiLayout()}
            {campaignOrcamentoToEdit.form.type === 'CPA' && this.renderCpaLayout()}
            {campaignOrcamentoToEdit.form.type === 'BUDGET' && this.renderBudgetLayout()}
          </ModalBody>
          <ModalFooter>
            <ButtonFooter
              title="CANCELAR"
              bordered
              onClick={() => {
                campaignEditChangeModalEditStatus({
                  editOrcamentoStatus: false,
                });
              }}
            />
            <ButtonFooter
              title="ACEITAR PROPOSTA"
              disabled={
                !campaignOrcamentoToEdit.proposal
                || (campaignOrcamentoToEdit.proposal && campaignOrcamentoToEdit.proposal.status)
              }
              onClick={() => {
                confirmModal(
                  'Aceitar proposta!',
                  'Tem certeza que deseja aceitar esta proposta? Este processo não poderá ser desfeito após confirmado.',
                  () => {
                    campaignProposalAccept(campaign.uuid);
                  },
                );
              }}
            />
          </ModalFooter>
        </ContainerWrap>
        {modalHistoryOpen && this.renderModalHistory()}
      </Modal>
    );
  }
}

ModalEditOrcamento.propTypes = {
  campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
  campaignEditSetOrcamentoToEdit: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  campaignProposalRequest: PropTypes.func.isRequired,
  campaignProposalAccept: PropTypes.func.isRequired,
  campaign: PropTypes.shape().isRequired,
  campaignOrcamentoToEdit: PropTypes.shape().isRequired,
  theme: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  campaignOrcamentoToEdit: state.campaignEdit.campaignOrcamentoToEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    confirmModal: ConfirmModalActions.showConfirmModal,
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditSetOrcamentoToEdit: CampaignEditActions.campaignEditSetOrcamentoToEdit,
    campaignEditSaveOrcamentoInEdit: CampaignEditActions.campaignEditSaveOrcamentoInEdit,
    campaignProposalRequest: CampaignEditActions.campaignProposalRequest,
    campaignProposalAccept: CampaignEditActions.campaignProposalAccept,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(ModalEditOrcamento));
