import queryString from 'query-string';
import moment from 'moment';
import api from './axios';
import CreativeName from '~/Utils/CreativeName';

const campaignEditSaveOrcamento = async ({
  uuid,
  ...body
}) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/budget`,
      { ...body },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditSavePeriodo = async ({
  uuid,
  startDate,
  startHour,
  endDate,
  endHour,
}) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/schedule`,
      {
        startDate: startDate
          ? moment(`${startDate} ${startHour}`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00')
          : null,
        endDate: endDate
          ? moment(`${endDate} ${endHour}`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00')
          : null,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditSavePeriodoEndDate = async ({
  uuid,
  endDate,
  endHour,
}) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/schedule/enddate`,
      {
        endDate: endDate
          ? moment(`${endDate} ${endHour}`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:00')
          : null,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditSaveBriefing = async ({
  uuid,
  templateId,
}) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/briefing`,
      {
        templateId,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditSaveEstrategiaEntrega = async ({
  uuid,
  ...rest
}) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/deliveryStrategy`,
      rest,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditDownloadBriefing = async (uuid) => {
  try {
    const { data } = await api.get(
      `campaign/${uuid}/briefing`,
      { responseType: 'blob' },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditCriativoRequest = async (uuid) => {
  try {
    const { data } = await api.get(`campaign/${uuid}/creative`);
    if (data.creativeDefinition
      && data.creativeDefinition.name === CreativeName.MIDIA_NO_CREDIT_AD) {
      if (data.content.mainData && data.content.mainData.type === 'interno') {
        data.content.mainData.type = 'internal';
      }
    }
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditCriativoSave = async (uuid, creative) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/creative`,
      creative.content,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditBenefitSave = async (uuid, benefitList) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/benefitoffer`,
      benefitList,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignGetBenefitQuiz = async (campangUuid, mediaUuid, questionUuid) => {
  try {
    const { data } = await api.get(`campaign/${campangUuid}/benefitQuiz?questionUuid=${questionUuid}&mediaUuid=${mediaUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignSaveAllBenefitQuiz = async (campangUuid, benefits) => {
  try {
    await api.post(`campaign/${campangUuid}/benefitQuiz`, benefits);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignDeleteAllBenefitQuiz = async (campangUuid, mediaUuid, questionUuid) => {
  try {
    await api.delete(`campaign/${campangUuid}/benefitQuiz`, {
      data: {
        mediaUuid,
        questionUuid,
      },
    });
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditCarrotPackageSave = async (uuid, carrotPackageList) => {
  console.log(carrotPackageList);
  try {
    const { data } = await api.put(
      `campaign/${uuid}/carrot-package`,
      carrotPackageList,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditReportRequest = async (uuid) => {
  try {
    const { data } = await api.get(`mcare-artemis-report-manager/campaign/result/${uuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditAudienceRequest = async (filter) => {
  try {
    const { data } = await api.get(`mcare-artemis-audience-service/audience?${queryString.stringify(filter)}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditNoCreditPreviewRequest = async (uuid) => {
  try {
    const { data } = await api.get(`campaign/${uuid}/get-preview`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditNoDataPreviewRequest = async (uuid) => {
  try {
    const { data } = await api.get(`campaign/${uuid}/get-preview`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditSaveImpactSettings = async (uuid, impact) => {
  try {
    const { data } = await api.put(
      `campaign/${uuid}/audience`,
      {
        volume: impact.volume,
        audienceUuid: impact.audienceUuid,
        estimatedAudience: impact.estimatedAudience,
        cpm: impact.cpm,
        purgeAudience: impact.purgeAudience ? impact.purgeAudience : null,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignEditLoadMediaEventList = async () => {
  try {
    const { data } = await api.get(
      'mediaevent?mediaType=campaign',
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignProposalRequest = async (uuid, body) => {
  try {
    const { data } = await api.post(
      `campaign/${uuid}/campaign-proposals`,
      body,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignProposalAccept = async (campaignProposalUuid) => {
  try {
    const { data } = await api.post(
      `campaign-proposals/${campaignProposalUuid}/accept`,
      {},
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignProposalInformation = async (uuid) => {
  try {
    const { data } = await api.get(`campaign/${uuid}/campaign-proposals/information`);
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignProposalHistory = async (uuid) => {
  try {
    const { data } = await api.get(`campaign/${uuid}/campaign-proposals/history`);
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignProposalGet = async (uuid) => {
  try {
    const { data } = await api.get(`campaign/${uuid}/campaign-proposals`);
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  campaignEditSaveOrcamento,
  campaignEditSavePeriodo,
  campaignEditSavePeriodoEndDate,
  campaignEditSaveBriefing,
  campaignEditDownloadBriefing,
  campaignEditCriativoRequest,
  campaignEditCriativoSave,
  campaignEditBenefitSave,
  campaignEditCarrotPackageSave,
  campaignGetBenefitQuiz,
  campaignSaveAllBenefitQuiz,
  campaignDeleteAllBenefitQuiz,
  campaignEditReportRequest,
  campaignEditAudienceRequest,
  campaignEditSaveImpactSettings,
  campaignEditSaveEstrategiaEntrega,
  campaignEditNoCreditPreviewRequest,
  campaignEditNoDataPreviewRequest,
  campaignEditLoadMediaEventList,
  campaignProposalRequest,
  campaignProposalAccept,
  campaignProposalInformation,
  campaignProposalHistory,
  campaignProposalGet,
};
