import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dropzone from 'react-dropzone';

import { Creators as ManualSegmentationCreators } from '~/redux/ducks/manualSegmentation';

import {
  Container, ModalBox,
} from './styles';
import { general } from '~/Styles';
import {
  Input, Select, Toggle, Button,
} from './Components';
import {
  CreatableSelect,
} from '../../../Components';


function ModalNewManualSegmentation({
  show,
  closeModalNewManualSegmentation,
  configTemplates,
  subcategories,
  createSegmentation,
}) {
  if (!show) {
    return null;
  }
  const [name, setName] = useState('');
  const [subcategory, setSelectedSubcategory] = useState(null);
  const [userIdentificator, setUserIdentificator] = useState('');
  const [importStrategy, setImportStrategy] = useState('');
  const [audienceName, setAudienceName] = useState('');
  const [contractAccessThisAudience, setContractAccessThisAudience] = useState('');
  const [automaticCreation, setAutomaticCreation] = useState(true);
  const [shareAudienceAllContracts, setShareAudienceAllContracts] = useState(false);
  const [originSegmentationFile, setOriginSegmentationFile] = useState({});

  function subcategoriesToOptions(subcategoriesData) {
    const list = subcategoriesData.map((subcateg) => {
      const item = {
        value: subcateg.key,
        label: subcateg.label,
      };
      return item;
    });
    return list;
  }

  function handleChangeSelectedGrouping(field, value) {
    switch (field) {
      case 'subcategories':
        setSelectedSubcategory(value);
        break;

      default:
        break;
    }
  }

  function onCreateNewSegmentation() {
    const newSegmentation = {
      name: 'Testeteste',
      fileSize: 60000,
      strategy: 'AUTO_REGISTRATION',
      userKey: 'terminal',
      subCategory: {
        key: 'Beleza',
        label: 'beleza',
      },
      audience: {
        autoCreation: false,
        name: 'Testeteste',
        shared: true,
        description: 'Audiencia Teste',
        criteria: [
          {
            category: 'manual_segmentation',
            value: 'Segmentação Manual',
            type: 'include',
            data: [
              {
                key: 'games',
                value: 'corrida',
              },
            ],
          },
        ],
        geolocations: [],
        preferences: {},
        countMap: {},
        contract: {
          uuid: '0bc6094e-eb56-4b57-acfe-2f89a1259b5b',
        },
      },
    };
    createSegmentation(newSegmentation, originSegmentationFile);
  }

  return (
    <Container>
      <ModalBox>
        <header>
          <h2>
            NOVA SEGMENTAÇÃO MANUAL
          </h2>
          <button type="button" onClick={closeModalNewManualSegmentation} className="close-button">
            <img src={general.imageCampaign['icon-close-modal.png']} alt="Exportar" />
          </button>
        </header>
        <div className="modal-body">
          <div className="identification">
            <div className="identification-row">
              <h3>
                IDENTIFICACÃO
              </h3>
              <div className="block">
                <label htmlFor="name">Nome</label>
                <Input
                  id="name"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder="Nome da segmentação..."
                />
              </div>
              <div className="block">
                <label htmlFor="grouping">Agrupamento</label>
                <CreatableSelect
                  id="grouping"
                  name="grouping"
                  placeholder="Selecione ou crie..."
                  onChange={value => handleChangeSelectedGrouping('subcategories', value)}
                  options={subcategoriesToOptions(subcategories)}
                  value={subcategory}
                  className="react-select-container"
                  isClearable
                />
              </div>
            </div>
          </div>
          <div className="config">
            <div className="config-row">
              <h3>
                CONFIGURAÇÃO
              </h3>
              <div className="block">
                <span className="block-title" htmlFor="user-identificator">Identificador do Usuário</span>
                <Select
                  id="user-identificator"
                  name="user-identificator"
                  className="user-identificator"
                  value={userIdentificator}
                  onChange={e => setUserIdentificator(e.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  {configTemplates
                  && configTemplates.userKeys
                  && configTemplates.userKeys.map(template => (
                    <option key={template} value={template}>{template}</option>
                  ))}
                </Select>
                {userIdentificator && (
                  <div className="config-template-download">
                    <a
                      href={
                        configTemplates
                        && configTemplates.templates
                        && configTemplates.templates[0].link
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={general.image['csv-icon.png']} alt="csv-icon" className="csv-icon" />
                      Download
                    </a>
                  </div>
                )}
                {!userIdentificator && (
                  <div className="block-text">
                    <p>
                      Identifique qual campo será utilizado como chave durante
                      {' '}
                      o processo de segmentação.
                    </p>
                    <p>
                      Você deve publicar um arquivo CSV com apenas uma coluna,
                      {' '}
                      tendo em cada linha um usuário identificado pelo atributo selecionado aqui.
                    </p>
                    <p>
                      O sistema validará cada linha para garantir que a mesma
                      {' '}
                      está no formato selecionado
                    </p>
                  </div>
                )}
              </div>
              <div className="block">
                <span className="block-title" htmlFor="import-strategy">Estratégia de Importação</span>
                <Select
                  id="import-strategy"
                  name="import-strategy"
                  value={importStrategy}
                  onChange={e => setImportStrategy(e.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">Atualizar apenas base de dados existente</option>
                  <option value="AUTO_REGISTRATION">Cadastrar automaticamente na base</option>
                </Select>
                <div className="block-text">
                  {!importStrategy && (
                    <p>
                      Identifique aqui se deseja que apenas os clientes já cadastrados
                      {' '}
                      sejam atualizados com este novo atributo nas bases de dados existentes
                      {' '}
                      ou se deseja também incluir de forma automática, clientes que ainda
                      {' '}
                      não constem na base de dados existente.
                    </p>
                  )}
                  {importStrategy === '1' && (
                    <>
                      <p className="text-warning-bordered">
                        <img src={general.image['alert-icon.png']} alt="Alert" />
                      </p>
                      <p className="text-warning">
                        Apenas clientes já cadastrados previamente na base de dados
                        {' '}
                        da plataforma serão marcados com este atributo.
                      </p>
                    </>
                  )}
                  {importStrategy === 'AUTO_REGISTRATION' && (
                    <>
                      <p className="text-warning-bordered">
                        <img src={general.image['alert-icon.png']} alt="Alert" />
                      </p>
                      <p className="text-warning">
                        Clientes existentes no arquivo que está sendo publicado
                        {' '}
                        e que ainda não existam na base de clientes da plataforma
                        {' '}
                        serão cadastrados automaticamente.
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="block">
                <span className="block-title">Criação automática de Audiência</span>
                <div className="toggle-row">
                  <Toggle
                    id="automatic-creation"
                    name="automatic-creation"
                    checked={automaticCreation}
                    onClick={() => setAutomaticCreation(prevState => !prevState)}
                  />
                  {automaticCreation
                    ? <label>Criação automática ativada</label>
                    : <label className="inactive">Criação automática desativada</label>
                   }
                </div>
                <label htmlFor="audience-name">Nome da audiência</label>
                <Input
                  id="audience-name"
                  name="audience-name"
                  value={audienceName || name}
                  onChange={e => setAudienceName(e.target.value)}
                  placeholder="Nome da audiência..."
                />
                <div className="toggle-row">
                  <Toggle
                    id="share-audience-all-contracts"
                    name="share-audience-all-contracts"
                    checked={shareAudienceAllContracts}
                    onClick={() => setShareAudienceAllContracts(prevState => !prevState)}
                  />
                  <label className={!shareAudienceAllContracts ? 'inactive' : ''}>Publicar audiência para todos os contratos</label>
                </div>
                <label htmlFor="contract-access-this-audience">Contrato com acesso a esta audiência</label>
                <Select
                  id="contract-access-this-audience"
                  name="contract-access-this-audience"
                  value={contractAccessThisAudience}
                  onChange={e => setContractAccessThisAudience(e.target.value)}
                  disabled={shareAudienceAllContracts}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Select>
              </div>
            </div>
          </div>
          <div className="segmentation-origin">
            <div className="row">
              <Dropzone
                accept=".csv"
                multiple={false}
                onDrop={(files) => {
                  setOriginSegmentationFile({
                    ...originSegmentationFile,
                    file: files[0],
                  });
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={{ outline: 'none' }}>
                      <h3>
                        ORIGEM DA SEGMENTAÇÃO
                      </h3>
                      <div className="dropzone">
                        <input {...getInputProps()} />
                        {
                          originSegmentationFile.file
                          && <p>{originSegmentationFile.file.name}</p>
                        }
                        <p>
                          {!originSegmentationFile.file
                            ? 'Clique aqui ou arraste o CSV'
                            : 'Clique aqui ou arraste para alterar'}
                        </p>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
        </div>
        <div className="modal-new-manual-segmentation-footer">
          <Button
            id="cancel-new-manual-segmentation"
            name="cancel-new-manual-segmentation"
            title="Cancelar"
            onClick={closeModalNewManualSegmentation}
            bordered
          />
          <Button
            id="save-new-manual-segmentation"
            name="save-new-manual-segmentation"
            title="Salvar"
            onClick={onCreateNewSegmentation}
          >
            <img src={general.image['save-icon.png']} alt="save-new-segmentation-icon" className="save-new-segmentation-icon" />
          </Button>
        </div>
      </ModalBox>
    </Container>
  );
}

const mapStateToProps = ({
  manualSegmentation: {
    show,
    configTemplates,
    subcategories,
  },
}) => ({
  show,
  configTemplates,
  subcategories,
});


const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closeModalNewManualSegmentation: ManualSegmentationCreators.closeModalNewManualSegmentation,
    createSegmentation: ManualSegmentationCreators.createSegmentation,
  },
  dispatch,
);

ModalNewManualSegmentation.defaultProps = {
  configTemplates: null,
  subcategories: null,
};

ModalNewManualSegmentation.propTypes = {
  show: PropTypes.bool.isRequired,
  configTemplates: PropTypes.objectOf(PropTypes.array),
  subcategories: PropTypes.arrayOf(PropTypes.shape()),
  closeModalNewManualSegmentation: PropTypes.func.isRequired,
  createSegmentation: PropTypes.func.isRequired,
  filterLists: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalNewManualSegmentation);
