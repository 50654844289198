/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Favicon from 'react-favicon';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push as pushRouter } from 'connected-react-router';

import { withTheme } from 'styled-components';

import { Header, Side } from './styles';

import Icone from '~/Components/Icone';

class NavbarComponent extends Component {
  state = {
    pages: [],
  };

  componentDidMount() {
    const {
      location: { pathname },
      currentUser: { modules },
    } = this.props;
    if (pathname) {
      const pages = [...this.makePages(modules)];

      pages.push({
        id: 3,
        route: '/manual-segmentation',
        name: 'Segmentação Manual',
        active: false,
        icon: 'chart-line',
      });

      const pageMap = pages.map(
        page => (pathname === page.route
          ? {
            ...page,
            active: true,
          }
          : {
            ...page,
            active: false,
          }),
      );

      this.setState({
        pages: [...pageMap],
      });
    }
  }

  makePages = (modules) => {
    const result = modules.map((m) => {
      if (m.type === 'EXTERNAL') {
        return {
          id: m.uuid,
          urlExternal: m.url,
          name: m.label,
          active: false,
          icon: m.icon,
        };
      }
      if (m.type === 'INTERNAL') {
        return {
          id: m.uuid,
          route: m.url,
          name: m.label,
          active: false,
          icon: m.icon,
        };
      }
      return null;
    });

    return result;
  };

  renderSideList = (item) => {
    const { push, theme } = this.props;
    const { pages } = this.state;
    return (
      <div
        className="box-link"
        key={item.id}
        onClick={() => {
          if (item.route) {
            push(item.route);
            this.setState({
              pages: [
                ...pages.map(page => (item.route === page.route
                  ? {
                    ...page,
                    active: true,
                  }
                  : {
                    ...page,
                    active: false,
                  })),
              ],
            });
          } else if (item.urlExternal) {
            window.location = item.urlExternal;
          }
        }}
      >
        <Icone
          icon={item.icon}
          color={item.active ? theme.colors.primary : theme.colors.regular}
          size="20"
        />
        <label>{item.name}</label>
      </div>
    );
  };

  render() {
    const { pages } = this.state;
    const { currentUser, push, credentials } = this.props;
    return (
      <>
        <Header>
          <Favicon
            url={`https://auth0.apolowifi.com/${credentials.client_id}-favicon.ico`}
          />
          <img
            alt="Logo"
            className="logo"
            src={`https://auth0.apolowifi.com/${credentials.client_id}-logo.png`}
          />
          <div className="right">
            <div className="user-box">
              <div className="user-data">
                <h1>{currentUser.name}</h1>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    push('/logout');
                  }}
                >
                  Sair
                </a>
              </div>
              {currentUser.picture && (
                <img
                  alt="User"
                  className="icon-user"
                  src={currentUser.picture}
                />
              )}
            </div>
          </div>
        </Header>
        <Side>{pages.map(item => this.renderSideList(item))}</Side>
      </>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location,
  credentials: state.credentials.data,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    push: pushRouter,
  },
  dispatch,
);

NavbarComponent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  credentials: PropTypes.shape({
    client_id: PropTypes.string,
  }).isRequired,
  currentUser: PropTypes.shape({
    name: PropTypes.string.isRequired,
    picture: PropTypes.string,
    modules: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  push: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(NavbarComponent));
