import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { Creators as ManualSegmentationCreators } from '~/redux/ducks/manualSegmentation';

import { general } from '~/Styles';
import Breadcrumb from '~/Components/Breadcrumb';

import {
  Input,
  Select,
  Button,
  DeleteButton,
  ImportSummary,
} from './Components';

import {
  CreatableSelect,
} from '../Components';

import {
  Wrap,
  Container,
} from './styles';

function ViewSegmentation({
  match: { params },
  getSegmentationData,
  segmentation,
  subcategories,
}) {
  const { segmentationUuid } = params;

  const [name, setName] = useState('');
  const [subcategory, setSelectedSubcategory] = useState(null);
  const [zone, setZone] = useState('');

  useEffect(() => {
    getSegmentationData(segmentationUuid);
  }, []);

  useEffect(() => {
    if (segmentation) {
      setName(segmentation.name);
    }
  }, [segmentation]);
  function subcategoriesToOptions(subcategoriesData) {
    const list = subcategoriesData.map((subcateg) => {
      const item = {
        value: subcateg.key,
        label: subcateg.label,
      };
      return item;
    });
    return list;
  }

  function handleChangeSelectedGrouping(field, value) {
    switch (field) {
      case 'groupings':
        setSelectedSubcategory(value);
        break;

      default:
        break;
    }
  }
  return (
    <Wrap>
      <Breadcrumb
        items={[
          {
            route: '/',
            name: 'Home',
          },
          {
            route: '/manual-segmentation',
            name: 'Segmentação Manual',
          },
          {
            name: 'Detalhamento',
          },
        ]}
      />

      <Container>
        <div className="segmentation-header">
          <h1>{segmentation && segmentation.name}</h1>
          <div className="header-buttons">
            <Button
              id="back-list-manual-segmentation"
              name="back-list-manual-segmentation"
              title={<Link to="/manual-segmentation">Voltar</Link>}
              bordered
            />
            <Button
              id="save-new-manual-segmentation"
              name="save-new-manual-segmentation"
              title="Salvar"
            >
              <img src={general.image['save-icon.png']} alt="save-new-segmentation-icon" className="save-new-segmentation-icon" />
            </Button>
            <DeleteButton className="button-danger" bordered>
              <img src={general.image['trash-icon-red.png']} alt="trash-icon" className="trash-icon" />
            </DeleteButton>
          </div>
        </div>
        <div className="identification">
          <div className="identification-row">
            <h3>
              IDENTIFICACÃO
            </h3>
            <div className="block">
              <label htmlFor="name">Nome</label>
              <Input
                id="name"
                name="name"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder="Nome da segmentação..."
              />
            </div>
            <div className="block">
              <label htmlFor="grouping">Agrupamento</label>
              <CreatableSelect
                id="grouping"
                name="grouping"
                placeholder="Selecione ou crie..."
                onChange={value => handleChangeSelectedGrouping('groupings', value)}
                options={subcategoriesToOptions(subcategories)}
                value={subcategory}
                className="react-select-container"
                isClearable
              />
            </div>
          </div>
        </div>

        <ImportSummary />

        <div className="row-with-multiple-blocks">
          <div className="automatically-generated-audience-block">
            <div className="block-content">
              <h3>Audiência Gerada Automaticamente</h3>
              <div>
                <label>Nome</label>
                <span>Audiência Gerada Autom 01</span>
              </div>
              <div>
                <label>Permissão de Acesso:</label>
                <span>Global</span>
              </div>
            </div>
          </div>
          <div className="actual-impact-block">
            <div className="block-content">
              <h3>Análise de Impacto Atual</h3>
              <div>
                <label className="select-zone">Zona</label>
                <Select
                  id="zone"
                  name="zone"
                  value={zone}
                  onChange={e => setZone(e.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Select>
              </div>
              <div>
                <label>Impacto Estimado</label>
                <h1 className="estimated-impact">200.000</h1>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Wrap>
  );
}

const mapStateToProps = ({
  manualSegmentation: {
    segmentation,
    subcategories,
  },
}) => ({
  segmentation,
  subcategories,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getSegmentationData: ManualSegmentationCreators.getSegmentationData,
  },
  dispatch,
);

ViewSegmentation.defaultProps = {
  segmentation: null,
  subcategories: null,
};

ViewSegmentation.propTypes = {
  segmentation: PropTypes.shape(),
  getSegmentationData: PropTypes.func.isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.shape()),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(ViewSegmentation));
