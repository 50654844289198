import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  gridPeriodSetup: null,
  gridPeriodSetupSuccess: ['periods', 'periodSelected', 'zones'],
  changePeriodSelected: ['periodSelected'],
  changePeriodSelectedSuccess: ['periodSelected', 'zones'],
  openModalInitCloseWeekly: null,
  openModalInitCloseWeeklySuccess: ['geral', 'auction', 'probe', 'fixedCpa', 'bonus'],
  closeModalInitCloseWeekly: null,
  updateConfigsGeral: ['geral'],
  updateConfigsAuction: ['auction'],
  updateConfigsProbe: ['probe'],
  updateConfigsFixedCpa: ['fixedCpa'],
  updateConfigsBonus: ['bonus'],
  confirmStartGridPeriod: null,
  confirmCloseGridPeriod: null,
  openModalChannel: ['zone'],
  openModalChannelSuccess: ['zone', 'channel', 'fieldConfigurations', 'config', 'disparosSatpush'],
  closeModalChannel: null,
  closeModalChannelSuccess: null,
  changeStatusAuction: ['status'],
  changeStatusAuctionSuccess: ['status'],
  changeStatusProbe: ['status'],
  changeStatusProbeSuccess: ['status'],
  changeStatusFixedCpa: ['status'],
  changeStatusFixedCpaSuccess: ['status'],
  changeStatusBonus: ['status'],
  changeStatusBonusSuccess: ['status'],
  updateItemAuction: ['item', 'save'],
  updateItemProbe: ['item', 'save'],
  updateItemFixedCpa: ['item', 'save'],
  updateItemBonus: ['item', 'save'],

  recalculateRemainingImpressions: null,
  recalculateRemainingImpressionsSuccess: ['remainingImpressions'],

  removeCampaignProbe: ['item'],
  removeCampaignProbeSuccess: ['item'],

  removeCampaignFixedCpa: ['item'],
  removeCampaignFixedCpaSuccess: ['item'],

  removeCampaignBonus: ['item'],
  removeCampaignBonusSuccess: ['item'],

  gridPeriodSetFilterModalCampaign: ['campaignListFilter'],
  gridPeriodCampaignByFilterRequest: null,
  gridPeriodCampaignByFilterRequestSuccess: ['campaignList'],
  gridPeriodChangeModalStatus: ['modalStatus'],
  gridPeriodSetCampaignToAdd: ['campaignListUuid'],

  gridPeriodProductRequestSuccess: ['productList'],
  gridPeriodContractRequestSuccess: ['contractList'],

  gridPeriodInsertCampaignsSelected: null,
  gridPeriodResetFilterModalCampaign: null,
  updatePositionInBatch: ['gridManagerGridItemUpdatePosList']
});

export const NewGridPeriodTypes = Types;
export default Creators;

const INITIAL_MODALS_CAMPAIGN_FILTER = {
  page: null,
  type: null,
  typeName: null,
};

const INITIAL_MODALS_STATUS = {
  modalBonificadas: false,
  modalCpaFixo: false,
  modalTeste: false,
};

/* Initial State */
const INITIAL_STATE = Immutable({
  showModalInitCloseWeekly: false,
  showModalChannel: false,
  periods: [],
  periodSelected: null,
  zones: [],
  configs: {
    auction: null,
    probe: null,
    fixedCpa: null,
  },
  channelEdit: {
    zone: null,
    channel: null,
    fieldConfigurations: [],
    config: null,
  },
  productList: [],
  contractList: [],
  campaignList: null,
  campaignListFilter: INITIAL_MODALS_CAMPAIGN_FILTER,
  campaignToAdd: [],
  modal: INITIAL_MODALS_STATUS,
});

/* Reducers */
const openModalInitCloseWeeklySuccess = (state, {
  geral,
  auction,
  probe,
  fixedCpa,
  bonus,
}) => ({
  ...state,
  showModalInitCloseWeekly: true,
  configs: {
    geral,
    auction,
    probe,
    fixedCpa,
    bonus,
  },
});

const updateConfigsGeral = (state, {
  geral,
}) => ({
  ...state,
  configs: {
    ...state.configs,
    geral,
  },
});

const updateConfigsAuction = (state, {
  auction,
}) => ({
  ...state,
  configs: {
    ...state.configs,
    auction,
  },
});

const updateConfigsProbe = (state, {
  probe,
}) => ({
  ...state,
  configs: {
    ...state.configs,
    probe,
  },
});

const updateConfigsFixedCpa = (state, {
  fixedCpa,
}) => ({
  ...state,
  configs: {
    ...state.configs,
    fixedCpa,
  },
});

const updateConfigsBonus = (state, {
  bonus,
}) => ({
  ...state,
  configs: {
    ...state.configs,
    bonus,
  },
});

const closeModalInitCloseWeekly = state => ({
  ...state,
  showModalInitCloseWeekly: false,
});

const openModalChannelSuccess = (state, {
  zone, channel, fieldConfigurations, config, disparosSatpush,
}) => ({
  ...state,
  showModalChannel: true,
  channelEdit: {
    zone,
    channel,
    fieldConfigurations,
    config,
    disparosSatpush,
  },
});

const recalculateRemainingImpressionsSuccess = (state, { remainingImpressions }) => ({
  ...state,
  showModalChannel: true,
  channelEdit: {
    ...state.channelEdit,
    disparosSatpush: {
      ...state.channelEdit.disparosSatpush,
      remainingImpressions,
    },
  },
});

const updateItemAuction = (state, { item }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      auction: {
        ...state.channelEdit.channel.auction,
        items: state.channelEdit.channel.auction.items.map(a => (a.uuid === item.uuid ? item : a)),
      },
    },
  },
});


const changeStatusAuctionSuccess = (state, { status }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      auction: {
        ...state.channelEdit.channel.auction,
        status,
      },
    },
  },
});

const updateItemProbe = (state, { item }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      probe: {
        ...state.channelEdit.channel.probe,
        items: state.channelEdit.channel.probe.items.map(a => (a.uuid === item.uuid ? item : a)),
      },
    },
  },
});

const removeCampaignProbeSuccess = (state, { item }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      probe: {
        ...state.channelEdit.channel.probe,
        items: state.channelEdit.channel.probe.items.filter(a => a.uuid !== item.uuid),
      },
    },
  },
});

const changeStatusProbeSuccess = (state, { status }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      probe: {
        ...state.channelEdit.channel.probe,
        status,
      },
    },
  },
});

const updateItemFixedCpa = (state, { item }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      fixedCpa: {
        ...state.channelEdit.channel.fixedCpa,
        items: state.channelEdit.channel.fixedCpa.items.map(a => (a.uuid === item.uuid ? item : a)),
      },
    },
  },
});

const removeCampaignFixedCpaSuccess = (state, { item }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      fixedCpa: {
        ...state.channelEdit.channel.fixedCpa,
        items: state.channelEdit.channel.fixedCpa.items.filter(a => a.uuid !== item.uuid),
      },
    },
  },
});


const changeStatusFixedCpaSuccess = (state, { status }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      fixedCpa: {
        ...state.channelEdit.channel.fixedCpa,
        status,
      },
    },
  },
});

const updateItemBonus = (state, { item }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      bonus: {
        ...state.channelEdit.channel.bonus,
        items: state.channelEdit.channel.bonus.items.map(a => (a.uuid === item.uuid ? item : a)),
      },
    },
  },
});

const removeCampaignBonusSuccess = (state, { item }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      bonus: {
        ...state.channelEdit.channel.bonus,
        items: state.channelEdit.channel.bonus.items.filter(a => a.uuid !== item.uuid),
      },
    },
  },
});


const changeStatusBonusSuccess = (state, { status }) => ({
  ...state,
  channelEdit: {
    ...state.channelEdit,
    channel: {
      ...state.channelEdit.channel,
      bonus: {
        ...state.channelEdit.channel.bonus,
        status,
      },
    },
  },
});

const closeModalChannelSuccess = state => ({
  ...state,
  showModalChannel: false,
});

const gridPeriodSetupSuccess = (state, { periods, periodSelected, zones }) => ({
  ...state,
  periods,
  periodSelected,
  zones,
});

const changePeriodSelectedSuccess = (state, { periodSelected, zones }) => ({
  ...state,
  periodSelected,
  zones,
});

const gridPeriodSetFilterModalCampaign = (state, { campaignListFilter }) => ({
  ...state,
  campaignListFilter: {
    ...state.campaignListFilter,
    ...campaignListFilter,
  },
});

const gridPeriodCampaignByFilterRequestSuccess = (state, { campaignList }) => ({
  ...state,
  campaignList,
});

const gridPeriodChangeModalStatus = (state, { modalStatus }) => ({
  ...state,
  modal: {
    ...state.modal,
    ...modalStatus,
  },
});

const gridPeriodSetCampaignToAdd = (state, { campaignListUuid }) => ({
  ...state,
  campaignToAdd: campaignListUuid,
});

const gridPeriodProductRequestSuccess = (state, { productList }) => ({
  ...state,
  productList,
});
const gridPeriodContractRequestSuccess = (state, { contractList }) => ({
  ...state,
  contractList,
});

const gridPeriodResetFilterModalCampaign = state => ({
  ...state,
  campaignListFilter: null,
});


export const reducer = createReducer(INITIAL_STATE, {
  [NewGridPeriodTypes.OPEN_MODAL_INIT_CLOSE_WEEKLY_SUCCESS]: openModalInitCloseWeeklySuccess,
  [NewGridPeriodTypes.CLOSE_MODAL_INIT_CLOSE_WEEKLY]: closeModalInitCloseWeekly,
  [NewGridPeriodTypes.OPEN_MODAL_CHANNEL_SUCCESS]: openModalChannelSuccess,
  [NewGridPeriodTypes.CLOSE_MODAL_CHANNEL_SUCCESS]: closeModalChannelSuccess,
  [NewGridPeriodTypes.GRID_PERIOD_SETUP_SUCCESS]: gridPeriodSetupSuccess,
  [NewGridPeriodTypes.CHANGE_PERIOD_SELECTED_SUCCESS]: changePeriodSelectedSuccess,
  [NewGridPeriodTypes.UPDATE_CONFIGS_GERAL]: updateConfigsGeral,
  [NewGridPeriodTypes.UPDATE_CONFIGS_AUCTION]: updateConfigsAuction,
  [NewGridPeriodTypes.UPDATE_CONFIGS_PROBE]: updateConfigsProbe,
  [NewGridPeriodTypes.UPDATE_CONFIGS_FIXED_CPA]: updateConfigsFixedCpa,
  [NewGridPeriodTypes.UPDATE_CONFIGS_BONUS]: updateConfigsBonus,
  [NewGridPeriodTypes.CHANGE_STATUS_AUCTION_SUCCESS]: changeStatusAuctionSuccess,
  [NewGridPeriodTypes.CHANGE_STATUS_PROBE_SUCCESS]: changeStatusProbeSuccess,
  [NewGridPeriodTypes.CHANGE_STATUS_FIXED_CPA_SUCCESS]: changeStatusFixedCpaSuccess,
  [NewGridPeriodTypes.CHANGE_STATUS_BONUS_SUCCESS]: changeStatusBonusSuccess,
  [NewGridPeriodTypes.UPDATE_ITEM_AUCTION]: updateItemAuction,
  [NewGridPeriodTypes.UPDATE_ITEM_PROBE]: updateItemProbe,
  [NewGridPeriodTypes.UPDATE_ITEM_FIXED_CPA]: updateItemFixedCpa,
  [NewGridPeriodTypes.UPDATE_ITEM_BONUS]: updateItemBonus,
  [NewGridPeriodTypes.RECALCULATE_REMAINING_IMPRESSIONS_SUCCESS]: recalculateRemainingImpressionsSuccess,

  [NewGridPeriodTypes.GRID_PERIOD_SET_FILTER_MODAL_CAMPAIGN]:
    gridPeriodSetFilterModalCampaign,
  [NewGridPeriodTypes.GRID_PERIOD_CAMPAIGN_BY_FILTER_REQUEST_SUCCESS]:
    gridPeriodCampaignByFilterRequestSuccess,
  [NewGridPeriodTypes.GRID_PERIOD_CHANGE_MODAL_STATUS]: gridPeriodChangeModalStatus,
  [NewGridPeriodTypes.GRID_PERIOD_SET_CAMPAIGN_TO_ADD]: gridPeriodSetCampaignToAdd,
  [NewGridPeriodTypes.GRID_PERIOD_PRODUCT_REQUEST_SUCCESS]: gridPeriodProductRequestSuccess,
  [NewGridPeriodTypes.GRID_PERIOD_CONTRACT_REQUEST_SUCCESS]: gridPeriodContractRequestSuccess,
  [NewGridPeriodTypes.GRID_PERIOD_RESET_FILTER_MODAL_CAMPAIGN]:
    gridPeriodResetFilterModalCampaign,
  [NewGridPeriodTypes.REMOVE_CAMPAIGN_PROBE_SUCCESS]:
    removeCampaignProbeSuccess,
  [NewGridPeriodTypes.REMOVE_CAMPAIGN_FIXED_CPA_SUCCESS]:
    removeCampaignFixedCpaSuccess,
  [NewGridPeriodTypes.REMOVE_CAMPAIGN_BONUS_SUCCESS]:
    removeCampaignBonusSuccess,
});
