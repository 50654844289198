import queryString from 'query-string';
import api from './axios';

const urlBase = window.location.hostname === 'admin.oiads.com.br' ? 'https://artemis-apigw.mobicare.com.br/' : '';

const getSubcategories = async () => {
  try {
    const { data } = await api.get(
      `${urlBase}mcare-artemis-audience-service/subcategory/category/manual_segmentation`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const getConfigTemplates = async () => {
  try {
    const { data } = await api.get(
      `${urlBase}tenant/config/segmentation`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const getSegmentations = async ({ name, userKey, page }) => {
  try {
    const { data } = await api.get(
      `${urlBase}segmentation?${queryString.stringify({
        userKey,
        name,
        page,
        pageSize: 20,
      })}`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const getSegmentationData = async (segmentationUuid) => {
  try {
    const { data } = await api.get(
      `${urlBase}segmentation/${segmentationUuid}`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const createSegmentation = async ({ newSegmentation }) => {
  try {
    const { data } = await api.post(
      `${urlBase}segmentation`, { newSegmentation },
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  getSubcategories,
  getConfigTemplates,
  getSegmentations,
  getSegmentationData,
  createSegmentation,
};
