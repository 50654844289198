import styled from 'styled-components';


export const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.light};
  border-radius: 30px;
  margin-top: 30px;
  min-width: 500px;
  overflow-x: auto;

`;

export const Content = styled.div`
  min-width: 1200px;


  .import-summary-title{
    width: 100%;
    margin: 25px 25px;
  }

  .import-summary-row{
    margin: 30px;

    display: grid;
    /* grid-template-columns: repeat(5, 1fr); */
    grid-template-columns: 1fr 30px 1fr 30px 1fr 30px 1fr 30px 1fr;
    /* grid-column-gap: 30px; */

    .import-summary-column-1{
      background-color: ${props => props.theme.colors.lighter};
      border-radius: 20px;
      position: relative;

      .content{
        background-color: ${props => props.theme.colors.lighterWhite};

        height: 100%;

        margin-right: 30px;
        padding: 25px 10px 25px 25px;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;

        display: grid;
        grid-template-rows: repeat(5, 1fr);
        grid-row-gap: 10px;

        img.csv-icon{
          height: 35px;
        }

        span.csv-file-name{
          margin-left: 10px;
          color: ${props => props.theme.colors.primary};
        }

        label{
          width: 100%;
        }

        div.button-row{
          display: flex;
          justify-content: center;
        }

        &::before{
            content: '';
            width: 33px;
            height: 2px;
            background-color: #BDBDBD;
            position: absolute;
            right: -14px;
            top: 52%;
          }

        &::after{
          content: '';
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #BDBDBD;
          position: absolute;
          right: 8px;
          top: 50%;
        }

      }
    }

    .vertical-line-1{
      position: relative;

      &::after{
        content: '';
        width: 2px;
        height: 30%;
        background-color: #BDBDBD;
        position: absolute;
        right: 49%;
        top: 38px;
      }

      &::before{
        content: '';
        width: 2px;
        height: 37.5%;
        background-color: #BDBDBD;
        position: absolute;
        right: 49%;
        top: 15%;
      }
    }

    .import-summary-column-2{

      .content{
        border: 1px solid ${props => props.theme.colors.light};
        border-radius: 20px;
        padding: 25px 25px 25px 25px;

        span.title{
          font-size: 19px;
          margin-left: 15px;
          color: ${props => props.theme.colors.primary};
          position: relative;

          &::before{
            content: '';
            width: 43px;
            height: 2px;
            background-image: linear-gradient(to left, ${props => props.theme.colors.primary}, #BDBDBD);
            position: absolute;
            left: -57px;
            top: 9px;
          }

          &::after{
            content: '';
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: ${props => props.theme.colors.primary};
            position: absolute;
            left: -25px;
            top: 3px;
          }
        }
      }
    }

    .import-summary-column-3{

      .content{
        border: 1px solid ${props => props.theme.colors.light};
        border-radius: 20px;
        padding: 25px 25px 25px 25px;

        span.title{
          font-size: 19px;
          margin-left: 15px;
          color: ${props => props.theme.colors.primary};
          position: relative;

          /* &::before{
            content: '';
            width: 43px;
            height: 2px;
            background-image: linear-gradient(to left, ${props => props.theme.colors.primary}, #BDBDBD);
            position: absolute;
            left: -57px;
            top: 9px;
          } */

          &::after{
            content: '';
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: ${props => props.theme.colors.primary};
            position: absolute;
            left: -25px;
            top: 3px;
          }
        }
      }
    }

    .import-summary-column-4{

      .content{
        border: 1px solid ${props => props.theme.colors.light};
        border-radius: 20px;
        padding: 25px 25px 25px 25px;
      }
    }

    .import-summary-column-5{

      .content{
        border: 1px solid ${props => props.theme.colors.light};
        border-radius: 20px;
        padding: 25px 25px 25px 25px;

        span.title{
          font-size: 19px;
          margin-left: 15px;
          color: ${props => props.theme.colors.primary};
          position: relative;
        }
      }
    }

  }
`;

export const MiniCardInfoExpanded = styled.div`
  background-color: ${props => props.theme.colors.lighter};
  border-radius: 20px;
  margin: 15px -45px 0px 0px;

  .summary-mini-card-info-expanded-content{
    background-color: ${props => props.theme.colors.lighterWhite};
    margin-right: 30px;
    padding: 15px 5px 15px 15px;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;

    label{
      width: 100%;
    }

    span{
      font-size: 20px;
    }
  }
`;

export const MiniCardInfo = styled.div`
  border-radius: 20px;
  margin: 15px 0px 0px 0px;

  .summary-mini-card-info-content{
    background-color: ${props => props.theme.colors.lighterWhite};
    padding: 15px 15px 15px 15px;
    border-radius: 20px;

    label{
      width: 100%;
    }

    span{
      font-size: 20px;
    }
  }
`;

export const MiniCardAlert = styled.div`
  border-radius: 20px;
  margin: 15px 0px 0px 0px;

  .summary-mini-card-alert-content{
    background-color: #FFE6E6;
    padding: 15px 5px 15px 15px;
    border-radius: 20px;

    label{
      width: 100%;
    }

    span{
      font-size: 20px;
    }
  }
`;
