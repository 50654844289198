import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: normal;
  font-family: "Simplon BP";
  color: ${props => props.theme.colors.primary};
  span:focus{
      background-color: rgba(196, 242, 203, 0.15);
      border-color: rgba(196, 242, 203, 0.15);
    }

  li a {
    color: ${props => props.theme.colors.dark};
    font-size: 16px;
    border: none;
    margin-right:5px;
    outline: none;
    &:hover {
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.primary};
      border-bottom: solid 3px ${props => props.theme.colors.primary};
    }
  }
  .active a {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primary};
    border-bottom: solid 3px ${props => props.theme.colors.primary};
    &:hover {
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.primary};
      border-bottom: solid 3px ${props => props.theme.colors.primary};
    }
  }

  .pagination{
    margin-bottom:0;
    margin-top:30px;
    .active a {
      background-color: rgba(196, 242, 203, 0.15);
      color: ${props => props.theme.colors.primary};
      border-color: ${props => props.theme.colors.primary};
    }
  }
  .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .previous,
  .next {
    font-size: 20px;
    &.disabled a {
      color: ${props => props.theme.colors.light};
      border: none;
    }
  }
`;
