/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import Timer from 'react-compound-timer';
import Clock from 'react-live-clock';
import ReactTooltip from 'react-tooltip';
import {
  FaTimes,
  FaChevronLeft,
} from 'react-icons/fa';

import { json2excel } from 'js2excel';

import { Creators as AuctionActions } from '~/redux/ducks/auction';
import { Creators as ProductActions } from '~/redux/ducks/product';
import Button from '~/Components/Button';
import Checkbox from '~/Components/Checkbox';
import Modal from '~/Components/Modal';
import IncludeHasPermission from '~/Components/IncludeHasPermission';
import {
  Container,
  TermWrap,
  TermHeader,
  TermContent,
  TermFooter,
  Row,
  RowFooter,
  FormGroup,
  // Input,
  Table,
  TableFooter,
  ActionContainer,
  Select,
  Header,
  HeaderForm,
  HeaderFormInput,
  HeaderStatus,
  HeaderStatusTitle,
  HeaderStatusContent,
  HeaderStatusContentItem,
  ButtonCloseModal,
  CurrentInput,
} from './styles';

import { convertFloatReal, getBudgetLabelString } from '~/Utils';
import ModalDetailProduct from '~/Pages/Product/ModalDetailProduct';

class AuctionsList extends Component {
  state={
    modalTerms: false,
    acceptedTerms: false,
  };

  componentDidMount() {
    const { auctionSetup } = this.props;
    auctionSetup();
  }

  componentWillUnmount() {
    this.resetPageData();
  }

  resetPageData = () => {
    const {
      auctionChannelRequestSuccess,
      auctionPeriodRequestSuccess,
      auctionRequestSuccess,
      auctionSetFilter,
    } = this.props;
    auctionChannelRequestSuccess([]);
    auctionPeriodRequestSuccess([]);
    auctionRequestSuccess(null);
    auctionSetFilter({
      period: null,
      channel: null,
    });
  };

  renderStatusAuction = () => {
    const { auction, auctionSetup, auctionAlgorithm } = this.props;
    const dateNow = moment();
    const dateInitial = moment(auction.gridPeriod.bidOpenDate);
    const dateFinal = moment(auction.gridPeriod.bidCloseDate);
    const timerCounter = () => {
      if (dateNow.isBefore(dateInitial)) return dateInitial.diff(dateNow);
      if (dateNow.isBefore(dateFinal)) return dateFinal.diff(dateNow);
      return 0;
    };

    const { type: algorithmType } = auctionAlgorithm;

    return (
      <HeaderStatus>
        <HeaderStatusTitle>
          {'STATUS DO LEILÃO - '}
          {auction.status === 'OPEN' ? 'ABERTO' : 'FECHADO'}
        </HeaderStatusTitle>
        <HeaderStatusContent>
          <HeaderStatusContentItem>
            <b>Indicador: </b>
            {algorithmType === 'default' && (<span>Métrica Default</span>)}
            {algorithmType === 'bcg' && (<span>Métrica BCG</span>)}
          </HeaderStatusContentItem>
          <HeaderStatusContentItem>
            <b>Inicio: </b>
            {moment(auction.gridPeriod.bidOpenDate).format('DD/MM/YYYY HH:mm')}
          </HeaderStatusContentItem>
          <HeaderStatusContentItem>
            <b>Final: </b>
            {moment(auction.gridPeriod.bidCloseDate).format('DD/MM/YYYY HH:mm')}
          </HeaderStatusContentItem>
          {/*
          <HeaderStatusContentItem>
            <b>Data atual: </b>
            <Clock format="DD/MM/YYYY HH:mm:ss" ticking />
          </HeaderStatusContentItem>
          */}
          <HeaderStatusContentItem>
            <b>{dateNow.isBefore(dateInitial) ? 'Começa em: ' : 'Tempo Restante: '}</b>
            <span className={dateNow.isBefore(dateInitial) ? 'successDate' : 'dangerDate'}>
              <Timer
                initialTime={timerCounter()}
                direction="backward"
                lastUnit="h"
                checkpoints={[
                  {
                    time: 0,
                    callback: () => {
                      this.resetPageData();
                      auctionSetup();
                    },
                  },
                ]}
              >
                <Timer.Hours formatValue={value => (value < 10 ? `0${value}` : value)} />
                :
                <Timer.Minutes formatValue={value => (value < 10 ? `0${value}` : value)} />
                :
                <Timer.Seconds formatValue={value => (value < 10 ? `0${value}` : value)} />
              </Timer>
            </span>
          </HeaderStatusContentItem>
        </HeaderStatusContent>
      </HeaderStatus>
    );
  };

  renderHeader = () => {
    const {
      periodList,
      channelList,
      auctionFilter,
      auctionSetFilter,
      auctionRequest,
      auctionSetup,
      auction,
    } = this.props;
    return (
      <Header>
        <HeaderForm>
          <HeaderFormInput>
            <label>Semana:</label>
            <Select
              placeholder="Selecione a semana"
              value={auctionFilter.period}
              onChange={(period) => {
                auctionSetFilter({ period });
                auctionSetup();
              }}
              options={periodList}
              isClearable
            />
          </HeaderFormInput>
          <HeaderFormInput>
            <label>Canal:</label>
            <Select
              placeholder="Selecione o canal"
              value={auctionFilter.channel}
              onChange={(channel) => {
                auctionSetFilter({ channel });
                auctionRequest();
              }}
              options={channelList}
              isClearable
            />
          </HeaderFormInput>
        </HeaderForm>
        {auction && this.renderStatusAuction()}
      </Header>
    );
  }

  renderTable = () => {
    const { auction, auctionSetDataGridUpdate, showDetailProductSetData } = this.props;
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th width="1%" align="center">POSIÇÃO</th>
              <th>CAMPANHA</th>
              <th width="1%" />
              <th>PARCEIRO</th>
              <th>PRODUTO - OFERTA</th>
              <th width="2%" align="center">eCPM ANTERIOR</th>
              <th width="1%" align="center">eCPM</th>
              <th width="1%" align="center">% CONVERSÃO</th>
              <th width="1%" align="center">AÇÕES</th>
              <th width="1%" align="center">INDICADORES</th>
            </tr>
          </thead>
          <tbody>
            {auction.gridItems.length < 1 ? (
              <tr>
                <td colSpan="10">
                  <div className="empty-table-desc">
                    <span> Não foram localizados registros de leilões.</span>
                  </div>
                </td>
              </tr>
            ) : auction.gridItems.map(item => (
              <tr key={item.key}>
                <td align="center">
                  <span>
                    {item.ranking}
                  </span>
                </td>
                <td>
                  <span>
                    {item.campaign.name}
                  </span>
                </td>
                <td>
                  {item.modified && (
                    <span className="auctionModified">Bid alterado</span>
                  )}
                </td>
                <td>
                  <span>
                    {item.campaign.contract.name}
                  </span>
                </td>
                <td>
                  {item.campaign.productOffers.map(po => (
                    <span key={`${item.key}-${po.uuid}`}>
                      {`${po.product.name} - ${po.name}`}
                      <br />
                    </span>
                  ))}
                </td>
                <td align="center">
                  <span className="nowrap">
                    {`R$ ${item.previousEcpm ? parseFloat(item.previousEcpm).toFixed(2).replace('.', ',') : '0,00'}`}
                  </span>
                </td>
                <td align="center">
                  <span className="nowrap">
                    {`R$ ${item.ecpm ? parseFloat(item.ecpm).toFixed(2).replace('.', ',') : '0,00'}`}
                  </span>
                </td>
                <td align="center">
                  <span className="nowrap">
                    {parseFloat(item.conversionRate).toFixed(2)}
                  </span>
                </td>
                <td align="center">
                  {auction.status === 'OPEN' && item.uuid && (
                    <ActionContainer>
                      {item.campaign.canBid && (
                        <>
                          {!!item.validate && !item.validate.bidEnabled ? (
                            <>
                              <Button
                                icon="FaInfoCircle"
                                noLabel
                                bordered
                                onClick={() => {}}
                                data-tip={item.validate.bigDisabledMessage}
                              />
                              <ReactTooltip place="left" effect="solid" />
                            </>
                          ) : (
                            <Button
                              icon="FaDollarSign"
                              noLabel
                              onClick={() => {
                                auctionSetDataGridUpdate({
                                  gridUuid: auction.uuid,
                                  gridItemUuid: item.uuid,
                                  campaignUuid: item.campaign.uuid,
                                  cpa: item.campaign.cpa,
                                  budget: parseFloat(item.campaign.budget),
                                });
                              }}
                            />
                          )}
                        </>
                      )}
                    </ActionContainer>
                  )}
                </td>
                <td>
                  {item.uuid && (
                    <ActionContainer>
                      <Button
                        icon="FaInfo"
                        noLabel
                        onClick={() => {
                          const productOffer = item.campaign.productOffers[0];
                          if (productOffer && productOffer.product) {
                            showDetailProductSetData(productOffer.product);
                          }
                        }}
                      />
                    </ActionContainer>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <TableFooter>
          <div>
            {`* Ranking gerado em: ${moment(auction.updatedAt).format('DD/MM/YYYY HH:mm:ss')}`}
          </div>
          <div>
            Data atual:
            <Clock format="DD/MM/YYYY HH:mm:ss" ticking />
          </div>
        </TableFooter>
      </>
    );
  };

  renderModalGridUpdate = () => {
    const {
      dataGridUpdate,
      auctionSetDataGridUpdate,
      auction,
      auctionGetTermPreview,
      auctionGetTermPreviewSuccess,
    } = this.props;

    const oldDataGridItem = auction.gridItems.find(
      item => item.campaign.uuid === dataGridUpdate.campaignUuid,
    );
    const { extra } = oldDataGridItem.campaign.zone;
    const { pricing } = extra;

    const showPricingMinCPA = pricing
      && pricing.cpa
      && dataGridUpdate.cpa < pricing.cpa.min;

    const showPricingMinBudget = pricing
      && pricing.budget
      && dataGridUpdate.budget < pricing.budget.min;

    let disabledBtnProsseguir = false;
    if (showPricingMinCPA) {
      if (dataGridUpdate.cpa < pricing.cpa.min) {
        disabledBtnProsseguir = true;
      }
    }
    if (showPricingMinBudget) {
      if (dataGridUpdate.budget > 0 && dataGridUpdate.budget < pricing.budget.min) {
        disabledBtnProsseguir = true;
      }
    }

    return (
      <Modal>
        <Row>
          <h1>Efetuar BID</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              this.setState({
                modalTerms: false,
                acceptedTerms: false,
              });
              auctionGetTermPreviewSuccess(null);
              auctionSetDataGridUpdate(null);
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row>
          <FormGroup>
            <label>
              CPA:
              {showPricingMinCPA && (
                <small>{`Mínimo: R$${convertFloatReal(pricing.cpa.min)}`}</small>
              )}
            </label>
            <CurrentInput
              placeholder="CPA"
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              value={parseFloat(dataGridUpdate.cpa)}
              invalid={!dataGridUpdate.cpa || (showPricingMinCPA && dataGridUpdate.cpa < pricing.cpa.min)}
              onChange={(e, cpa) => {
                auctionSetDataGridUpdate({
                  ...dataGridUpdate,
                  cpa: parseFloat(cpa),
                });
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label>
              Budget:
              {showPricingMinBudget && dataGridUpdate.budget > 0 && (
                <small>{`Mínimo: R$${convertFloatReal(pricing.budget.min)}`}</small>
              )}
            </label>
            <CurrentInput
              placeholder="Budget"
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              value={parseFloat(dataGridUpdate.budget)}
              invalid={showPricingMinBudget && dataGridUpdate.budget > 0 && dataGridUpdate.budget < pricing.budget.min}
              onChange={(e, budget) => {
                auctionSetDataGridUpdate({
                  ...dataGridUpdate,
                  budget: parseFloat(budget),
                });
              }}
            />
          </FormGroup>
        </Row>
        <RowFooter>
          <Button
            icon="FaAngleDoubleRight"
            title="Prosseguir"
            disabled={disabledBtnProsseguir}
            onClick={() => {
              auctionGetTermPreview();
              this.setState({
                modalTerms: true,
              });
              // auctionGridUpdate();
            }}
          />
        </RowFooter>
      </Modal>
    );
  };

  renderModalTerms = () => {
    const {
      auctionGridUpdate, auctionGetTermPreviewSuccess, termPreview, dataGridUpdate,
    } = this.props;
    const { acceptedTerms } = this.state;
    return (
      <Modal width={1000} height={600} minHeight={600} scroll={false} padding={0}>
        <TermWrap>
          <TermHeader>
            <h1>PEDIDO DE INSERÇÃO</h1>
            <ButtonCloseModal
              type="button"
              onClick={() => {
                this.setState({
                  modalTerms: false,
                  acceptedTerms: false,
                });
                auctionGetTermPreviewSuccess(null);
              }}
            >
              <FaTimes size={30} />
            </ButtonCloseModal>
          </TermHeader>
          <TermContent>
            <p>
              {`
                Este documento é considerado um Termo Aditivo aos TERMOS E CONDIÇÕES que regulam a
                contratação dos serviços (“Serviços”) oferecidos pela AKROSS PLATAFORMAS TECNOLÓGICAS E
                SERVIÇOS LTDA (“AKROSS”), inscrita no CNPJ sob o n º 32.275.954/0001-01, disponíveis através
                da plataforma [www.platform.akross.com.br] ou por qualquer outro website, APIs (interfaces
                de programação de aplicativos), aplicativos móveis, extensões, widgets ou similares
                (coletivamente, a “Plataforma”), ao USUÁRIO ANUNCIANTE abaixo qualificado.
              `}
            </p>
            <p>
              {`
                Este PEDIDO DE INSERÇÃO contém a descrição e registro das condições específicas de
                contratação da campanha promocional abaixo descrita, que passa a integrar o contrato acima
                aludido para todos os fins de direito. Caso o ANUNCIANTE constate qualquer erros, omissão ou
                incongruência nestes dados, deve comunicar imediatamente a AKROSS e interromper qualquer
                atividade relacionada à campanha imediatamente, até a solução do problema.
              `}
            </p>
            <table>
              <tr>
                <td>Nome da Campanha</td>
                <td>{termPreview.campaignName}</td>
              </tr>
              <tr>
                <td>Parceiro</td>
                <td>{termPreview.contract}</td>
              </tr>
              <tr>
                <td>CNPJ</td>
                <td>{termPreview.cnpj}</td>
              </tr>
              <tr>
                <td>Produto</td>
                <td>{termPreview.product}</td>
              </tr>
              <tr>
                <td>Tipo de Campanha</td>
                <td>{termPreview.campaignType}</td>
              </tr>
              <tr>
                <td>Canal</td>
                <td>{termPreview.channel}</td>
              </tr>
              <tr>
                <td>CPA</td>
                <td>
                  {'R$ '}
                  {parseFloat(dataGridUpdate.cpa).toFixed(2).replace('.', ',')}
                </td>
              </tr>
              <tr>
                <td>Budget</td>
                <td>{getBudgetLabelString(dataGridUpdate.budget)}</td>
              </tr>
              <tr>
                <td>Leilão</td>
                <td>{termPreview.gridPeriod}</td>
              </tr>
              <tr>
                <td>Responsável pela Campanha</td>
                <td>{termPreview.responsibleName}</td>
              </tr>
              <tr>
                <td>Responsável pelo Pagamento</td>
                <td>{termPreview.responsiblePayment}</td>
              </tr>
              <tr>
                <td>Mês de vencimento</td>
                <td>{termPreview.expirationMonth}</td>
              </tr>
            </table>
            <p>
              {`
                O USUÁRIO ANUNCIANTE acorda tacitamente com as condições acima descritas, nesta data.
                Rio de Janeiro, ${moment().format('DD [de] MMMM [de] YYYY')}.
              `}
            </p>

            <FormGroup style={{ alignItems: 'center', marginTop: 10, marginBottom: 40 }}>
              <Checkbox
                label="Aceitar termos de realização de BID"
                value={acceptedTerms}
                onChange={(value) => {
                  this.setState({
                    acceptedTerms: value,
                  });
                }}
              />
            </FormGroup>
          </TermContent>
          <TermFooter>
            <button
              className="terms-previous"
              type="button"
              onClick={() => {
                this.setState({
                  modalTerms: false,
                  acceptedTerms: false,
                });
                auctionGetTermPreviewSuccess(null);
              }}
            >
              <FaChevronLeft />
              VOLTAR
            </button>
            <Button
              icon="FaSave"
              title="Salvar"
              disabled={!acceptedTerms}
              onClick={() => {
                this.setState({
                  modalTerms: false,
                  acceptedTerms: false,
                });
                auctionGetTermPreviewSuccess(null);
                auctionGridUpdate();
              }}
            />
          </TermFooter>
        </TermWrap>
      </Modal>
    );
  };

  handleExportToExcel = () => {
    const { auction } = this.props;
    const data = auction.gridItems.map(item => (
      {
        POSIÇÃO: item.ranking,
        KEYWORD: item.campaign.productOffers.map(po => `${po.CampaignProductOffer ? po.CampaignProductOffer.keyword : ''}`).join(', '),
        CAMPANHA: item.campaign.name,
        PARCEIRO: item.campaign.contract.name,
        'PRODUTO - OFERTA': item.campaign.productOffers.map(po => `${po.product.name} - ${po.name}`).join(', '),
        'eCPM ANTERIOR': `R$ ${item.previousEcpm ? parseFloat(item.previousEcpm).toFixed(2).replace('.', ',') : '0,00'}`,
        eCPM: `R$ ${item.ecpm ? parseFloat(item.ecpm).toFixed(2).replace('.', ',') : '0,00'}`,
        '% CONVERSÃO': parseFloat(item.conversionRate).toFixed(2),
      }
    ));
    data.push({ eCPM: 'Exportação:', '% CONVERSÃO': `${moment().format('DD-MM-YYYY, hh:mm')}` });
    try {
      json2excel({
        data,
        name: 'Leilão',
        formateDate: 'dd/mm/yyyy',
      });
    } catch (e) {
      console.error('export error', e);
    }
  }

  render() {
    const {
      auction, dataGridUpdate, auctionRankingUpdate, termPreview,
    } = this.props;
    const { modalTerms } = this.state;
    return (
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1 className="header-title">LEILÃO</h1>
          {auction && (
            <>
              <Button
                bordered
                icon="FaFileExcel"
                title="Exportar"
                onClick={() => {
                  this.handleExportToExcel();
                }}
                type="button"
              />
              <IncludeHasPermission roles={['AUCTION_ADMIN']}>
                <Button
                  bordered
                  onClick={() => {
                    auctionRankingUpdate();
                  }}
                  title="ATUALIZAR"
                />
              </IncludeHasPermission>
            </>
          )}
        </Row>
        {this.renderHeader()}
        {auction && this.renderTable()}
        {dataGridUpdate && this.renderModalGridUpdate()}
        {modalTerms && termPreview && this.renderModalTerms()}
        <ModalDetailProduct />
      </Container>
    );
  }
}

AuctionsList.defaultProps = {
  dataGridUpdate: null,
  auction: null,
  termPreview: null,
};

AuctionsList.propTypes = {
  channelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  periodList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  auction: PropTypes.shape(),
  auctionFilter: PropTypes.shape().isRequired,
  auctionSetFilter: PropTypes.func.isRequired,
  auctionRequest: PropTypes.func.isRequired,
  auctionSetup: PropTypes.func.isRequired,
  dataGridUpdate: PropTypes.shape(),
  auctionSetDataGridUpdate: PropTypes.func.isRequired,
  auctionGridUpdate: PropTypes.func.isRequired,
  auctionRankingUpdate: PropTypes.func.isRequired,
  auctionChannelRequestSuccess: PropTypes.func.isRequired,
  auctionPeriodRequestSuccess: PropTypes.func.isRequired,
  auctionRequestSuccess: PropTypes.func.isRequired,
  auctionGetTermPreview: PropTypes.func.isRequired,
  auctionGetTermPreviewSuccess: PropTypes.func.isRequired,
  termPreview: PropTypes.shape(),
  showDetailProductSetData: PropTypes.func.isRequired,
  auctionAlgorithm: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  auctionAlgorithm: state.auth.auctionAlgorithm,
  channelList: state.auction.channelList,
  periodList: state.auction.periodList,
  auction: state.auction.auction,
  auctionFilter: state.auction.auctionFilter,
  dataGridUpdate: state.auction.dataGridUpdate,
  termPreview: state.auction.termPreview,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    auctionRequest: AuctionActions.auctionRequest,
    auctionSetup: AuctionActions.auctionSetup,
    auctionSetFilter: AuctionActions.auctionSetFilter,
    auctionSetDataGridUpdate: AuctionActions.auctionSetDataGridUpdate,
    auctionGridUpdate: AuctionActions.auctionGridUpdate,
    auctionRankingUpdate: AuctionActions.auctionRankingUpdate,
    auctionChannelRequestSuccess: AuctionActions.auctionChannelRequestSuccess,
    auctionPeriodRequestSuccess: AuctionActions.auctionPeriodRequestSuccess,
    auctionRequestSuccess: AuctionActions.auctionRequestSuccess,
    auctionGetTermPreview: AuctionActions.auctionGetTermPreview,
    auctionGetTermPreviewSuccess: AuctionActions.auctionGetTermPreviewSuccess,
    showDetailProductSetData: ProductActions.showDetailProductSetData,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuctionsList);
