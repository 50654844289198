import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Input = ({
  id,
  name,
  value,
  onChange,
  className,
  placeholder,
  disabled,
  ...rest
}) => (
  <>
    <Container
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      {...rest}
    />
  </>
);

Input.defaultProps = {
  disabled: false,
  className: null,
  placeholder: null,
  name: null,
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Input;
