import styled from 'styled-components';
import { ContainerStyled } from '~/Components/Styled';

export const Wrap = styled(ContainerStyled)`

`;

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 30px;
  margin-top: 15px;
  margin-bottom: 30px;

  table{
    th{
      color:${props => props.theme.colors.primary};
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  h1{
    width: 500px;
  };

  @media(max-width: 1250px) {
    flex-direction: column;
  }

`;

export const HeaderFormGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  gap: 15px;

  @media(max-width: 910px) {
    flex-direction: column;
    div{
      display: flex;
      width: 100%;
      justify-content: end;
    }
  }
`;

export const SearchGroup = styled.div`
  display: flex;

  @media(max-width: 910px) {
    justify-content: end;
  }
`;

export const SearchInput = styled.input`

  border-radius: 10px 0px 0px 10px;
  padding: 5px 5px 5px 15px;
  border-right: 0;
  min-width: 350px;
  outline: none;

  font-size: 14pt;
  color:${props => props.theme.colors.dark};
  font-weight: 600;

  border-bottom: 1px solid ${props => props.theme.colors.light};
  border-top: 1px solid ${props => props.theme.colors.light};
  border-left: 1px solid ${props => props.theme.colors.light};

`;

export const SearchButton = styled.button`

  border-radius: 0px 10px 10px 0px;
  min-width: 60px;
  outline: none;

  color:${props => props.theme.colors.primary};
  background-color:${props => props.theme.colors.white};

  border: 2px solid ${props => props.theme.colors.primary};

  img.search-icon{
      height: 26px;
    }

  &&:hover{
    filter: brightness(0.97);
    transition: .2s all;

    img.search-icon{
      filter: brightness(1.1);
      transition: .2s all;
    }
  }

`;

export const Select = styled.select`

  border-radius: 10px;
  padding: 5px 20px;
  width: 250px;
  outline: none;
  min-height: 46px;

  font-size: 14pt;
  color:${props => props.theme.colors.dark};
  font-weight: 600;

  border: 1px solid ${props => props.theme.colors.regular};

  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
        background-position: right 10px top; /* Change the px value here to change the distance */
        -moz-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
`;
