import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px;

  > img {
    width: 100%;
    max-width: 400px;

    margin-bottom: 100px;
  }

  > h1 {
    font-size: 50px;
    color: ${props => props.theme.colors.primary};
    text-align: center;
  }

  > p {
    font-size: 25px;
    margin-bottom: 0;
    text-align: center;
  }

  > span {
    font-size: 20px;
    font-style: italic;
    text-decoration: underline;
    margin-top: 5px;
  }

  > button {
    width: 100%;
    max-width: 500px;

    margin-top: 100px;
    margin-left: 0;
  }
`;
