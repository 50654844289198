import styled from 'styled-components';
import { ContainerStyled } from '~/Components/Styled';

export const Wrap = styled(ContainerStyled)`
`;

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 550px;
  position: relative;
  padding: 30px;
  margin-bottom: 30px;


  .segmentation-header{
    margin-bottom: 30px;

    display: flex;
    justify-content: space-between;

    .header-buttons{
      display: flex;
      gap: 15px;
    }

    @media(max-width: 1000px) {
      flex-direction: column;

      h1{
        margin-bottom: 20px;
      }

      .header-buttons{
        display: flex;
        gap: 15px;
        justify-content: flex-end;

        min-width: 500px;

      }
    }

  }


  .identification{
    border: 1px solid ${props => props.theme.colors.light};
    border-radius: 30px;
    min-width: 500px;

    .identification-row{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 25px;
      padding: 10px 0px 25px 0px;
      margin: 0px 25px 0px 25px;

      h3{
        margin: 10px 0px 10px 0px;
        grid-column-start: 1;
        grid-column-end: 4;
      }

      .block{
        label{
          padding: 0;
          margin-left: 10px;
          margin-bottom: 0px;
          color: ${props => props.theme.colors.primary};
        }
      }
    }

    @media(max-width: 1000px) {
      .identification-row{
        grid-template-columns: repeat(2, 1fr);

        h3{
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }

    @media(max-width: 700px) {
      .identification-row{
        grid-template-columns: repeat(1, 1fr);

        h3{
          grid-column-start: 1;
          grid-column-end: 2;
        }
      }
    }
  }

  .row-with-multiple-blocks{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;

    min-width: 500px;

    margin: 30px 0;

    @media(max-width: 1000px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .automatically-generated-audience-block{
      border: 1px solid ${props => props.theme.colors.light};
      border-radius: 30px;

      .block-content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 15px;
        grid-column-gap: 35px;

        margin: 25px 25px;

        h3{
          margin: 10px 0px 10px 0px;
          grid-column-start: 1;
          grid-column-end: 3;
        }

        label{
          width: 100%;
        }

        span{
          font-size: 19px;
          text-transform: uppercase;
        }
      }
    }

    .actual-impact-block{
      border: 1px solid ${props => props.theme.colors.light};
      border-radius: 30px;

      .block-content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 15px;
        grid-column-gap: 35px;

        margin: 25px 25px;

        h3{
          margin: 10px 0px 10px 0px;
          grid-column-start: 1;
          grid-column-end: 3;
        }

        label{
          width: 100%;
        }

        label.select-zone{
          margin-left: 15px;
        }

        h1.estimated-impact{
          font-size: 35px;
        }
      }
    }
  }

`;
