/* eslint-disable no-plusplus */
import { call, put, select } from 'redux-saga/effects';
import ChannelUtil from '~/Utils/ChannelUtil';
import moment from 'moment';
import uuidv4 from 'uuid/v4';

import { push, replace } from 'connected-react-router';

import FileSaver from 'file-saver';
import CampaignService from '~/services/CampaignService';
// import ContractService from '~/services/ContractService';
// import ProviderService from '~/services/ProviderService';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import { Creators as BenefitActions } from '~/redux/ducks/benefit';
import { Creators as CampaignActions } from '~/redux/ducks/campaign';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

export function* loadCampaignListView() {
  yield put(startRequest());
  let products = [];
  let channels = [];
  let types = [];
  let status = [];

  try {
    products = yield call(CampaignService.listProducts);
  } catch (e) {
    // ERROR
  }
  try {
    channels = yield call(CampaignService.listChannels);
  } catch (e) {
    // ERROR
  }
  try {
    types = yield call(CampaignService.listTypes);
  } catch (e) {
    // ERROR
  }
  try {
    status = yield call(CampaignService.listStatus);
  } catch (e) {
    // ERROR
  }

  try {
    yield put(
      CampaignActions.loadCampaignListViewSuccess({
        products: products || [],
        channels: channels || [],
        types: types || [],
        status: status || [],
      }),
    );
    yield put(requestSuccess());
  } catch (error) {
    yield put(requestError());
  }
}

export function* loadCampaignEdit(action) {
  const campaignId = action.payload;
  yield put(startRequest());
  try {
    const fortuna = [];
    const providers = [];
    const campaign = yield call(CampaignService.get, campaignId);

    if (campaign.cpa) {
      campaign.cpa = parseFloat(campaign.cpa);
    }
    if (campaign.budget) {
      // campaign.budget = parseFloat(campaign.budget);
      campaign.budget = parseFloat(campaign.budget);
    }
    if (campaign.volume) {
      campaign.volume = parseInt(campaign.volume);
    }

    // campaign.screenSchema.include_probe_grid = { visible: true };
    const probeIsVisible = !!campaign.screenSchema.include_probe_grid
      && campaign.screenSchema.include_probe_grid.visible;

    const includeProbeGrid = {
      visible: probeIsVisible,
      enabled: false,
      message: '',
    };

    if (probeIsVisible) {
      try {
        const probeMessage = yield call(CampaignService.verifyIncludeProbeGrid, campaignId);
        includeProbeGrid.enabled = probeMessage.enabled;
        includeProbeGrid.message = probeMessage.message || '';
      } catch (error) {
        // includeProbeGrid.message = 'Falha ao buscar dados de verificação!';
        includeProbeGrid.message = '';
      }
    }

    const attributes = yield call(CampaignService.getAttributesCampaign, campaignId);
    const fact = yield call(CampaignService.getFactCampaign, campaignId);
    const campaignShare = yield call(CampaignService.getCampaignShare, campaignId);

    yield put(
      CampaignActions.loadCampaignEditSuccess({
        campaign: {
          ...campaign,
          hasFrequency: !!campaign.frequencyPerUser,
          includeProbeGrid,
        },
        fortuna,
        providers,
        attributes,
        fact,
        campaignShare,
      }),
    );

    yield put(requestSuccess());

    yield put(
      CampaignEditActions.campaignEditCriativoRequest(campaignId),
    );
    yield put(
      CampaignEditActions.campaignProposalInformation(campaignId),
    );

    yield put(BenefitActions.benefitRequest());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* includeToProbeGrid(action) {
  const campaignId = action.payload;
  yield put(startRequest());
  try {
    yield call(CampaignService.includeToProbeGrid, campaignId);
    yield put(requestSuccess());
    yield put(CampaignActions.loadCampaignEdit(campaignId));
  } catch (error) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao enviar a campanha para teste. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* activeCampaignEdit(action) {
  const campaign = action.payload;
  yield put(startRequest());
  try {
    const { id: userId } = yield select(state => state.auth.currentUser);

    const campaignSave = {
      ...campaign,
      startDate: moment(campaign.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      endDate: moment(campaign.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };
    yield call(CampaignService.update, {
      campaign: campaignSave,
      campaignSave,
    });
    yield call(CampaignService.activeCampaign, {
      campaignId: campaign.id,
      userId,
    });
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha aguardando aprovação.',
    ));
    yield put(requestSuccess());
    yield put(CampaignActions.loadCampaignEdit(campaign.id));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao tentar ativar a campanha.',
    ));
    yield put(requestError());
  }
}


export function* filterCampaign() {
  const { filter } = yield select(state => state.campaign);
  const {
    product,
    name,
    channel,
    type,
    status,
    page,
    keyword,
  } = filter;
  yield put(startRequest());
  try {
    const contractId = yield select(state => state.auth.contractId);
    const { channelUuid, zoneUuid } = ChannelUtil.unwrapChannelKeyValue(channel)

    const resultList = yield call(CampaignService.search, {
      contractId,
      page: page || 0,
      name,
      zone: zoneUuid,
      channel: channelUuid,
      product: product ? product.value : null,
      type: type ? type.value : null,
      status: status ? status.value : null,
      keyword: keyword || null,
    });
    const campaigns = resultList;

    yield put(CampaignActions.filterCampaignSuccess(campaigns));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as campanhas. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* exportFilterCampaign() {
  const { filter } = yield select(state => state.campaign);
  const {
    product, name, channel, type, status, keyword,
  } = filter;
  yield put(startRequest());
  try {
    const contractId = yield select(state => state.auth.contractId);

    const resultStream = yield call(CampaignService.reportDownload, {
      contractId,
      name,
      product: product ? product.value : null,
      channel: channel ? channel.value : null,
      type: type ? type.value : null,
      status: status ? status.value : null,
      keyword: keyword || null,
    });
    const blob = new Blob(
      [resultStream],
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    );
    FileSaver.saveAs(blob, 'dados.xlsx');
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao exportar os dados das campanhas. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* createCampaign(action) {
  const campaign = action.payload;
  yield put(startRequest());
  try {
    const { id: userId } = yield select(state => state.auth.currentUser);

    const result = yield call(CampaignService.create, { campaign, userId });
    if (result.id) {
      yield put(push(`campaigns/edit/${result.id}`));
    }

    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao criar a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* deleteCampaign(action) {
  const campaignId = action.payload;
  yield put(startRequest());
  try {
    yield call(CampaignService.excluir, campaignId);
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha excluida com sucesso.',
    ));
    yield put(requestSuccess());
    yield put(CampaignActions.loadCampaignList());
    yield put(CampaignActions.filterCampaign());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao excluir a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* cloneCampaign(action) {
  const campaignId = action.payload;
  yield put(startRequest());
  try {
    yield call(CampaignService.clonar, campaignId);
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha clonada com sucesso.',
    ));
    yield put(requestSuccess());
    yield put(CampaignActions.loadCampaignList());
    yield put(CampaignActions.filterCampaign());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao clonar a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* saveCampaignEdit(action) {
  const campaign = action.payload;
  yield put(startRequest());
  try {
    const campaignSave = {
      ...campaign,
      startDate: moment(campaign.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      endDate: moment(campaign.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
    };

    const { id: userId } = yield select(state => state.auth.currentUser);

    yield call(CampaignService.update, { campaign: campaignSave, userId });
    yield put(replace('/campaigns'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha salva com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message,
    ));
    yield put(requestError());
  }
}

export function* cancelCampaign({ payload: reason }) {
  yield put(startRequest());
  try {
    const { id: userId } = yield select(state => state.auth.currentUser);
    const { id: campaignId } = yield select(
      state => state.campaign.edit.campaign,
    );

    const campaignCancel = {
      campaignId,
      reason,
      userId,
    };

    yield call(CampaignService.cancelCampaign, campaignCancel);
    yield put(replace('/campaigns'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha cancelada com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message,
    ));
    yield put(requestError());
  }
}

export function* pauseCampaign({ payload: reason }) {
  yield put(startRequest());
  try {
    const { id: userId } = yield select(state => state.auth.currentUser);
    const { id: campaignId } = yield select(
      state => state.campaign.edit.campaign,
    );

    const campaignPause = {
      campaignId,
      reason,
      userId,
    };

    yield call(CampaignService.pauseResumeCampaign, campaignPause);
    yield put(replace('/campaigns'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha pausada com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message,
    ));
    yield put(requestError());
  }
}

export function* resumeCampaign() {
  yield put(startRequest());
  try {
    const { id: userId } = yield select(state => state.auth.currentUser);
    const { id: campaignId } = yield select(
      state => state.campaign.edit.campaign,
    );

    const campaignResume = {
      campaignId,
      userId,
      reason: '',
    };

    yield call(CampaignService.pauseResumeCampaign, campaignResume);
    yield put(replace('/campaigns'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha ativada com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message,
    ));
    yield put(requestError());
  }
}

export function* downloadReport(action) {
  // GET Campaing ID
  const campaignId = action.payload;
  // Start Load
  yield put(startRequest());
  try {
    // Response URL
    let urlResponse = null;
    // Do While status !== Done
    do {
      // GET URL Download CSV
      urlResponse = yield call(CampaignService.downloadReport, campaignId);
      // Check if is Done
      if (urlResponse.status === 'Done') {
        // Open new window to download
        window.open(urlResponse.url, '_blank');
      }
    } while (urlResponse.status !== 'Done');
    // Stop Load
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar o arquivo para download. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* openModalNewCampaign() {
  yield put(startRequest());
  let recipes = [];

  try {
    recipes = yield call(CampaignService.listRecipes);
  } catch (e) {
    // ERROR
  }

  yield put(
    CampaignActions.showModalNewCampaign(recipes),
  );
  yield put(requestSuccess());
}

export function* updateProductsListByZoneNewCampaign({ payload }) {
  yield put(startRequest());
  const zoneUuid = payload ? payload.data.uuid : null;

  try {
    const products = yield call(CampaignService.listProducts, { zoneUuid });
    yield put(
      CampaignActions.setProductsNewCampaign(products),
    );
    yield put(requestSuccess());
  } catch (e) {
    // ERROR
  }
}

export function* setDefaultProductOffersNewCampaign() {
  yield put(startRequest());
  try {
    const { form } = select(state => state.campaign.modalNewCampaign);

    const products = yield call(CampaignService.listProducts);
    const localProducts = products.map(item => ({
      value: item.uuid,
      label: item.name,
      data: item,
    }));
    const product = localProducts[0];
    const localOffers = product.data.productOffers.map(po => ({
      value: po.uuid,
      label: po.name,
      data: po,
    }));
    const offer = localOffers[0];
    yield put(
      CampaignActions.updateFormNewCampaign({
        ...form,
        name: '',
        description: '',
        type: '',
        productOffers: [
          {
            uuid: uuidv4(),
            product,
            offer,
          },
        ],
      }),
    );
    yield put(requestSuccess());
  } catch (e) {
    // ERROR
  }
}

export function* confirmCreateCampaign() {
  const {
    recipe,
    form: {
      type: {
        value: campaignTypeUuid,
      },
      productOffers,
      zone,
      name,
      description,
    },
  } = yield select(state => state.campaign.modalNewCampaign);

  yield put(startRequest());
  try {
    let zoneUuid = null;
    if (zone) {
      zoneUuid = zone.value;
    } else {
      zoneUuid = recipe.channel.zones[0].uuid;
    }
    const campaignSave = {
      recipeUuid: recipe.uuid,
      campaignTypeUuid,
      productOffers: productOffers.map(item => item.offer.data),
      zoneUuid,
      name,
      description,
    };
    const response = yield call(CampaignService.create, campaignSave);
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha salva com sucesso.',
    ));
    yield put(CampaignActions.closeModalNewCampaign());
    // yield put(CampaignActions.filterCampaign());
    yield put(replace(`/campaigns/edit/${response.uuid}`));
  } catch (e) {
    yield put(requestError());
    if (e && e.type === 'CampaignResultNotFoundException') {
      yield put(ToastrMessageActions.warningMessage(
        'Atenção!',
        'Para essa configuração de Produto e Oferta é necessário criar uma campanha com o tipo Campanha de Teste inicialmente.',
      ));
    } else if (e && e.type === 'ConflictException') {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        `Já existe uma campanha com o nome "${name}".`,
      ));
    } else {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Ocorreu um erro ao cadastrar a campanha. Tente novamente mais tarde.',
      ));
    }
  }
}

export function* workflowStatus() {
  const { transition } = yield select(state => state.campaign);
  yield put(startRequest());
  try {
    yield call(CampaignService.workflowStatus, transition);
    yield put(requestSuccess());
    yield put(CampaignActions.setCampaignTransitionState(null));
    yield put(CampaignActions.loadCampaignEdit(transition.campaignUuid));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Ocorreu um erro ao realizar a transição da campanha. Tente novamente mais tarde.',
    ));
    yield put(requestError());
  }
}
