import styled, { css } from 'styled-components';
import { hex2rgba } from '~/Utils';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => hex2rgba(props.theme.colors.dark, 0.5)};
  .value-range {
    align-self: flex-end;
    float: right;
    font-size: 18px;
    font-weight: 200;
    color: ${props => props.theme.colors.regular};
    margin-right: 15px;
  }
`;

export const ModalBox = styled.div`
  position: relative;
  z-index: 2;
  padding: 50px 50px 50px 50px;
  min-width: 950px;
  min-height: 650px;
  max-height: calc(100vh - 60px);
  background-color: white;
  border-radius: 30px;
  overflow-y: ${props => (props.scroll ? 'auto' : 'visible')};

  .box-sem-dados{
    font-size: large;
    height: 500px !important;
    max-height: 100%;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 3px dashed ${props => props.theme.colors.primary};
    border-radius: 14px;
    padding: 10px 14px;
    display: flex;

    p {
      color: ${props => props.theme.colors.primary};
      margin-bottom: 0;
    }
  }

  .scroll-table{
    display: block;
    width: 850px;
    height: 500px;
    overflow: auto;
  }

  ${props => props.width
    && css`
      width: ${props.width}px;
    `};
  ${props => props.height
    && css`
      max-height: ${props.height}px;
    `};
  ${props => props.minHeight
    && css`
      min-height: ${props.minHeight}px;
    `};
    header{
      text-align: center;
      padding-bottom:50px;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
        h1{
        font-size: xx-large;
        color: ${props => props.theme.colors.primary};
        margin: 0;
        .export-modal-icon{
          padding-right: 0.4em;
          padding-bottom: 0.2em;
        }
        .refresh-modal-button{
          margin-left: 0.8em;
          padding-left: 0.8em;
          padding-top: 0.2em;
          border: 0px;
          border-left: 1px solid ${props => props.theme.colors.light};
          background-color: transparent;
        }
        span{
          font-size: 2rem;
          padding-left: 1rem;
          color: ${props => hex2rgba(props.theme.colors.dark, 0.5)};
        }
      };
    };
  .close-button{
    border: 0px;
    background-color: transparent;
  }
  box-shadow: 0 5px 20px ${props => hex2rgba(props.theme.colors.dark, 0.5)};
`;

export const BtnGroup = styled.div`
  button:first-child{
    width: 8em;
    border-top-right-radius: 0em;
    border-bottom-right-radius: 0em;
    background-color: ${props => props.theme.colors.primary};
  }
  button:last-child{
    width: 4em;
    border-top-left-radius: 0em;
    border-bottom-left-radius: 0em;
  }
`;

export const ContainerTable = styled.table`
  width: 100%;
  thead{
    font-size: large;
    min-width: 650px;
    tr{
      height: 50px;

      th{
        font-weight: 700;
        text-align: center;
        color: ${props => props.theme.colors.primary};
      }
      th:first-child{
        text-align: left;
      }
    }
  }
  tbody{
    min-width: 650px;
      tr{
        font-size: large;
        height: 70px;
        text-align: center;
        border-bottom: 1px solid ${props => props.theme.colors.light};

        button{
          color: ${props => props.theme.colors.white};
          border: 0px;
          border-radius: 1em;
          height: 30px;
          width: 12em;
          background-color: ${props => props.theme.colors.blue};
          img{
            padding-right: 0.3em;
          }

        }

        td:first-child{
          text-align: left;
        }
      }

      .button-span-em-andamento{
        border: 0px;
        border-radius: 1em;
        height: 30px;
        width: 12rem;
        padding: 0.5rem 4.8rem;
        color: ${props => props.theme.colors.dark};
        background-color: ${props => props.theme.colors.light};
      }

      .icon-download{
        height: 1em;
      }
  }
.column-exportado-em{
  width: 10em;
}
.column-acoes{
  width: 12em;
}
`;
