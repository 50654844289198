import styled from 'styled-components';

export const Container = styled.select`

  padding: 5px 20px;
  width: 100%;

  font-size: 14pt;
  color: ${props => (props.disabled ? props.theme.colors.light : props.theme.colors.dark)};

  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.light};

  &:focus{
    outline-color: ${props => props.theme.colors.primary};
  }

  background-color: ${props => (props.disabled ? props.theme.colors.lighterWhite : props.theme.colors.white)} !important;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
        background-position: right 10px top; /* Change the px value here to change the distance */
        -moz-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;

`;
