import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';

import Upload from '~/Components/Upload';
import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import { Creators as FinancialActions } from '~/redux/ducks/financial';
import FinancialService from '~/services/FinancialService';
import {
  startRequest as request,
  requestSuccess as success,
  requestError as error,
} from '~/redux/ducks/loading';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  FileListContent,
  FileList,
  FileListItem,
  CurrentInput,
} from './styles';

registerLocale('pt-BR', pt);

class ModalUpload extends Component {
  state = {
    informationPrices: [],
  };

  componentDidMount() {
    this.setState({
      informationPrices: [],
    });
  }

  handleChangeAmountValue = () => {
    const { setModalInvoiceData, modalInvoiceData } = this.props;
    const { informationPrices } = this.state;
    setModalInvoiceData({
      ...modalInvoiceData,
      amount: parseFloat(informationPrices.reduce(
        (total, item) => total + (item.filePrice || 0),
        0,
      )),
    });
  };

  handleLoadInvoiceInformation = async (invoices) => {
    const { informationPrices } = this.state;
    const { startRequest, requestSuccess, requestError } = this.props;
    try {
      startRequest();
      const invoiceData = await FinancialService.getInvoiceInformation(invoices);
      this.setState({
        informationPrices: [
          ...informationPrices,
          ...invoiceData.prices,
        ],
      }, this.handleChangeAmountValue);
      requestSuccess();
    } catch (e) {
      this.setState({
        informationPrices: [
          ...informationPrices,
          ...invoices.map(i => ({
            fileName: i.name,
            filePrice: 0,
          })),
        ],
      }, this.handleChangeAmountValue);
      requestError();
    }
  };

  uploadFiles = async (upFiles) => {
    const { setModalInvoiceData, modalInvoiceData } = this.props;
    const listOfFiles = [
      ...modalInvoiceData.files,
      ...upFiles,
    ];
    setModalInvoiceData({
      ...modalInvoiceData,
      files: listOfFiles,
    });
    this.handleLoadInvoiceInformation(upFiles);
  };

  getFormIsvalid = () => {
    const { informationPrices } = this.state;
    const { modalInvoiceData } = this.props;
    let formIsValid = true;
    if (!modalInvoiceData.dueDate) formIsValid = false;
    if (!modalInvoiceData.amount) formIsValid = false;
    if (modalInvoiceData.files.length === 0) formIsValid = false;
    informationPrices.forEach((i) => {
      if (!i.filePrice || i.filePrice <= 0) formIsValid = false;
    });
    return formIsValid;
  }

  renderFileItem = (item) => {
    const { setModalInvoiceData, modalInvoiceData } = this.props;
    const { informationPrices } = this.state;
    const findInformationFile = informationPrices.find(file => file.fileName === item.name);
    return (
      <FileListItem>
        <button
          type="button"
          onClick={() => {
            setModalInvoiceData({
              ...modalInvoiceData,
              files: modalInvoiceData.files.filter(file => file.name !== item.name),
            });
            this.setState({
              informationPrices: informationPrices.filter(file => file.fileName !== item.name),
            }, this.handleChangeAmountValue);
          }}
        >
          <FaTimes />
        </button>
        <span>{item.name}</span>
        <CurrentInput
          placeholder={`Valor do arquivo ${item.name}`}
          prefix="R$ "
          decimalSeparator=","
          thousandSeparator="."
          value={findInformationFile ? findInformationFile.filePrice : 0}
          onChange={(e, value) => {
            const newInvoices = informationPrices.map((i) => {
              if (i.fileName === item.name) {
                return {
                  ...i,
                  filePrice: parseFloat(value || 0),
                };
              }
              return i;
            });
            this.setState({
              informationPrices: newInvoices,
            }, this.handleChangeAmountValue);
          }}
        />
      </FileListItem>
    );
  };

  render() {
    const { setModalInvoiceData, modalInvoiceData, financialInvoiceSave } = this.props;
    return (
      <Modal width={700} scroll={false}>
        <Row>
          <h1>{`Lançar cobrança - ${modalInvoiceData.provider.name}`}</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => setModalInvoiceData(null)}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row>
          <FormGroup>
            <label>
              <b>Ano:</b>
              {modalInvoiceData.year}
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              <b>Mês:</b>
              {modalInvoiceData.monthLabel}
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              <b>Parceiro:</b>
              {modalInvoiceData.partner.name}
            </label>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label><b>Data de vencimento:</b></label>
            <DatePicker
              selected={modalInvoiceData.dueDate ? moment(modalInvoiceData.dueDate).toDate() : null}
              onChange={(dueDate) => {
                setModalInvoiceData({
                  ...modalInvoiceData,
                  dueDate,
                });
              }}
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
              minDate={moment().add(1, 'days').toDate()}
            />
          </FormGroup>
          <FormGroup style={{ flex: 1 }}>
            <label><b>Valor:</b></label>
            <CurrentInput
              disabled
              placeholder="Valor"
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              value={modalInvoiceData.amount}
              onChange={(e, value) => {
                setModalInvoiceData({
                  ...modalInvoiceData,
                  amount: value,
                });
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label><b>Arquivos:</b></label>
            <Upload
              multiple
              className="button-file-upload"
              label="Selecione"
              onUpload={this.uploadFiles}
              accept="image/png,image/jpg,image/jpeg,application/pdf"
              onRejectMaxSize={() => {}}
              onClick={() => {}}
            />
          </FormGroup>
        </Row>
        {modalInvoiceData.files.length > 0 && (
          <FileListContent>
            <FileList>
              {modalInvoiceData.files.map(this.renderFileItem)}
            </FileList>
          </FileListContent>
        )}
        <RowFooter>
          <Button
            disabled={!this.getFormIsvalid()}
            title="Confirmar"
            onClick={() => financialInvoiceSave()}
          />
        </RowFooter>
      </Modal>
    );
  }
}

ModalUpload.defaultProps = {
  modalInvoiceData: null,
};

ModalUpload.propTypes = {
  modalInvoiceData: PropTypes.shape(),
  setModalInvoiceData: PropTypes.func.isRequired,
  financialInvoiceSave: PropTypes.func.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalInvoiceData: state.financial.modalInvoiceData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setModalInvoiceData: FinancialActions.setModalInvoiceData,
    financialInvoiceSave: FinancialActions.financialInvoiceSave,
    startRequest: request,
    requestSuccess: success,
    requestError: error,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalUpload);
