import React from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';
import { Container } from './styles';

const Pagination = ({ page, pageCount, pageChange }) => (
  <Container>
    <ReactPaginate
      initialPage={page}
      forcePage={page}
      previousLabel={'<'}
      nextLabel={'>'}
      breakLabel="..."
      breakClassName="break-me"
      pageCount={pageCount}
      nextClassName={pageCount === 0 ? 'next disabled' : 'next '}
      marginPagesDisplayed={2}
      pageRangeDisplayed={4}
      onPageChange={pageChange}
      containerClassName="pagination"
      subContainerClassName="pages pagination"
      activeClassName="active"
    />
  </Container>
);

Pagination.defaultProps = {
  page: 0,
};

Pagination.propTypes = {
  page: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  pageChange: PropTypes.func.isRequired,
};

export default Pagination;
