import {
  all, call, put, select,
} from 'redux-saga/effects';

import GridPeriodService from '~/services/GridPeriodService';
import GridManagerService from '~/services/GridManagerService';
import { Creators as NewGridPeriodActions } from '~/redux/ducks/newGridPeriod';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

function makeDailyDistributionBeforeByFieldConfigurations(volume, fieldConfigurations) {
  let dividerBy = 0;
  fieldConfigurations.forEach((item) => {
    dividerBy += item.distributable ? 1 : 0;
  });


  const div = parseInt(volume / dividerBy);
  let remaining = volume - (div * dividerBy);
  const dailyDistributionBefore = fieldConfigurations.map(
    (field) => {
      if (!field.distributable || dividerBy === 0) return 0;

      const res = (div + remaining);
      remaining = 0;
      return res;
    },
  );
  return dailyDistributionBefore;
}

export function* gridPeriodSetup() {
  // Start loading
  yield put(startRequest());
  try {
    // GET Periods
    const periods = yield call(GridPeriodService.periodRequest);
    const periodSelected = periods[0];

    const manager = yield call(GridManagerService.getGridManagerPeriod, periodSelected.uuid);
    const zones = manager ? manager.zones : [];

    yield put(NewGridPeriodActions.gridPeriodSetupSuccess(periods, periodSelected, zones));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

function calculateRemainingImpressions(totalImpressions, auction, probe, fixedCpa, bonus) {
  let remainingImpressions = 0;


  if (fixedCpa) {
    remainingImpressions += fixedCpa.items.reduce(
      (sumItems, current) => {
        if (current.dailyDistributionBefore.length > 0) {
          const sumDailyDistributionBefore = current.dailyDistributionBefore.reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
          );
          return sumItems + (parseFloat(sumDailyDistributionBefore) || 0);
        }
        return sumItems + (parseFloat(current.volume) || 0);
      }, 0,
    );
  }

  if (probe) {
    remainingImpressions += probe.items.reduce(
      (sumItems, current) => {
        if (current.dailyDistributionBefore.length > 0) {
          const sumDailyDistributionBefore = current.dailyDistributionBefore.reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
          );
          return sumItems + (parseFloat(sumDailyDistributionBefore) || 0);
        }
        return sumItems + (parseFloat(current.volume) || 0);
      }, 0,
    );
  }

  if (bonus) {
    remainingImpressions += bonus.items.reduce(
      (sumItems, current) => {
        if (current.dailyDistributionBefore.length > 0) {
          const sumDailyDistributionBefore = current.dailyDistributionBefore.reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
          );
          return sumItems + (parseFloat(sumDailyDistributionBefore) || 0);
        }
        return sumItems + (parseFloat(current.volume) || 0);
      }, 0,
    );
  }

  if (auction) {
    remainingImpressions += auction.items.reduce(
      (sumItems, current) => {
        if (current.dailyDistributionBefore.length > 0) {
          const sumDailyDistributionBefore = current.dailyDistributionBefore.reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
          );
          return sumItems + (parseFloat(sumDailyDistributionBefore) || 0);
        }
        return sumItems + (parseFloat(current.volume) || 0);
      }, 0,
    );
  }

  return totalImpressions - remainingImpressions;
}

export function* recalculateRemainingImpressions() {
  const { channelEdit } = yield select(state => state.newGridPeriod);
  if (channelEdit) {
    const { disparosSatpush, channel } = channelEdit;
    if (disparosSatpush && disparosSatpush.show) {
      if (channel) {
        const {
          auction, probe, fixedCpa, bonus,
        } = channel;
        const remainingImpressions = calculateRemainingImpressions(
          disparosSatpush.totalImpressions, auction, probe, fixedCpa, bonus,
        );
        yield put(NewGridPeriodActions.recalculateRemainingImpressionsSuccess(remainingImpressions));
      }
    }
  }
}

export function* openModalChannel({ zone }) {
  const { periodSelected } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    // GET Periods

    const managerGridItem = yield call(
      GridManagerService.getGridManagerPeriodItems,
      periodSelected.uuid,
      zone.uuid,
    );

    const auction = managerGridItem.types.find(c => c.name === 'AUCTION');
    const probe = managerGridItem.types.find(c => c.name === 'PROBE');
    const fixedCpa = managerGridItem.types.find(c => c.name === 'FIXED_CPA');
    const bonus = managerGridItem.types.find(c => c.name === 'BONUS');

    const disparosSatpush = {
      show: false,
      totalImpressions: 0,
      remainingImpressions: 0,
    };

    // SATPUSH
    if (zone && zone.channel && zone.channel.uuid === '7d8d2fc0-7267-47b6-a8fe-b5180beb4d94') {
      disparosSatpush.show = true;
      const gridPeriodSatpush = yield call(
        GridPeriodService.gridPeriodRequest,
        periodSelected.uuid,
        zone.uuid,
      );
      if (gridPeriodSatpush && gridPeriodSatpush.zone) {
        const { totalImpressions } = gridPeriodSatpush.zone;
        disparosSatpush.totalImpressions = totalImpressions;
      }
      disparosSatpush.remainingImpressions = calculateRemainingImpressions(disparosSatpush.totalImpressions, auction, probe, fixedCpa, bonus);
    }


    yield put(NewGridPeriodActions.openModalChannelSuccess(
      zone,
      {
        auction, probe, fixedCpa, bonus,
      },
      managerGridItem.fieldConfigurations,
      managerGridItem.config,
      disparosSatpush,
    ));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    console.log(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* changePeriodSelected({ periodSelected }) {
  // Start loading
  yield put(startRequest());
  try {
    const manager = yield call(GridManagerService.getGridManagerPeriod, periodSelected.uuid);
    const zones = manager ? manager.zones : [];

    yield put(NewGridPeriodActions.changePeriodSelectedSuccess(periodSelected, zones));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* openModalInitCloseWeekly() {
  const { periodSelected } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    const configs = yield call(GridManagerService.getGridConfigPeriod, periodSelected.uuid);

    const geral = configs.find(c => c.type === 'GERAL');
    const auction = configs.find(c => c.type === 'CAMPAIGN_TYPE' && c.campaignType.name === 'AUCTION');
    const probe = configs.find(c => c.type === 'CAMPAIGN_TYPE' && c.campaignType.name === 'PROBE');
    const fixedCpa = configs.find(c => c.type === 'CAMPAIGN_TYPE' && c.campaignType.name === 'FIXED_CPA');
    const bonus = configs.find(c => c.type === 'CAMPAIGN_TYPE' && c.campaignType.name === 'BONUS');

    yield put(NewGridPeriodActions.openModalInitCloseWeeklySuccess(
      geral, auction, probe, fixedCpa, bonus,
    ));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* confirmStartGridPeriod() {
  const {
    periodSelected,
    configs: {
      geral, auction, probe, fixedCpa, bonus,
    },
  } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    const gridConfig = [geral, auction, probe, fixedCpa, bonus];

    yield call(GridManagerService.startGridManager, periodSelected.uuid, gridConfig);

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Abertura de análise realizada com sucesso!',
    ));

    // Stop loading
    yield put(requestSuccess());

    yield put(NewGridPeriodActions.closeModalInitCloseWeekly());
    yield put(NewGridPeriodActions.changePeriodSelected(periodSelected));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar iniciar a análise do fechamento semanal. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* confirmCloseGridPeriod() {
  const {
    periodSelected,
    zones,
  } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    for (let i = 0; i < zones.length; i += 1) {
      const zone = zones[i];
      yield call(GridManagerService.closeGridManagerZone, periodSelected.uuid, zone.uuid);
    }
    yield call(GridManagerService.closeGridManager, periodSelected.uuid);

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Conclusão de análise realizada com sucesso!',
    ));

    // Stop loading
    yield put(requestSuccess());

    yield put(NewGridPeriodActions.gridPeriodSetup());
  } catch (e) {
    console.log(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar concluir a análise do fechamento semanal. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* changeStatusAuction({ status }) {
  const {
    periodSelected,
    channelEdit: { zone, channel: { auction } },
  } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      GridManagerService.updateStatusCampaignType,
      periodSelected.uuid,
      zone.uuid,
      auction.uuid,
      status,
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(NewGridPeriodActions.changeStatusAuctionSuccess(status));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar atualizar o status do Leilão. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* updateItemAuction({ item, save }) {
  if (save) {
    const {
      periodSelected,
      channelEdit: { zone, channel: { auction } },
    } = yield select(state => state.newGridPeriod);
    // Start loading
    // yield put(startRequest());
    try {
      yield call(
        GridManagerService.updateItemCampaignType,
        periodSelected.uuid,
        zone.uuid,
        auction.uuid,
        item.campaign.uuid,
        item,
      );
      // Stop loading
      // yield put(requestSuccess());
    } catch (e) {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um erro ao tentar atualizar o item do Leilão. Tente novamente!',
      ));
      // yield put(requestError());
    }
  }
  yield put(NewGridPeriodActions.recalculateRemainingImpressions());
}

export function* changeStatusProbe({ status }) {
  const {
    periodSelected,
    channelEdit: { zone, channel: { probe } },
  } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      GridManagerService.updateStatusCampaignType,
      periodSelected.uuid,
      zone.uuid,
      probe.uuid,
      status,
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(NewGridPeriodActions.changeStatusProbeSuccess(status));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar atualizar o status do Teste. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* updateItemProbe({ item, save }) {
  if (save) {
    const {
      periodSelected,
      channelEdit: { zone, channel: { probe } },
    } = yield select(state => state.newGridPeriod);
    // Start loading
    // yield put(startRequest());
    try {
      yield call(
        GridManagerService.updateItemCampaignType,
        periodSelected.uuid,
        zone.uuid,
        probe.uuid,
        item.campaign.uuid,
        item,
      );
      // Stop loading
      // yield put(requestSuccess());
    } catch (e) {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um erro ao tentar atualizar o item do Teste. Tente novamente!',
      ));
      // yield put(requestError());
    }
  }
  yield put(NewGridPeriodActions.recalculateRemainingImpressions());
}

export function* removeCampaignProbe({ item }) {
  const {
    periodSelected,
    channelEdit: { zone, channel: { probe } },
  } = yield select(state => state.newGridPeriod);
    // Start loading
  yield put(startRequest());
  try {
    yield call(
      GridManagerService.removeGridItem,
      periodSelected.uuid,
      zone.uuid,
      probe.uuid,
      item.campaign.uuid,
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(NewGridPeriodActions.removeCampaignProbeSuccess(item));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar remover o item do Teste. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* removeCampaignFixedCpa({ item }) {
  const {
    periodSelected,
    channelEdit: { zone, channel: { fixedCpa } },
  } = yield select(state => state.newGridPeriod);
    // Start loading
  yield put(startRequest());
  try {
    yield call(
      GridManagerService.removeGridItem,
      periodSelected.uuid,
      zone.uuid,
      fixedCpa.uuid,
      item.campaign.uuid,
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(NewGridPeriodActions.removeCampaignFixedCpaSuccess(item));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar remover o item do CPA Fixo. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* removeCampaignBonus({ item }) {
  const {
    periodSelected,
    channelEdit: { zone, channel: { bonus } },
  } = yield select(state => state.newGridPeriod);
    // Start loading
  yield put(startRequest());
  try {
    yield call(
      GridManagerService.removeGridItem,
      periodSelected.uuid,
      zone.uuid,
      bonus.uuid,
      item.campaign.uuid,
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(NewGridPeriodActions.removeCampaignBonusSuccess(item));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar remover o item do Bonificada. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* updateItemFixedCpa({ item, save }) {
  if (save) {
    const {
      periodSelected,
      channelEdit: { zone, channel: { fixedCpa } },
    } = yield select(state => state.newGridPeriod);
    // Start loading
    // yield put(startRequest());
    try {
      yield call(
        GridManagerService.updateItemCampaignType,
        periodSelected.uuid,
        zone.uuid,
        fixedCpa.uuid,
        item.campaign.uuid,
        item,
      );
      // Stop loading
      // yield put(requestSuccess());
    } catch (e) {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um erro ao tentar atualizar o item do CPA Fixo. Tente novamente!',
      ));
      // yield put(requestError());
    }
  }
  yield put(NewGridPeriodActions.recalculateRemainingImpressions());
}
export function* updateItemBonus({ item, save }) {
  if (save) {
    const {
      periodSelected,
      channelEdit: { zone, channel: { bonus } },
    } = yield select(state => state.newGridPeriod);
    // Start loading
    // yield put(startRequest());
    try {
      yield call(
        GridManagerService.updateItemCampaignType,
        periodSelected.uuid,
        zone.uuid,
        bonus.uuid,
        item.campaign.uuid,
        item,
      );
      // Stop loading
      // yield put(requestSuccess());
    } catch (e) {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um erro ao tentar atualizar o item do Bonificado. Tente novamente!',
      ));
      // yield put(requestError());
    }
  }
  yield put(NewGridPeriodActions.recalculateRemainingImpressions());
}

export function* changeStatusFixedCpa({ status }) {
  const {
    periodSelected,
    channelEdit: { zone, channel: { fixedCpa } },
  } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      GridManagerService.updateStatusCampaignType,
      periodSelected.uuid,
      zone.uuid,
      fixedCpa.uuid,
      status,
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(NewGridPeriodActions.changeStatusFixedCpaSuccess(status));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar atualizar o status do CPA Fixo. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* changeStatusBonus({ status }) {
  const {
    periodSelected,
    channelEdit: { zone, channel: { bonus } },
  } = yield select(state => state.newGridPeriod);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      GridManagerService.updateStatusCampaignType,
      periodSelected.uuid,
      zone.uuid,
      bonus.uuid,
      status,
    );
    // Stop loading
    yield put(requestSuccess());
    yield put(NewGridPeriodActions.changeStatusBonusSuccess(status));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um erro ao tentar atualizar o status do Bonificado. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* closeModalChannel() {
  const {
    periodSelected,
  } = yield select(state => state.newGridPeriod);
  yield put(NewGridPeriodActions.closeModalChannelSuccess());
  yield put(NewGridPeriodActions.changePeriodSelected(periodSelected));
}

export function* gridPeriodCampaignByFilterRequest() {
  const {
    campaignListFilter,
    productList,
    contractList,
  } = yield select(state => state.newGridPeriod);
  yield put(startRequest());
  try {
    if (productList.length <= 0) {
      const products = yield call(GridPeriodService.listAllProducts);
      yield put(NewGridPeriodActions.gridPeriodProductRequestSuccess(products));
    }
    if (contractList.length <= 0) {
      const contracts = yield call(GridPeriodService.listContracts);
      yield put(NewGridPeriodActions.gridPeriodContractRequestSuccess(contracts));
    }

    const campaignList = yield call(GridPeriodService.getCampaignByFilter, campaignListFilter);
    yield put(
      NewGridPeriodActions.gridPeriodCampaignByFilterRequestSuccess(campaignList),
    );
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* gridPeriodInsertCampaignsSelected() {
  const {
    campaignToAdd,
    campaignList,
    campaignListFilter,
    periodSelected,
    channelEdit: {
      zone,
      fieldConfigurations,
    },
  } = yield select(state => state.newGridPeriod);

  const campaingNewItems = campaignToAdd.map((item) => {
    const itemFind = campaignList.content.find(
      contentItem => contentItem.uuid === item,
    );
    const newVolume = campaignListFilter.typeName === 'BONUS' ? 500000 : itemFind.volume;

    const dailyDistributionBefore = makeDailyDistributionBeforeByFieldConfigurations(
      newVolume,
      fieldConfigurations,
    );

    return {
      campaignUuid: itemFind.uuid,
      budget: itemFind.budget,
      cpa: 0,
      ecpm: '0.0',
      volume: newVolume,
      dailyDistributionBefore,

    };
  });

  yield call(
    GridManagerService.addCampaignsCampaignType,
    periodSelected.uuid,
    zone.uuid,
    campaignListFilter.type,
    campaingNewItems,
  );

  yield put(NewGridPeriodActions.openModalChannel(zone));
  yield put(NewGridPeriodActions.gridPeriodChangeModalStatus({
    modalBonificadas: false,
    modalBonificadasEdit: false,
    modalCpaFixo: false,
    modalTeste: false,
  }));
  yield put(NewGridPeriodActions.gridPeriodCampaignByFilterRequestSuccess(null));
  yield put(NewGridPeriodActions.gridPeriodResetFilterModalCampaign(null));
  yield put(NewGridPeriodActions.gridPeriodSetCampaignToAdd([]));
}

export function* updatePositionInBatch({gridManagerGridItemUpdatePosList}) {
  // Start loading
  yield put(startRequest());
  try {
    yield call(GridManagerService.updatePositionInBatch, gridManagerGridItemUpdatePosList);
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao salvar posição. Tente novamente!',
    ));
    yield put(requestError());
  }
}
