import styled from 'styled-components';
import Button from '~/Components/Button';
import ReactSelect from '~/Components/Select';
import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
  CurrentInputStyled,
} from '~/Components/Styled';

export const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`;

export const ModalBodyHistory = styled(ModalBody)`
  height: 400px;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
`;

export const ButtonClose = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const ButtonFooter = styled(Button)`
  margin: 0;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: column;
  flex: 1;
  label {
    font-weight: bold;
  }
`;

export const CalculationTypeSelect = styled(ReactSelect).attrs({
  width: 180,
})``;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
`;

export const BoxHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  padding: 0 10px;
  > span {
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    font-size: 16px;
    padding: 0;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonGenerateProposal = styled(Button)`
  margin: 0;
  height: 30px;
  line-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

export const ProposalBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
`;

export const ProposalItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  width: ${props => (props.width ? `${props.width}px` : 'unset')} !important;
  flex: ${props => (props.width ? 'unset' : 1)} !important;
  &:last-child {
    margin-right: 0;
  }
  > label {
    font-size: 16px;
  }
  > span {
    font-weight: bold;
    font-size: 18px;
    color: ${props => props.theme.colors.darker};
  }
`;

export const ProposalSelect = styled(ReactSelect)`
  width: 100%;
`;

export const ProposalInput = styled(InputStyled)`
  width: 100%;
  margin: 0;
  height: 40px;
`;

export const ProposalInputCurrent = styled(CurrentInputStyled)`
  width: 100%;
  margin: 0;
  height: 40px;
`;

export const LinkHistory = styled.a`
  align-self: flex-start;
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  &:hover, &:focus {
    color: ${props => props.theme.colors.primary};
  }
`;

export const LogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .left {
    width: 60px;
    flex: 1;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-circle {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.primary};

      img {
        max-width: 25px;
      }
    }

    .line {
      width: 5px;
      background-color: ${props => props.theme.colors.primary};
      border-radius: 4px;
      flex: 1;
      display: flex;
    }
  }
  .right {
    flex: 4;
    .content-log {
      background-color: ${props => props.theme.colors.lighterWhite};
      border-radius: 2px;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 15px;
      margin-left: -15px;
    }
    .status {
      height: 60px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        &.label {
          font-size: 14px;
          color: ${props => props.theme.colors.dark};
          padding: 0;
        }
        &.desc {
          font-weight: 200;
          font-size: 18px;
          color: ${props => props.theme.colors.darker};
        }
        &.desc-bold {
          font-weight: bold;
          font-size: 18px;
          color: ${props => props.theme.colors.darker};
        }
      }
    }

    .content-log-info {
      margin-bottom: 0;
    }

    .content-log-justify {
      margin-top: 20px;
      min-height: 35px;
    }
  }
`;
