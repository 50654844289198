import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Button from '~/Components/Button';

import {
  Container,
} from './styles';

function AuthError({ credentials, error, auth }) {
  // {"error":"unauthorized","errorDescription":"user is blocked","state":"aVX_RzfEMnTdXazaH94xv7t_uhe3oC2o"}
  const { error: errorEvent, errorDescription } = error;

  function handleLogout() {
    auth.logout();
  }

  return (
    <Container>
      <img alt="Logo" className="logo" src={`https://auth0.apolowifi.com/${credentials.client_id}-logo.png`} />

      <h1>Atenção</h1>
      <p>Não foi possível acessar o sistema com o usuário informado</p>

      {errorEvent === 'unauthorized' && errorDescription === 'user is blocked' && (<span>Usuário bloqueado</span>)}

      <Button
        bordered
        title="Alterar usuário"
        onClick={handleLogout}
      />
    </Container>
  );
}

AuthError.propTypes = {
  credentials: PropTypes.shape().isRequired,
  error: PropTypes.shape().isRequired,
  auth: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  credentials: state.credentials.data,
});

export default connect(mapStateToProps, null)(AuthError);
